export const StepperAnimation = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? -100 : 100,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction > 0 ? -100 : 100,
    }
  },
}
