import styled from 'styled-components'

export const StyledCheckbox = styled.label<{ isChecked: boolean }>`
  display: flex;
  align-items: start;
  margin-top: ${({ theme }) => theme.spacing(10)};

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  input:focus + svg {
    border: 2px ${({ theme }) => theme.palette.common.black} solid;
  }

  svg {
    display: inline-block;
    height: 18px;
    width: 18px;
    border: 2px #ddd solid;
    border-radius: 5px;
    margin-right: ${({ theme }) => theme.spacing(3)};

    ${({ theme, isChecked }) =>
      isChecked &&
      `
      border-color: ${theme.palette.primary.main};
      background: ${theme.palette.primary.main};
    `}

    path {
      ${({ theme, isChecked }) =>
        isChecked &&
        `
          stroke: ${theme.palette.common.black};
        `}
    }
  }

  p,
  a {
    line-height: 20px;
    max-width: 374px;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`
