import styled from '@emotion/styled'
import { PinInputField, Text } from '@chakra-ui/react'

export const StyledPinInput = styled(PinInputField)`
  color: #e8ecfb;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  background: #21232f;
  border: none;
  padding: 10px;
`

export const StyledErrorText = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #f7547b;
`

export const StyledContainer = styled.div`
  font-family: Inter;
`

export const StyledSuccessTitle = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: #e8ecfb;
`

export const StyledSuccessDescription = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #cfd4eb;
  padding-bottom: 30px;
`
