export const Badge = {
  baseStyle: {
    fontSize: '10px',
    borderRadius: '30px',
    fontWeight: 600,
    padding: '1px 12px',
    textTransform: 'initial',
  },
  variants: {
    success: {
      bg: 'primary.accent',
      color: 'grey.400',
    },
    disabled: {
      bg: 'grey.300',
      color: 'white',
    },
    primary: {
      bg: 'primary.accent',
      color: 'grey.400',
    },
    secondary: {
      bg: 'secondary.accent',
      color: 'white',
    },
  },
}
