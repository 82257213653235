import styled from 'styled-components'
import { StyledMusicPlayerControl } from './musicPlayerControl/MusicPlayerControl.styles'

export const StyledMusicPlayer = styled.div<{ isExpanded: boolean }>`
  z-index: 20;
  background: ${({ theme }) => theme.palette.common.black};
  position: fixed;
  bottom: 15px;
  left: 25px;
  width: ${({ isExpanded }) => (isExpanded ? '400px' : '80px')};
  height: ${({ isExpanded }) => (isExpanded ? '115px' : '80px')};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(5)};
  border: 3px solid;
  transition: all 0.3s ease-in-out;
  border-image: linear-gradient(
      to right bottom,
      ${({ theme, isExpanded }) => (isExpanded ? theme.palette.secondary.main : theme.palette.primary.main)},
      transparent,
      ${({ theme, isExpanded }) => (isExpanded ? theme.palette.primary.main : theme.palette.secondary.main)}
    )
    1;

  & > div {
    transition: all 0.3s ease-in-out;
    transform: scale(${({ isExpanded }) => (isExpanded ? 1 : 0)});
    transform-origin: top left;
  }

  & > ${StyledMusicPlayerControl} {
    transform: scale(${({ isExpanded }) => (!isExpanded ? '1' : '0')}) translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: ${({ isExpanded }) => (isExpanded ? 'calc(100% - 50px)' : '80px')};
    height: ${({ isExpanded }) => (isExpanded ? '115px' : '80px')};
  }
`
