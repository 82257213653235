import React, { FunctionComponent } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import styled from 'styled-components'

export const StyledTextareaInput = styled.textarea`
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
  border-radius: 5px;
  background: ${({ theme }) => theme.input.background};
  font-size: ${({ theme }) => theme.input.fontSize.base};
  color: ${({ theme }) => theme.input.color};
  border: 1px solid transparent;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

export type TextareaInputProps = {
  placeholder?: string
  registerReturn: UseFormRegisterReturn
}

export const TextareaInput: FunctionComponent<TextareaInputProps> = ({ placeholder, registerReturn }) => {
  return <StyledTextareaInput placeholder={placeholder} {...registerReturn} />
}
