interface PublicMintArgs {
  uniqueCollectionContract: any
  mintIndex: number | undefined
  mintPrice: any
  mintDataConstraint: string | undefined
  openSuccess: () => void
  setTxHash: (txHash: string) => void
}

export const publicMint = async ({ uniqueCollectionContract, mintIndex, mintPrice, mintDataConstraint, openSuccess, setTxHash }: PublicMintArgs) => {
  try {
    // Before making public mint, check constraint
    const mint = await uniqueCollectionContract?.publicMint(mintIndex, mintDataConstraint, {
      value: mintPrice,
      gasLimit: 1000000,
    })

    if (mint) {
      openSuccess()
      mint.wait().then(async (data: any) => {
        setTxHash(data.transactionHash)
      })
    }
  } catch (e) {
    console.log('User rejected transaction', e)
    setTxHash('')
    // if the user rejects metamask transaction we need to reset the items
    // await handleSetItems({ mintPayload, setItems, uniqueCollection, address })
  }
}
