export const setLocalStorage = (key: string, value: any) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

export const getLocalStorage = (key: string): any => {
  if (typeof window !== 'undefined') {
    const value = window.localStorage.getItem(key)
    if (value !== null) {
      try {
        return JSON.parse(value)
      } catch (e) {
        return value
      }
    }
  }
}

export const removeLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key)
  }
}
