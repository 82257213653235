import { useState, useContext, useRef, useEffect } from 'react'
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './styles.css'
import useVerifyPhone from '@fuel/auth/hooks/useVerifyPhone'
import { PhoneVerificationContext } from '@fuel/auth/context/PhoneVerificationProvider'
import { StyledHeader, StyledHelperText } from './PhoneInputModal.styled'
import PhoneVerifyPin from './PhoneVerifyPin'

const animation = {
  opacity: [0, 0.5, 1],
}

const PhoneInputModal = () => {
  const { modalOpen, setModalOpen, setPhoneNumber, phoneNumber } = useContext<any>(PhoneVerificationContext as any)
  const [isVerifying, setIsVerifying] = useState(false)
  const { verifyPhone } = useVerifyPhone()
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const animatedHeaderRef = useRef<any>()
  const animatedBodyRef = useRef<any>()

  useEffect(() => {
    animatedHeaderRef?.current?.animate(animation, 500)
    animatedBodyRef?.current?.animate(animation, 500)
  }, [isVerifying, isSuccess])

  const handleVerify = async () => {
    console.log('Verify', phoneNumber)
    if (phoneNumber.length > 3) {
      setLoading(true)
      try {
        await verifyPhone.mutateAsync({
          phone_number: phoneNumber,
        })
        setIsVerifying(true)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
      <ModalOverlay />

      <ModalContent bg="#0E0E16" textAlign="center" maxW={['350px', '450px', '500px']} borderRadius="17px" h="400px">
        {!isSuccess && (
          <ModalHeader mt="40px" ref={animatedHeaderRef}>
            <Flex alignItems="center">
              {isVerifying ? <ArrowBackIcon cursor="pointer" onClick={() => setIsVerifying(false)} /> : null}
              <StyledHeader>{isVerifying ? 'Onaylama kodunu gir' : 'Telefon numaranı gir.'}</StyledHeader>
            </Flex>
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody ref={animatedBodyRef}>
          {!isSuccess && (
            <StyledHelperText>
              {isVerifying
                ? `Telefonuna 6 haneli bir kod gönderdik ${phoneNumber}`
                : 'Bu sayede Turkish Flava’daki kaçınılmaz fırsatlardan ilk sen haberdar olacaksın.'}
            </StyledHelperText>
          )}

          {isVerifying ? (
            <PhoneVerifyPin handleResendCode={handleVerify} isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
          ) : (
            <Box mt="40px">
              <PhoneInput country="tr" placeholder="Enter phone number" value={phoneNumber} onChange={value => setPhoneNumber(`+${value}`)} />
              <Button
                margin="0 auto"
                maxW="200px"
                my="40px"
                fontFamily="Inter"
                bg="#E8ECFB"
                borderRadius="50px"
                lineHeight="22px"
                fontSize="18px"
                p="25px 40px"
                color="#21232F"
                onClick={handleVerify}
                disabled={loading}
                w="150px"
              >
                {loading ? <Spinner /> : 'Devam et'}
              </Button>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PhoneInputModal
