import { Box, Checkbox, Link } from '@chakra-ui/react'
import { DropPageDataContext } from '@fuel/auth/context/DropPageDataProvider'
import { storage } from '@fuel/core'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { CollectionContext } from '@fuel/nft'
import React, { useContext, useState } from 'react'
import { paymentMethods } from '../../../constants/paymentMethods'
import { StyledHeaderText, StyledPayButton, StyledTotalText } from './Payment.styled'
import RadioButtons from './RadioButtons/RadioButtons'
import { StyledHStack } from './RadioButtons/RadioButtons.styles'
import { useTranslation } from 'next-i18next'
import { StyledCheckbox } from '@fuel/stepper/components/details/UserDetailsForm.styled'
import styled from '@emotion/styled'

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const StyledBox = styled(Box)`
  margin: 16px 0 40px;
`

type PaymentModalProps = {
  onPaymentSelected: (selectedPaymentMethod: string) => void
  isTurkish?: boolean
  onlyStripe?: boolean
  purchaseQuantity: number
}

export default function Payment({ onPaymentSelected, isTurkish = false, purchaseQuantity, onlyStripe }: PaymentModalProps) {
  const { t } = useTranslation('stepper')
  const [isPaying, setIsPaying] = useState(false)
  const [value, setValue] = useState('1')
  const [termsChecked, setTermsChecked] = useState(false)
  const { collection } = useContext<any>(CollectionContext)
  const { dropPageData } = useContext<any>(DropPageDataContext)
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)

  const handlePay = () => {
    setIsPaying(true)
    const payment_id = paymentMethods.find(method => method.id === value)?.payment_id
    onPaymentSelected(payment_id ?? '')
  }

  const isUniqueCollection = dropPageData?.payment_flow === 'unique_nft_purchase'
  const isUnlimitedEditionPurchase = dropPageData?.payment_flow === 'unlimited_edition_purchase'

  const priceAndTotal = () => {
    if (isUnlimitedEditionPurchase) {
      if (selectedEdition?.currency === 'EUR') {
        return ` €${Number(selectedEdition?.total)}`
      } else if (selectedEdition?.currency === 'ETH') {
        return `${selectedEdition?.total} ETH`
      } else `${selectedEdition?.total} ${selectedEdition?.currency}`
    }

    if (isUniqueCollection) {
      if (collection?.currency === 'EUR') {
        return ` €${Number(collection?.total)}`
      } else if (collection?.currency === 'ETH') {
        return `${collection?.total} ETH`
      } else `${collection?.total} ${collection?.currency}`
    } else {
      return isTurkish ? ` ${selectedEdition?.price * purchaseQuantity} TL ` : ` €${selectedEdition?.price * purchaseQuantity} `
    }
  }

  // TODO: To remove isTurkish after gunes is translated (or we just not deploy anymore)
  const Total = () => (
    <StyledTotalText>
      {isTurkish ? 'Toplam:' : t('payment_total')} {priceAndTotal()}
    </StyledTotalText>
  )

  return (
    <Box textAlign="center">
      <StyledHeaderText>{isTurkish ? 'Ödeme yöntemleri' : t('payment_methods')}</StyledHeaderText>

      <RadioButtons value={value} setValue={setValue} isTurkish={isTurkish} onlyStripe={onlyStripe} />

      <StyledHStack
        sx={{
          marginTop: 10,
          justifyContent: 'space-between',
        }}
      >
        <StyledTotalText>
          {purchaseQuantity}x {selectedEdition?.name}
        </StyledTotalText>
        <Total />
      </StyledHStack>

      <StyledBox>
        <Checkbox isChecked={termsChecked} onChange={e => setTermsChecked(e.target.checked)}>
          <StyledCheckbox>
            <StyledLink href="https://docs.google.com/document/d/1LX6O5IQF0UZAMrnM5wi0zbEgOMdbHLau5SHoFYStgd4/edit?usp=sharing" isExternal>
              {t("i_approve_special_conditions_of_purchase")}
            </StyledLink>
          </StyledCheckbox>
        </Checkbox>
      </StyledBox>

      <StyledPayButton onClick={() => !isPaying && handlePay()} isDisabled={isPaying || !termsChecked} isLoading={isPaying}>
        {isTurkish ? 'öde' : t('payment_buy_now')}
      </StyledPayButton>
    </Box>
  )
}
