import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { NavigationItemModel } from '@fuel/core/model/NavigationItemModel'

interface NavigationItemProps {
  navigationItem: NavigationItemModel
}

export const NavigationItem: FC<NavigationItemProps> = ({ navigationItem: { name, url, translation_key } }) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  return (
    <Link href={url} passHref={true}>
      <Button variant="link" color={router.pathname === url ? 'secondary.accent' : 'grey.300'} mr={['16px', '64px']}>
        {translation_key ? t(translation_key) : <span>{name}</span>}
      </Button>
    </Link>
  )
}
