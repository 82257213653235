import styled from 'styled-components'

export const Box = styled.div<{
  mt?: number
  mb?: number
  ml?: number
  mr?: number
  width?: string
}>`
  ${({ theme, mt }) => mt && `margin-top: ${theme.spacing(mt)}`};
  ${({ theme, mb }) => mb && `margin-bottom: ${theme.spacing(mb)}`};
  ${({ theme, ml }) => ml && `margin-left: ${theme.spacing(ml)}`};
  ${({ theme, mr }) => mr && `margin-right: ${theme.spacing(mr)}`};

  ${({ width }) => width && `width: ${width}`};
`
