export const errors = {
  NOT_FOUND: { code: 1002, http_status: 404 },
  PARAMETER_MISSING: { code: 1010, http_status: 400 },
  VALIDATION_FAILED: { code: 1020, http_status: 422 },
  INTERNAL_SERVER_ERROR: { code: 1000, http_status: 500 },
  DATABASE_ERROR: { code: 1003, http_status: 500 },
  GENERAL_ERROR: { code: 1001, http_status: 500 },

  WRONG_SIGNATURE: { code: 1033, http_status: 401 }, // LOGOUT
  INVALID_TOKEN: { code: 1031, http_status: 401 }, // LOGOUT
  JWT_EXPIRED: { code: 1034, http_status: 401 }, // REFRESH TOKEN

  NO_CREATOR: { code: 1030, http_status: 403 },
  FORBIDDEN: { code: 1021, http_status: 403 },
  MAGIC_API_ERROR: { code: 1032, http_status: 500 },

  COINBASE_API_ERROR: { code: 1040, http_status: 500 },
  STRIPE_API_ERROR: { code: 1041, http_status: 500 },

  NO_ACCOUNT_ID: { code: 1050, http_status: 400 },
  DELETE_ERROR: { code: 1060, http_status: 500 },
  DROP_DATE_ERROR: { code: 1070, http_status: 400 },
  TWILIO_BAD_REQUEST: { code: 1080, http_status: 400 },
}
