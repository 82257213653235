import styled from 'styled-components'

export const StyledMusicPlayerControl = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.common.white};
  margin-right: 10px;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`
