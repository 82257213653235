import React, { FunctionComponent } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { StyledTextInput } from './TextInput.styles'

export type TextInputProps = {
  placeholder?: string
  registerReturn: UseFormRegisterReturn
  type?: 'text' | 'email' | 'number'
}

export const TextInput: FunctionComponent<TextInputProps> = ({ placeholder, registerReturn, type = 'text' }) => {
  return <StyledTextInput placeholder={placeholder} {...registerReturn} type={type} />
}
