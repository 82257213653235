import { PaymentMethod } from '@fuel/payment/model/PaymentMethod'
import { FiCreditCard } from 'react-icons/fi'
import { GiTwoCoins } from 'react-icons/gi'

// This is only a translation
export const turkishPaymentMethods: PaymentMethod[] = [
  {
    id: '1',
    icon: FiCreditCard,
    title: 'Kredi Kartı',
    subtitle: 'Kolay ödeme.Apple/Google Pay ile uyumlu',
    recommended: true,
    payment_id: 'stripe',
  },
  {
    id: '2',
    icon: GiTwoCoins,
    title: 'Crypto ile öde',
    subtitle: 'Kolay ödeme.Gas fee uygulanır',
    recommended: false,
    payment_id: 'coinbase',
  },
]

export enum PurchaseStatus {
  NOT_STARTED = 'not_started',
  NFT_AVAILABLE = 'nft_available',
  ENQUEUED = 'enqueued',
  SOLD_OUT = 'sold_out',
}
