import { useContext, useEffect, useState } from 'react'
import { CollectionContext, NETWORK_STATUS, useNetworkStatus, useUniqueCollectionContract } from '@fuel/nft'
import * as Sentry from '@sentry/browser'
import { abi } from '../../constants/abi'

/**
 * Determines if a collection is sold out based on max supply and total supply
 */
export const useMintSoldOut = () => {
  const { networkStatus } = useNetworkStatus()
  const { collection }: any = useContext(CollectionContext)
  const { uniqueCollectionContract } = useUniqueCollectionContract({ contractAddress: collection?.contract_address, abi: abi.COLLECTION_ABI })
  const [soldOut, setSoldOut] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        if (uniqueCollectionContract && networkStatus === NETWORK_STATUS.CORRECT) {
          try {
            const maxSupply = +(await uniqueCollectionContract.maxSupply())
            const totalSupply = +(await uniqueCollectionContract.totalSupply()).toString()
            if (totalSupply >= maxSupply) {
              setSoldOut(true)
              Sentry.addBreadcrumb({
                category: 'mint',
                message: 'Token sold out!',
                level: 'info',
              })
            }
          } catch (e) {
            console.info('Called too early maxSupply/totalSupply!')
          }
        }
      } catch (e) {
        console.error('Something went wrong in useMintSoldOut')
      }
    })()
  }, [uniqueCollectionContract, networkStatus])

  return { soldOut }
}
