import styled, { keyframes } from 'styled-components'
import { StyledTitle } from './Title.styles'

const marquee = keyframes`
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
`

export const StyledStrokeTitle = styled(StyledTitle)<{ variant?: string }>`
  color: ${({ theme }) => theme.palette.secondary.dark};
  background: ${({ theme, variant }) => theme.palette[variant ?? 'primary'].dark};
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  text-shadow: ${({ theme, variant }) => theme.palette[variant ?? 'primary'].dark} 0px 0px 20px;
  font-size: ${({ theme }) => theme.fontSize['2xl']};
  line-height: ${({ theme }) => theme.fontSize['2xl']};
  white-space: nowrap;
  display: flex;
  justify-content: center;

  & > div {
    position: relative;
    overflow: hidden;
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    & > div {
      padding-top: 20px;
      width: fit-content;
      display: flex;
      position: relative;
      transform: translate3d(var(--move-initial), 0, 0);
      animation: ${marquee} 15s linear infinite;
      animation-play-state: running;
      & > span {
        padding: 0 ${({ theme }) => theme.spacing(2)};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSize.xl};
    line-height: ${({ theme }) => theme.fontSize.xl};
  }
`
