import React, { useContext } from 'react'
import { Box, Button, Text } from '@chakra-ui/react'
import { usePurchaseLeave } from '../hooks/usePurchaseLeave'
import { PaymentContext } from '../context/PaymentProvider'
import { PurchaseStatus } from '../constants/paymentMethods'
import { useEditionPurchaseStart } from '../hooks/useEditionPurchaseStart'

export default function LeavingPurchase({
  isTurkish = false,
  setIsLeavingPurchase,
  setPurchaseStatus,
  purchaseStatus,
  leaveQueue,
}: {
  isTurkish?: boolean
  setIsLeavingPurchase: (value: boolean) => void
  setPurchaseStatus: (value: PurchaseStatus) => void
  purchaseStatus: PurchaseStatus
  leaveQueue: () => void
}) {
  const { leavePurchase } = usePurchaseLeave()
  const { getEditionPurchaseStatus } = useEditionPurchaseStart({
    setPurchaseStatus,
    purchaseStatus,
  })

  const onStayQueue = () => {
    setIsLeavingPurchase(false)
    getEditionPurchaseStatus()
  }

  return (
    <Box textAlign="center">
      <Text mt="70px" fontFamily="inter" fontWeight={800} lineHeight="44px" fontSize="28px" color="#E8ECFB">
        {isTurkish ? 'Emin misin?' : 'Bist du sicher?'}
      </Text>

      <Text fontFamily="inter" fontWeight={500} lineHeight="24px" fontSize="16px" mt="3px" color="#CFD4EB">
        {isTurkish
          ? 'Görünüşe göre başarıya giden yoldasın! Sıradan şimdi çıkarsan yerini kaybedersin.Bu işlem geri alınamaz.'
          : `Es sieht aus, als wäre die Aktion erfolgreich. Wenn du die Warteschlange jetzt verlässt, bricht der Prozess ab und du musst von vorne beginnen.
          `}
      </Text>

      <Button
        fontFamily="inter"
        fontWeight={600}
        fontSize="16px"
        mt="46px"
        bg="#E8ECFB"
        color="#4F5079"
        _active={{
          transform: 'scale(0.95)',
          transition: 'all 0.2s',
        }}
        onClick={onStayQueue}
        borderRadius="50px"
      >
        {isTurkish ? 'Kuyrukta kal' : 'In der Warteschlange bleiben'}
      </Button>
      <Text
        fontFamily="inter"
        fontWeight={600}
        lineHeight="24px"
        fontSize="16px"
        color="alt.main"
        mt="33px"
        letterSpacing="-0.5px"
        pb="30px"
        onClick={leaveQueue}
        _hover={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {isTurkish ? 'Sırayı terk et' : 'Warteschlange verlassen'}
      </Text>
    </Box>
  )
}
