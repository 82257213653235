import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react'

import React, { useState } from 'react'
import Stepper from './Stepper/Stepper'
import styled from '@emotion/styled'
import { usePurchaseLeave } from '../../hooks/usePurchaseLeave'
import { PurchaseStatus } from '../../constants/paymentMethods'

const StyledModalContent = styled(ModalContent)`
  background-color: #0e0e16;
  color: #fff;
  padding: 30px 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  max-width: 646px;

  @media (max-width: 768px) {
    padding: 10px 5px;
    margin: 0px;
    position: fixed;
    width: 100vw;
    height: 100%;
    overflow-y: scroll;
  }
`

/**
 * @deprecated This one use used mostly for Gunes - we moved on to a stepper package
 * @param onClose
 * @param isOpen
 * @param isTurkish
 * @param purchaseLimit
 * @constructor
 */
export function EditionCollectionStepperModal({
  onClose,
  isOpen,
  isTurkish = false,
  purchaseLimit,
}: {
  onClose: () => void
  isOpen: boolean
  isTurkish?: boolean
  purchaseLimit: number
}) {
  const { leavePurchase } = usePurchaseLeave()
  const [purchaseStatus, setPurchaseStatus] = useState<PurchaseStatus>(PurchaseStatus.NOT_IN_QUEUE)

  const leaveQueue = async () => {
    await leavePurchase()
    setPurchaseStatus(PurchaseStatus.NOT_IN_QUEUE)
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={leaveQueue}>
        <ModalOverlay />
        <StyledModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Stepper
              isTurkish={isTurkish}
              leaveQueue={leaveQueue}
              purchaseStatus={purchaseStatus}
              setPurchaseStatus={setPurchaseStatus}
              purchaseLimit={purchaseLimit}
            />
          </ModalBody>
        </StyledModalContent>
      </Modal>
    </>
  )
}
