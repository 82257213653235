import { MINT_STATUS } from '../hooks/useUniqueCollectionContractV2Payload'
import { UniqueCollectionV2MintPhaseType } from '../types'
import { encodeTokenId, separateMintPhasesByBlockHeight, separateMintPhasesByMintType } from './index'
import { ethers } from 'ethers'

interface MintPhasesToMintStatusArgs {
  blockHeight: number
  rawMintPhases: UniqueCollectionV2MintPhaseType[] | undefined
  checkProof: ({ allowListMintPhase }: { allowListMintPhase: UniqueCollectionV2MintPhaseType }) => Promise<{ proof: string[]; isValid: boolean }>
  checkConstraint: ({ constraintAddress }: { constraintAddress: string }) => Promise<{
    tokenId: number | null
    canMint: boolean
  }>
}

export const mintPhasesToMintStatus = async ({ blockHeight, rawMintPhases, checkProof, checkConstraint }: MintPhasesToMintStatusArgs) => {
  const noDropAvailableAtAll = rawMintPhases?.length === 0 || !rawMintPhases
  if (noDropAvailableAtAll) {
    // no drop available at all
    return {
      status: MINT_STATUS.NO_PHASES,
      mintPhase: null,
    }
  }
  const { currentMintPhases, futureMintPhases } = separateMintPhasesByBlockHeight({ blockHeight, rawMintPhases })

  if (currentMintPhases.length === 0 && futureMintPhases.length === 0) {
    // drop is over, there is only mintphases in the past
    return {
      status: MINT_STATUS.ALL_PHASES_IN_THE_PAST,
      mintPhase: null,
    }
  }

  // is there a current mintphase?
  if (currentMintPhases.length > 0) {
    // which type is it? allowlist or public?
    const { allowListMintPhases: currentAllowListPhases, publicMintPhases: currentPublicMintPhases } = separateMintPhasesByMintType(currentMintPhases)

    // is there a allowlist mint phase?
    if (currentAllowListPhases.length > 0) {
      // are you on any allowlist mint phase?
      // allow list mint
      // check if address is on allowlist
      for (let i = 0; i < currentAllowListPhases.length; i++) {
        const { proof, isValid } = await checkProof({ allowListMintPhase: currentAllowListPhases[i] })
        if (isValid) {
          // you are on this allowlist
          return {
            status: MINT_STATUS.NOW_ON_ALLOW_LIST,
            mintPhase: currentAllowListPhases[i],
            proof,
          }
        }
      }
      // you are not on the allowlist, but there might be a public phase
      if (currentPublicMintPhases.length > 0) {
        // public mint
        return {
          status: MINT_STATUS.NOW_PUBLIC,
          mintPhase: currentPublicMintPhases[0],
        }
      }
      // you are not on the allowlist and no public phase
      return {
        status: MINT_STATUS.NOW_NOT_ON_ALLOW_LIST,
        mintPhase: null,
      }
    } else if (currentPublicMintPhases.length > 0) {
      // Check if the phase is constraint
      if (currentPublicMintPhases[0].mintConstraint !== ethers.constants.AddressZero) {
        // Check if canMint on the constraint with your tokenId
        const { canMint, tokenId } = await checkConstraint({ constraintAddress: currentPublicMintPhases[0].mintConstraint })

        if (canMint && tokenId) {
          const tokenIdToMintWithEncoded = encodeTokenId(tokenId)
          // if you are allowed to mint with constraint, send the tokenId encoded to the public mint function
          return {
            status: MINT_STATUS.NOW_PUBLIC_CONSTRAINT_PASSED,
            mintPhase: currentPublicMintPhases[0],
            mintDataConstraint: tokenIdToMintWithEncoded,
          }
        } else {
          return {
            status: MINT_STATUS.NOW_PUBLIC_CONSTRAINT_NOT_PASSED,
            mintPhase: currentPublicMintPhases[0],
          }
        }
      } else {
        // public mint
        return {
          status: MINT_STATUS.NOW_PUBLIC,
          mintPhase: currentPublicMintPhases[0],
        }
      }
    }
  } else if (futureMintPhases.length > 0) {
    // there is a mintphase in the future

    const { allowListMintPhases: futureAllowListMintPhases, publicMintPhases: futurePublicMintPhasese } =
      separateMintPhasesByMintType(futureMintPhases)
    if (futurePublicMintPhasese.length > 0) {
      // public mint in the future
      return {
        status: MINT_STATUS.FUTURE_PUBLIC,
        mintPhase: futurePublicMintPhasese[0],
      }
    } else if (futureAllowListMintPhases.length > 0) {
      // allow list mint
      // check if address is on allowlist

      for (let i = 0; i < futureAllowListMintPhases.length; i++) {
        const { proof, isValid } = await checkProof({ allowListMintPhase: futureAllowListMintPhases[i] })
        if (isValid) {
          // you are on this allowlist in the future
          return {
            status: MINT_STATUS.FUTURE_ON_ALLOW_LIST,
            mintPhase: futureAllowListMintPhases[i],
            proof,
          }
        }
      }
      // in the future but you are not on the allowlist
      return {
        status: MINT_STATUS.FUTURE_NOT_ON_ALLOW_LIST,
        mintPhase: null,
      }
    }
  }
}

export default mintPhasesToMintStatus
