import { HStack } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const StyledHStack = styled(HStack)`
  cursor: pointer;
  spacing: 5px;
  border-radius: 12px;
  padding: 5px 20px;
  text-align: left;
  gap: 15px;
  padding: 20px;

  box-shadow: 0px 4px 30px rgba(219, 227, 236, 0.45);
  background: #21232f;
  box-shadow: 0px 4px 30px rgba(14, 14, 22, 0.45);
  border-radius: 12px;

  // mobile
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
