import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Button } from '@chakra-ui/react'
import { ModalsContext } from '@fuel/core/context/ModalsProvider'

export const ErrorModal = () => {
  const { isOpenErrorModal, onCloseErrorModal } = useContext<any>(ModalsContext)
  const router = useRouter()

  return (
    <Modal isOpen={isOpenErrorModal} onClose={onCloseErrorModal}>
      <ModalOverlay />
      <ModalContent maxW="672px" h="370px" textAlign="center" borderRadius="17px" pt="70px">
        <ModalHeader>
          <Text fontSize="24px" fontWeight="bold">
            Something went wrong
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="16px" fontWeight="bold" mt="20px">
            Please try again later, or refresh the page.
          </Text>

          <Button
            mt="50px"
            onClick={() => {
              onCloseErrorModal()
              router.reload()
            }}
          >
            Refresh the page
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
