import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useAxiosWithAuth } from '@fuel/api'
import { queries, api } from '@fuel/core'
import { PhoneVerificationContext } from '../context/PhoneVerificationProvider'

export const useAccountDetails = (fetch: boolean) => {
  const { setModalOpen } = useContext<any>(PhoneVerificationContext)
  const axiosApi = useAxiosWithAuth()

  const query = useQuery(
    [queries.GET_ACCOUNT_DETAILS],
    async () => {
      try {
        const { data } = await axiosApi.get(api.GET_ACCOUNT_DETAILS)
        return data
      } catch (e) {
        console.log('Error en useAccountDetails', e)
      }
    },
    {
      retry: 0,
      enabled: fetch,
      onSuccess: data => {
        if (data && !data.phone_number) {
          setModalOpen(true)
        }
      },
    }
  )

  return query
}
