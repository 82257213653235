import { createContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Magic, SupportedLocale } from 'magic-sdk'
import { InstanceWithExtensions } from '@magic-sdk/provider/dist/types/modules/base-extension'
import { MagicSDKExtensionsOption, SDKBase } from '@magic-sdk/provider/dist/types/core/sdk'
import { ethers } from 'ethers'
import { useDisclosure } from '@chakra-ui/react'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { api, storage } from '@fuel/core'
import { useAxiosWithAuth } from '@fuel/api'
import { useLogout } from '../hooks/useLogout'
import { roles } from '../constants/roles'

const customNodeOptions = {
  rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
  chainId: 137,
}

export const MagicConnectUserContext = createContext<any>({}) as any

const MagicConnectUserProvider = ({ children }: any) => {
  const router = useRouter()
  const [magic, setMagic] = useState<InstanceWithExtensions<SDKBase, MagicSDKExtensionsOption<string>>>()
  const [provider, setProvider] = useState<any>(null)
  const [signer, setSigner] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const [collectionId, setCollectionId] = useState<any>(null)
  const [userDetails, setUserDetails] = useState<any>(null)
  const { logout } = useLogout()
  const axiosApi = useAxiosWithAuth()

  useEffect(() => {
    try {
      if (!magic) {
        const newMagic = new Magic(process.env.NEXT_PUBLIC_MAGIC_CONNECT_PUBLIC_KEY ?? '', {
          network: customNodeOptions,
          locale: (router.locale as SupportedLocale) ?? 'en',
        })
        setMagic(newMagic)

        const newProvider = new ethers.providers.Web3Provider(newMagic.rpcProvider as any)
        setProvider(newProvider)
        setSigner(newProvider.getSigner())
      }
    } catch (e) {
      console.log(e)
    }
  }, [router.locale])

  useEffect(() => {
    const authTokens = getLocalStorage(storage.AUTH_TOKENS)

    const getUserDetails = async () => {
      try {
        const { data } = await axiosApi.get(api.GET_ACCOUNT_DETAILS)
        setUserDetails(data)
      } catch (e) {
        setUserDetails(null)
        console.log('Get user details ERROR', e)
        logout()
      }
    }
    if (authTokens && !userDetails) getUserDetails()
  }, [])

  return (
    <MagicConnectUserContext.Provider
      value={{
        provider,
        signer,
        magic,
        onClose,
        onOpen,
        isOpen,
        detailModalOpen,
        setDetailModalOpen,
        collectionId,
        setCollectionId,
        userDetails,
        setUserDetails,
      }}
    >
      {children}
    </MagicConnectUserContext.Provider>
  )
}

export default MagicConnectUserProvider
