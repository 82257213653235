import { useEffect, useState } from 'react'
import { useNetwork } from 'wagmi'

export enum NETWORK_STATUS {
  UNDEFINED,
  CORRECT,
  INCORRECT,
}

export const useNetworkStatusV2 = () => {
  const { chain } = useNetwork()

  const [networkStatus, setNetworkStatus] = useState<NETWORK_STATUS>(NETWORK_STATUS.UNDEFINED)

  useEffect(() => {
    ;(async () => {
      const chainId = process.env.NEXT_PUBLIC_CHAIN_ID || 1
      if (chain && chainId) {
        setNetworkStatus(Number(chain.id) === Number(chainId) ? NETWORK_STATUS.CORRECT : NETWORK_STATUS.INCORRECT)
      }
    })()
  }, [chain])

  return { networkStatus }
}
