import { useRouter } from 'next/router'
import { routes } from '../constants/routes'
import { useStore } from '../zustand/store'

export const useCollectionExit = () => {
  const router = useRouter()
  const { setExitCollectionModal, setCollectionId, setCollection, setNfts } = useStore()

  const exitCollectionCreation = () => {
    setCollectionId(null)
    setCollection(null)
    setNfts(null)
    setExitCollectionModal(false)
    router.push(routes.COLLECTIONS)
  }

  return { exitCollectionCreation }
}
