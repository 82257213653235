import React, { FC } from 'react'
import { StyledButton } from './Button.styles'

export type ButtonProps = {
  color?: 'primary' | 'secondary' | 'alt'
  onClick?: () => void
  disabled?: boolean
  variant?: 'outline' | 'filled'
  type?: 'button' | 'submit'
  neon?: boolean
  children: any
}

export const Button: FC<ButtonProps> = ({ color = 'primary', children, disabled = false, onClick, variant = 'filled', type = 'button', neon }) => {
  return (
    <StyledButton neon={neon} color={color} onClick={onClick} disabled={disabled} variant={variant} type={type}>
      {children}
    </StyledButton>
  )
}
