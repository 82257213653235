import axios from 'axios'

const useAxios = () => {
  const baseURL = process.env.NEXT_PUBLIC_BACKEND_URL

  const axiosInstance = axios.create({
    baseURL,
  })

  return axiosInstance
}

export default useAxios
