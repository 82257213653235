import { useEffect, useState } from 'react'
import { NETWORK_STATUS, useNetworkStatus } from './useNetworkStatus'
import { useUniqueCollection } from './useUniqueCollection'

/**
 * Determines if a collection is sold out based on max supply and total supply
 */
export const useMintSoldOut = () => {
  const [soldOut, setSoldOut] = useState(false)
  const { uniqueCollection } = useUniqueCollection()
  const { networkStatus } = useNetworkStatus()

  useEffect(() => {
    ;(async () => {
      if (uniqueCollection && networkStatus === NETWORK_STATUS.CORRECT) {
        try {
          const maxSupply = +(await uniqueCollection.maxSupply())
          const totalSupply = +(await uniqueCollection.totalSupply()).toString()
          if (totalSupply >= maxSupply) {
            setSoldOut(true)
          }
        } catch (e) {
          console.info('Called too early maxSupply/totalSupply!')
        }
      }
    })()
  }, [uniqueCollection, networkStatus])

  return { soldOut }
}
