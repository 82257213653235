export const Heading = {
  sizes: {
    xl: {
      fontSize: '38px',
      fontWeight: 800,
      color: 'primary.black',
    },
    lg: {
      fontSize: '28px',
      fontWeight: 800,
      color: 'primary.black',
    },
  },
}
