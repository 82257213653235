import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export const StyledLinkNative = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.text.primary};
`

type LinkProps = {
  href: string
  className?: string
  children: any
}

export const LinkNative: FunctionComponent<LinkProps> = ({ href, children }) => {
  return (
    <StyledLinkNative href={href} target="_blank" rel="noreferrer">
      {children}
    </StyledLinkNative>
  )
}
