import styled from 'styled-components'

export const StyledMusicPlayerToggle = styled.button<{ isExpanded: boolean }>`
  background: none;
  color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: -20px;
  right: -20px;
  border: 2px solid ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  background: ${({ theme }) => theme.palette.common.black};

  &:hover {
    border-color: ${({ theme }) => theme.palette.secondary.main};
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  & svg {
    transition: transform 0.15s ease-in-out;
    transform-origin: center;
    ${({ isExpanded }) =>
      isExpanded &&
      `
    transform: rotate(135deg);
  `}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`
