import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { ethers } from 'ethers'

interface Owner {
  ownerAddress: string
  tokenBalances: {
    tokenId: string
    balance: number
  }[]
}

interface fetchTokenIdsArgs {
  address: string
}

export const fetchTokenIds = async ({ address }: fetchTokenIdsArgs) => {
  try {
    const { data } = await axios.get(
      `https://eth-mainnet.g.alchemy.com/nft/v2/l6sfF9R4oyrX1HQTaN-N2rygbQJjkBOE/getOwnersForCollection?contractAddress=0xaa02478d5b550874d2931e6ce067b4c337991dd2&withTokenBalances=true`
    )
    const owner: Owner = data?.ownerAddresses?.find((owner: Owner) => owner.ownerAddress.toLowerCase() === address.toLowerCase())
    if (owner) {
      const tokenIdsFromOwner = owner.tokenBalances.map(token => ethers.BigNumber.from(token.tokenId).toNumber())
      return tokenIdsFromOwner as number[]
    }
    return [] as number[]
  } catch (e) {
    Sentry.captureException(e)
    Sentry.captureMessage(
      `Call against https://eth-mainnet.g.alchemy.com/nft/v2/l6sfF9R4oyrX1HQTaN-N2rygbQJjkBOE/getOwnersForCollection?contractAddress=0xaa02478d5b550874d2931e6ce067b4c337991dd2&withTokenBalances=true failed from address ${address}`,
      'error'
    )
    return []
  }
}
