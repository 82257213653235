// TODO: Remove this modal and replace it for SucceedBuyModal (which is the same)

import React, { useContext, useState } from 'react'
import { ModalOverlay, ModalCloseButton, ModalBody, Button, Modal, Text, Box, VStack, Link, ModalContent } from '@chakra-ui/react'
import { PaymentContext } from '@fuel/payment'

import Image from 'next/image'
import { useRouter } from 'next/router'
import { MagicConnectUserContext } from '@fuel/auth'
import styled from '@emotion/styled'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { storage } from '@fuel/core'

export const StyledModalContent = styled(ModalContent)`
  background: #0e0e16;
  color: #e8ecfb;
  box-shadow: 0px 4px 30px rgba(14, 14, 22, 0.45);
  border-radius: 17px;
  text-align: center;
  max-width: 650px;
  border-radius: 17px;
  padding: 0px 100px 0px 100px;
  height: fit-content;
  margin: 0px;
  @media (max-width: 768px) {
    margin: 5px;
    padding: 10px;
  }
`

const SucceedPaymentModal = () => {
  const { isOpenSucceedModal, onCloseSucceedModal } = useContext<any>(PaymentContext)
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
  const txHash = getLocalStorage(storage.TX_HASH)

  const router = useRouter()

  const openProfile = async () => {
    router.push(process.env.NEXT_PUBLIC_WALLET ?? 'https://wallet.onfuel.io/', '_blank')
  }

  return (
    <>
      <Modal isOpen={isOpenSucceedModal} onClose={onCloseSucceedModal} isCentered>
        <ModalOverlay />
        <StyledModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack mt="40px" gap="10px">
              <Box>
                <Image src="/images/check.png" alt="success" width="30px" height="30px" />
                <Text fontWeight={800} lineHeight="44px" fontSize="28px" color="#E8ECFB">
                  Success
                </Text>
              </Box>
              <Text fontWeight={500} lineHeight="24px" fontSize="16px" color="#E8ECFB">
                You can access NFT from your wallet
              </Text>
              <Box>
                <Image
                  src={selectedEdition?.image}
                  width="208px"
                  height="208px"
                  layout="intrinsic"
                  alt="nft"
                  quality={50}
                  style={{
                    borderRadius: '10px',
                  }}
                />
                <Text fontWeight={800} lineHeight="44px" fontSize="28px" color="#E8ECFB" mt="10px">
                  {selectedEdition?.name}
                </Text>
              </Box>
            </VStack>
            <Button fontWeight={600} fontSize="16px" mt="46px" bg="#E8ECFB" color="#4F5079" onClick={openProfile}>
              Go to wallet
            </Button>
            <Box mt="20px" pb="50px">
              {txHash ? (
                <Link
                  target="blank"
                  fontFamily="inter"
                  fontWeight={600}
                  lineHeight="24px"
                  fontSize="16px"
                  mt="33px"
                  letterSpacing="-0.5px"
                  pb="30px"
                  color="secondary.accent"
                  href={`https://polygonscan.com/tx/${txHash}`}
                >
                  See transaction
                </Link>
              ) : null}
            </Box>
          </ModalBody>
        </StyledModalContent>
      </Modal>
    </>
  )
}

export default SucceedPaymentModal
