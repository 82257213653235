import { Box, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { AuthMagicConnectButton } from '../../index'

const buttonStyles = {
  background: '#ADFFB5',
  color: '#4F5079',
  letterSpacing: '-0.5px',
  borderRadius: '12px',
}

export default function LoginTabPanel({ onClose, setAlwaysOpen }: { onClose: () => void; setAlwaysOpen: (arg0: boolean) => void }) {
  const { t } = useTranslation('auth')

  return (
    <VStack textAlign="center" gap="20px">
      <Text fontFamily="Inter" fontWeight={800} fontSize="40px" lineHeight="44px" color="#1A1347">
        {t('welcome_back')}
      </Text>

      <Text fontSize="16px" fontStyle="normal" fontFamily="Inter" color="#4F5079" textAlign="center" lineHeight="24px" fontWeight={500}>
        {t('sign_in_get_started')}
      </Text>
      <Box
        textAlign="center"
        onClick={() => {
          setAlwaysOpen(false)
          onClose()
        }}
      >
        <AuthMagicConnectButton connectText={t('connect_email_metamask')} buttonStyles={buttonStyles} setAlwaysOpen={setAlwaysOpen} />
      </Box>
    </VStack>
  )
}
