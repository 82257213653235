import { PaymentMethod } from '@fuel/payment/model/PaymentMethod'
import { FiCreditCard } from 'react-icons/fi'
import { GiTwoCoins } from 'react-icons/gi'
export const paymentMethods: PaymentMethod[] = [
  {
    id: '1',
    icon: FiCreditCard,
    title: 'credit_card',
    subtitle: 'credit_card_description',
    recommended: false,
    payment_id: 'stripe',
  },
  {
    id: '2',
    icon: GiTwoCoins,
    title: 'crypto_pay',
    subtitle: 'crypto_pay_description',
    recommended: false,
    payment_id: 'coinbase',
  },
]

export enum PurchaseStatus {
  NOT_IN_QUEUE = 'not_in_queue',
  NFT_AVAILABLE = 'nft_available',
  ENQUEUED = 'enqueued',
  SOLD_OUT = 'sold_out',
  START_POLLING = 'start_polling',
  NOT_ENOUGH_ITEMS = 'not_enough_items',
}
