export const api = {
  // Creators
  UPDATE_ARTIST_PROFILE: (id: any) => `/creators/creator_profiles/${id}`,
  GET_ARTIST_PROFILE: `/creators/creator_profiles`,
  CREATE_ARTIST_PROFILE: '/creators/creator_profiles',
  COLLECTIONS: '/creators/collections',
  EDITION_COLLECTIONS: '/creators/editions',
  PUBLISH: '/publish',
  ENABLE_FOR_SALE: '/enable_for_sale',
  NFT: '/creators/nfts',
  CREATE_DROP_PAGE: (collection_id: any) => `/creators/collections/${collection_id}/drop_pages`,
  UPDATE_DROP_PAGE: (collection_id: any, drop_page_id: any) => `/creators/collections/${collection_id}/drop_pages/${drop_page_id}`,
  DROP_PAGE_DETAILS: (collection_id: any, drop_page_id: any) => `/creators/collections/${collection_id}/drop_pages/${drop_page_id}`,

  // Drops
  GET_DROP_PAGE: (slug: string) => `users/drop_pages/${slug}`,
  PURCHASE_START: '/users/purchase/start',
  QUEUE_STATUS: '/users/purchase/queue_status',
  QUEUE_LEAVE: '/users/purchase/leave_queue',
  GET_TX_HASH: `/users/purchase/tx_hash`,
  LIMITED_EDITION_PURCHASE_START: '/users/limited_edition_purchase/start',
  SELECT_PAYMENT_METHOD: '/users/purchase/select_payment_method',
  SELECT_LIMITED_EDITION_PAYMENT_METHOD: '/users/limited_edition_purchase/select_payment_method',
  SELECT_UNLIMITED_EDITION_PAYMENT_METHOD: '/users/unlimited_edition_purchase/select_payment_method',
  QUEUE_TICKET_STATUS: '/users/limited_edition_purchase/queue_status',
  LEAVE_TICKET_QUEUE: '/users/limited_edition_purchase/leave_queue',
  GET_TICKET_TX_HASH: `/users/limited_edition_purchase/tx_hash`,
  GET_QUEUE_PURCHASE_STATUS: (edition_id: number) => `/users/limited_edition_purchase/queue_status?edition_id=${edition_id}`,

  // Shop
  SHOP_CONFIG: (creator_id: string, id: string) => `/users/creators/${creator_id}/collections/${id}/shop_config`,

  COLLECTION: (creator_id: string, id: string) => `/users/creators/${creator_id}/collections/${id}`,
  OWNED_TOKENS_ON_COLLECTION: (creator_id: string, collection_id: string, owner_address: string) =>
    `/users/creators/${creator_id}/collections/${collection_id}/owned_tokens?owner_address=${owner_address}`,

  // Authentication
  CONNECT_WALLET: '/users/connect_wallet',
  VERIFY_SIGNATURE: '/users/verify_signature',
  REFRESH_TOKEN: '/users/refresh_token',
  GET_ACCOUNT_DETAILS: '/users/account',
  VERIFY_PHONE: '/users/verify_phone',
  VERIFY_OTP: '/users/verify_otp',
  EMAIL_VERIFICATION: '/users/verify_email',
  DISCORD_NAME: '/users/discord',

  // Allowlist proof
  ALLOW_LIST_PROOF: '/users/allowlist_proof',

  // Token Ids
  TOKEN_IDS: '/users/token_ids',
}
