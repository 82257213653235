export const FormService = {
  trimWhiteSpaces: (data: { [key: string]: any }) => {
    const finalData = data
    Object.keys(data).map(key => {
      if (typeof data[key] === 'string') {
        finalData[key] = data[key].replace(/^\s+|\s+$/gm, '')
      }
    })

    return finalData
  },
}
