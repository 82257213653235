import { Menu, MenuButton, Button, Skeleton, Avatar, MenuList, MenuItem } from '@chakra-ui/react'
import { useRouter } from 'next/dist/client/router'
import React, { useCallback } from 'react'
import { useAccount } from 'wagmi'
import { ChevronDownIcon } from '@chakra-ui/icons'

export const RainbowCustomButtonMenu = ({
  openAccountModal,
  avatarButton,
  connectedText,
  profileText,
  logoutText,
  menuListStyles,
  menuItemStyles,
  logoutItemStyles,
  buttonStyles,
  showProfile,
}: {
  openAccountModal: any
  logoutText: string
  avatarButton?: boolean
  connectedText?: string
  profileText?: string
  menuListStyles?: any
  menuItemStyles?: any
  logoutItemStyles?: any
  buttonStyles?: any
  showProfile?: boolean
}) => {
  const router = useRouter()
  const { address } = useAccount()

  const openWallet = async () => {
    if (window.location.href.includes('wallet') || window.location.href.includes('creators')) {
      router.push('/profile')
    } else window.open('https://wallet.onfuel.io/', '_blank')
  }

  const avatarImage = `https://api.multiavatar.com/${address}.svg`

  const mergedLogoutStyles = {
    ...menuItemStyles,
    ...logoutItemStyles,
  }

  const RainbowMenuComponent = useCallback(
    () => (
      <Menu>
        {avatarButton ? (
          <MenuButton id="menu-button" as={Button} variant="link">
            <Skeleton isLoaded={!!avatarImage} borderRadius="50%">
              <Avatar src={avatarImage} w="42px" h="42px" />
            </Skeleton>
          </MenuButton>
        ) : (
          <MenuButton id="menu-button-1" as={Button} rightIcon={<ChevronDownIcon />} sx={buttonStyles}>
            {connectedText}
          </MenuButton>
        )}
        <MenuList style={menuListStyles}>
          {showProfile ? (
            <MenuItem style={menuItemStyles} onClick={openWallet}>
              {profileText}
            </MenuItem>
          ) : (
            <MenuItem
              sx={{
                cursor: 'default',
                '&:focus': {
                  background: 'transparent',
                },
              }}
            >
              {address?.slice(0, 6) + '...' + address?.slice(-4)}
            </MenuItem>
          )}
          <MenuItem style={mergedLogoutStyles} onClick={openAccountModal}>
            {logoutText}
          </MenuItem>
        </MenuList>
      </Menu>
    ),
    []
  )

  return <RainbowMenuComponent />
}
