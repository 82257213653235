import { MINT_STATUS } from '../hooks/useUniqueCollectionContractV2Payload'
import { getMintedInfoFromAddress } from './getMintedInfoFromAddress'

interface HandleSetItemsArgs {
  mintPayload: any
  setItems: any
  uniqueCollectionContractV2: any
  address: string | undefined
}

export const handleSetItems = async ({ mintPayload, setItems, uniqueCollectionContractV2, address }: HandleSetItemsArgs) => {
  if (mintPayload.status == MINT_STATUS.NOW_PUBLIC) {
    setItems([])
    return
  } else {
    const { mintedInPhase } = await getMintedInfoFromAddress({
      uniqueCollectionContractV2,
      address,
      mintPhaseIndex: mintPayload.mintPhase?.mintPhaseIndex,
    })

    setItems([
      // {
      //   text: `${listName(mintPayload.mintPhase?.mintPhaseIndex)} minting is live.`,
      //   success: true,
      //   loading: false,
      // },
      // {
      //   text: 'You are on the allowlist.',
      //   success: mintPayload.status == MINT_STATUS.NOW_ON_ALLOW_LIST,
      //   loading: true,
      // },
      // {
      //   text: `Have not reached the maximum mint limit of ${mintPayload.mintPhase?.maxMintPerAddress}`,
      //   success: Number(mintedInPhase) < Number(mintPayload.mintPhase?.maxMintPerAddress),
      //   loading: true,
      // },

      {
        text: 'Genesis Pass Holder',
        success: true,
        loading: false,
        errorText: 'you are not a Genesis Pass Holder',
      },
      {
        text: 'Free NFT unclaimed',
        success: false,
        loading: false,
        errorText: 'you have already claimed your free NFT',
      },
    ])
  }
}
