import React from 'react'
import { Select, OptionBase, GroupBase, ChakraStylesConfig } from 'chakra-react-select'

interface SelectOptions extends OptionBase {
  label: string
  value: string
}

export default function CustomSelect({
  onChange,
  options,
  defaultValue = null,
}: {
  onChange: (value: any) => void
  options: SelectOptions[]
  defaultValue?: SelectOptions | null
}) {
  const chakraStyles: ChakraStylesConfig = {
    control: provided => ({
      ...provided,
      borderRadius: '12px',
      height: '50px',
      display: 'flex',
      border: 'none',
      background: '#FAFAFC',
      fontFamily: 'Inter,sans-serif;',
      fontSize: '16px',
      _focus: {
        border: '2px solid',
        borderColor: 'purple.500',
        boxShadow: 'none',
      },
    }),
    container: provided => ({
      ...provided,
      cursor: 'pointer',
    }),
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: '12px',
      padding: '6px',
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#F6F5FF' : 'white',
      color: state.isSelected ? 'purple.500' : 'black',
      borderRadius: '12px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: state.isSelected ? 600 : 500,
      marginTop: '4px',
      _hover: {
        background: '#FAFAFC',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'purple.500',
      cursor: 'pointer',
      transition: 'all 0.2s',
      _active: {
        transform: 'scale(0.9)',
      },
      transform: () => {
        if (state.selectProps.menuIsOpen) {
          return 'rotate(180deg)'
        }
      },
    }),
  }

  const getDefaultValue = () => {
    if (typeof defaultValue === 'string') {
      return options.find(option => option.value === defaultValue)
    } else return defaultValue
  }

  return (
    <Select<SelectOptions, true, GroupBase<SelectOptions>>
      useBasicStyles={true}
      placeholder="Select option"
      size="lg"
      value={getDefaultValue()}
      onChange={onChange}
      options={options}
      chakraStyles={chakraStyles as any}
    />
  )
}
