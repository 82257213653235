import { api } from '@fuel/core'
import { getLocalStorage, setLocalStorage } from '@fuel/core/services/localStorage'
import { storage } from '@fuel/core'
import axios from 'axios'

export const refreshToken = async (req: any, logout: Function) => {
  const baseURL = process.env.NEXT_PUBLIC_BACKEND_URL
  const authTokens = getLocalStorage(storage.AUTH_TOKENS)

  try {
    const response = await axios.post(
      `${baseURL}/${api.REFRESH_TOKEN}`,
      {
        authentication_token: authTokens?.accessToken,
        refresh_token: authTokens?.refreshToken,
      },
      {
        headers: {
          Authorization: `Token ${authTokens?.accessToken}`,
        },
      }
    )

    setLocalStorage(storage.AUTH_TOKENS, {
      accessToken: response.data.authentication_token,
      refreshToken: response.data.refresh_token,
    })

    if (req && req.headers) {
      req.headers.Authorization = `Token ${response.data.authentication_token}`
    }

    return req
  } catch (e) {
    console.log('Error refreshing token, logging out...')
    logout()
    window.location.reload()
  }
}
