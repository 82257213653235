import { useState, useCallback, useEffect } from 'react'
import { useUniqueCollectionContractV2 } from '@fuel/nft'

export const useMaxSupply = (contractAddress: string) => {
  const { uniqueCollectionContractV2 } = useUniqueCollectionContractV2({ contractAddress })
  const [maxSupply, setMaxSupply] = useState<number>(0)

  const getMaxSupply = useCallback(async () => {
    const supply = await uniqueCollectionContractV2?.maxSupply()
    if (!supply) return
    setMaxSupply(Number(supply))
  }, [uniqueCollectionContractV2])

  useEffect(() => {
    if (!uniqueCollectionContractV2) return
    getMaxSupply()
  }, [uniqueCollectionContractV2, getMaxSupply])

  return maxSupply
}
