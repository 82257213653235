import { useContext, useEffect, useState } from 'react'
import { Contract, ethers } from 'ethers'
import { abi } from '../../constants/abi'

import { CollectionContext } from '@fuel/nft/contexts/CollectionContext'

export const useUniqueCollection = () => {
  const { collection }: any = useContext(CollectionContext)
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null)
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner | null>(null)
  const [uniqueCollection, setUniqueCollection] = useState<Contract | null>(null)

  useEffect(() => {
    if (window.ethereum) {
      const getProvider = new ethers.providers.Web3Provider(window.ethereum as any)
      setProvider(provider)
      setSigner(getProvider.getSigner())
    }
  }, [])

  useEffect(() => {
    if (signer && collection?.contract_address) {
      const contract = new ethers.Contract(collection.contract_address, abi.COLLECTION_ABI, signer)

      setUniqueCollection(contract)
    }
  }, [signer, collection, setUniqueCollection])

  return { uniqueCollection }
}
