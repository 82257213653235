import { useMutation } from 'react-query'
import { useAxiosWithAuth } from '@fuel/api'
import { api } from '@fuel/core'

const useVerifyPhone = () => {
  const axiosApi = useAxiosWithAuth()
  const verifyPhone = useMutation(async (phone_number: any) => {
    const { data } = await axiosApi.post(api.VERIFY_PHONE, {
      phone_number,
    })

    return data
  })
  const verifyOtp = useMutation(async ({ phone_number, otp }: any) => {
    const { data } = await axiosApi.post(api.VERIFY_OTP, {
      otp,
      phone_number,
    })

    return data
  })

  return {
    verifyPhone,
    verifyOtp,
  }
}

export default useVerifyPhone
