import React, { FC } from 'react'
import styled from 'styled-components'

export const StyledLinkButton = styled.button`
  background: none;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.palette.common.black};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 0;
  cursor: pointer;
`

type LinkButtonProps = {
  onClick: () => void
  children: any
}

export const LinkButton: FC<LinkButtonProps> = ({ children, onClick }) => {
  return <StyledLinkButton onClick={onClick}>{children}</StyledLinkButton>
}
