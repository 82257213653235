import * as React from 'react'
import WagmiProvider from './WagmiProvider'
import CryptoNavtiveUserProvider from './CryptoNativeUserProvider'
import DropPageDataProvider from '@fuel/auth/context/DropPageDataProvider'

export function CryptoNativeWrapperProvider({ children }: any) {
  return (
    <CryptoNavtiveUserProvider>
      <WagmiProvider>
        <DropPageDataProvider>{children}</DropPageDataProvider>
      </WagmiProvider>
    </CryptoNavtiveUserProvider>
  )
}
