import styled from '@emotion/styled'
import { Text } from '@chakra-ui/react'

export const StyledHeader = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: #e8ecfb;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
  }
`

export const StyledHelperText = styled(Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #cfd4eb;

  //mobile
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`
