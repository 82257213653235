import { useContext, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { CollectionContext } from '@fuel/nft'
import { useUniqueCollectionContract } from '@fuel/nft'
import { MINT_STATUS } from '@fuel/nft'
import { NETWORK_STATUS, useNetworkStatus } from '@fuel/nft'
import * as Sentry from '@sentry/browser'
import { abi } from '../../constants/abi'

export const useMintExceptions = (gasPrice: string, proof: any, mintStatus: MINT_STATUS) => {
  const { networkStatus } = useNetworkStatus()
  const { collection }: any = useContext(CollectionContext)
  const { uniqueCollectionContract } = useUniqueCollectionContract({ contractAddress: collection?.contract_address, abi: abi.COLLECTION_ABI })
  const [hasEnoughFunds, setHasEnoughFunds] = useState(true)
  const walletBalance = 100

  useEffect(() => {
    ;(async () => {
      if (uniqueCollectionContract && networkStatus === NETWORK_STATUS.CORRECT) {
        const price = await uniqueCollectionContract.mintPrice()
        try {
          let estimatedGas = null
          // TODO: Check if proof exists for valid argument

          // Estimated gas for allowListMint
          if (mintStatus === MINT_STATUS.ALLOW_LIST) {
            estimatedGas = await uniqueCollectionContract.estimateGas.allowListMint(proof, { value: price })
          }

          // Estimated gas for publicMint
          if (mintStatus === MINT_STATUS.PUBLIC) {
            estimatedGas = await uniqueCollectionContract.estimateGas.publicMint({
              value: price,
            })
          }

          // Compare price on the estimated gas and wallet balance
          if (estimatedGas) {
            const gasPriceInWei = ethers.utils.parseUnits(estimatedGas.toString(), 'gwei').mul(gasPrice)
            if (price.add(gasPriceInWei).lte(walletBalance)) {
              setHasEnoughFunds(true)
              Sentry.addBreadcrumb({
                category: 'mint',
                message: 'Not enough funds gas + token price!',
                level: 'info',
              })
            }
          }
          // TODO: Catch not enough gas!
        } catch (e: any) {
          if (e.code === 'INSUFFICIENT_FUNDS') {
            console.log('INSUFFICIENT_FUNDS')
            setHasEnoughFunds(false)
          }
        }
      }
    })()
  }, [walletBalance, uniqueCollectionContract, gasPrice, networkStatus, mintStatus, proof])

  return { hasEnoughFunds }
}
