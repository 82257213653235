import mixpanel from 'mixpanel-browser'
import { useAccount } from 'wagmi'
import { general } from '../../constants/general'

export enum MINT_TRACK_STATUS {
  MINT_PRESSED = 'Mint Button Pressed',
  MINT_STARTED = 'Mint Started',
  MINT_FAILED = 'Mint Failed',
  MINT_COMPLETED = 'Mint Completed',
}

export const useTrackMint = () => {
  const { address } = useAccount()

  const trackMint = (mintStatus: MINT_TRACK_STATUS) => {
    if (address && general.HAS_MIXPANEL_ACCESS) {
      mixpanel.track(mintStatus, {
        distinct_id: address,
      })
    }
  }

  return { trackMint }
}
