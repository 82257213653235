export const collectionSlice = set => ({
  exitCollectionModal: false,
  setExitCollectionModal: exitCollectionModal => set(() => ({ exitCollectionModal })),

  publishCollectionModal: false,
  setPublishCollectionModal: publishCollectionModal => set(() => ({ publishCollectionModal })),

  collectionCompleteModal: false,
  setCollectionCompleteModal: collectionCompleteModal => set(() => ({ collectionCompleteModal })),

  collection: null,
  setCollection: collection => set(() => ({ collection })),

  collectionId: null,
  setCollectionId: collectionId => set(() => ({ collectionId })),
})
