export const userApi = {
  LOG_IN: '/users/register',
  CONNECT_WALLET: '/users/connect_wallet',
  VERIFY_SIGNATURE: '/users/verify_signature',
  EMAIL_VERIFICATION: '/users/email',
  REFRESH_TOKEN: '/users/refresh_token',
  VERIFY_PHONE: '/users/verify_phone_number',
  VERIFY_OTP: '/users/verify_otp',
  GET_ACCOUNT_DETAILS: '/users/account',
}

export const userQueries = {
  GET_ACCOUNT_DETAILS: 'get_account',
}
