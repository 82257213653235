import { FC, useContext, useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Avatar, Button, Menu, MenuButton, MenuItem, MenuList, Skeleton } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { DefaultTFuncReturn } from 'i18next'
import styled from '@emotion/styled'
import { MagicConnectUserContext, useLogout, useMagicConnect } from '@fuel/auth'

interface Props {
  buttonStyles?: any
  menuListStyles?: any
  menuItemStyles?: any
  connectText?: string | DefaultTFuncReturn
  connectedText?: string | DefaultTFuncReturn
  profileText?: string | DefaultTFuncReturn
  logoutText?: string | DefaultTFuncReturn
  redirectPathOnLogout?: string
  avatarButton?: boolean
  setAlwaysOpen?: (arg0: boolean) => void
  hideWallet?: boolean
}

const StyledMenuButton = styled(MenuButton)`
  padding: 0 50px;
  & span {
    min-width: initial;
  }
`

export const AuthMagicConnectButton: FC<Props> = ({
  connectText = 'Connect',
  connectedText = 'My Account',
  profileText = 'My Wallet',
  logoutText = 'Logout',
  menuItemStyles = null,
  menuListStyles = null,
  buttonStyles = {
    borderRadius: '50px',
  },
  redirectPathOnLogout = '/',
  avatarButton = false,
  setAlwaysOpen = () => {},
  hideWallet = false,
}) => {
  const { userDetails } = useContext<any>(MagicConnectUserContext)
  const [isDisabled, setDisabled] = useState(false)
  const { connectMagic, loading } = useMagicConnect({
    setDisabled,
  })
  const { logout } = useLogout()
  const router = useRouter()

  const userWalletPublicAddress = userDetails?.wallet_public_address
  if (!userWalletPublicAddress) {
    return (
      <Button isLoading={loading} px="30px" onClick={connectMagic} isDisabled={isDisabled} {...buttonStyles}>
        {connectText}
      </Button>
    )
  }

  const openWallet = async () => {
    if (window.location.href.includes('wallet') || window.location.href.includes('creators')) {
      router.push('/profile')
    } else window.open(process.env.NEXT_PUBLIC_WALLET ?? 'https://wallet.onfuel.io/', '_blank')
  }

  const handleLogout = () => {
    router.push(redirectPathOnLogout)
    logout()
  }

  const avatarImage = `https://api.multiavatar.com/${userWalletPublicAddress}.svg`

  return (
    <Menu>
      {avatarButton ? (
        <MenuButton as={Button} variant="link">
          <Skeleton isLoaded={!!avatarImage} borderRadius="50%">
            <Avatar src={avatarImage} w="42px" h="42px" />
          </Skeleton>
        </MenuButton>
      ) : (
        <StyledMenuButton
          as={Button}
          iconSpacing={2}
          px="30px"
          rightIcon={<ChevronDownIcon fontSize="25px" />}
          style={buttonStyles}
          lineHeight="16px"
        >
          {connectedText}
        </StyledMenuButton>
      )}
      <MenuList style={menuListStyles ?? null}>
        {!hideWallet && <MenuItem onClick={openWallet}>{profileText}</MenuItem>}
        <MenuItem onClick={handleLogout}>{logoutText}</MenuItem>
      </MenuList>
    </Menu>
  )
}
