import { useDisclosure } from '@chakra-ui/react'
import { createContext } from 'react'

export const ModalsContext: any = createContext<any>({})

export const ModalsProvider = ({ children }: any) => {
  const { isOpen: isOpenErrorModal, onOpen: onOpenErrorModal, onClose: onCloseErrorModal } = useDisclosure()

  return (
    <ModalsContext.Provider
      value={{
        isOpenErrorModal,
        onOpenErrorModal,
        onCloseErrorModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}
