import * as React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { AuthenticationStatus as AuthStatus } from '@rainbow-me/rainbowkit'
import { useState } from 'react'
import { storage, api } from '@fuel/core'
import { getLocalStorage, removeLocalStorage } from '@fuel/core/services/localStorage'
import { useAxiosWithAuth } from '@fuel/api'

export const CryptoNativeUserContext = React.createContext<any>({}) as any

const CryptoNavtiveUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [address, setAddress] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [authStatus, setAuthStatus] = useState<AuthStatus>('unauthenticated')
  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const axiosApi = useAxiosWithAuth()
  const [userDetails, setUserDetails] = React.useState<any>(null)

  React.useEffect(() => {
    const authTokens = getLocalStorage(storage.AUTH_TOKENS)

    const getUserDetails = async () => {
      try {
        const { data } = await axiosApi.get(api.GET_ACCOUNT_DETAILS)
        setUserDetails(data)
      } catch (e) {
        setUserDetails(null)
        removeLocalStorage(storage.AUTH_TOKENS)
        setAuthStatus('unauthenticated')
      }
    }
    if (authTokens && !userDetails) getUserDetails()
  }, [])

  return (
    <CryptoNativeUserContext.Provider
      value={{
        address,
        setAddress,
        setAuthStatus,
        authStatus,
        onClose,
        onOpen,
        isOpen,
        detailModalOpen,
        setDetailModalOpen,
      }}
    >
      {children}
    </CryptoNativeUserContext.Provider>
  )
}

export default CryptoNavtiveUserProvider
