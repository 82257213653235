import { UniqueCollectionInitializableV2Abi } from '@fuel/payment/constants/UniqueCollectionInitializableV2.abi'
import { useUniqueCollectionContract } from '../useUniqueCollectionContract'

interface UseUniqueCollectionV2Args {
  contractAddress: string
}

export const useUniqueCollectionContractV2 = ({ contractAddress }: UseUniqueCollectionV2Args) => {
  const { uniqueCollectionContract } = useUniqueCollectionContract({
    contractAddress,
    abi: UniqueCollectionInitializableV2Abi,
  })

  return { uniqueCollectionContractV2: uniqueCollectionContract }
}
