import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Box } from '../Box'
import { Text } from '../../typography/text/Text'

library.add(faTimes)

type SuccessModalProps = {
  modalIsOpen: boolean
  closeModal: () => void
  title: any | string
  primaryCta?: any
  secondaryCta?: any
}

const customStyles = {
  overlay: {
    zIndex: 998,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    minWidth: '35vw',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
  },
}

export const StyledCloseButton = styled.button`
  background: none;
  border: none;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    transform: scale(1.2);
  }
`

export const StyledSuccessModal = styled<any>(ReactModal)`
  position: absolute;
  inset: 50% auto auto 50%;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  margin-right: -50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  max-width: 600px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(15)} ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(10)}
    ${({ theme }) => theme.spacing(10)};

  img[alt='success icon'] {
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-bottom: ${({ theme }) => theme.spacing(12)};
  }

  img[alt='modal decor'] {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(20)}
      ${({ theme }) => theme.spacing(5)};
  }
`

export const SuccessModal: FC<SuccessModalProps> = ({ closeModal, modalIsOpen, title, primaryCta, secondaryCta }) => {
  return (
    <StyledSuccessModal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} closeTimeoutMS={300}>
      <StyledCloseButton>
        <FontAwesomeIcon icon={faTimes} onClick={closeModal} size="lg" />
      </StyledCloseButton>
      <img src="/images/success-icon.svg" alt="success icon" />
      <img src="/images/decor.png" alt="modal decor" />
      <Text color="secondary" fontWeight="semibold">
        {title}
      </Text>
      <Box mt={8} mb={5}>
        {primaryCta}
      </Box>
      {secondaryCta}
    </StyledSuccessModal>
  )
}
