import { useContext, useEffect, useState } from 'react'
import { Button, HStack, PinInput, Spinner, VStack, Text, Image } from '@chakra-ui/react'
import useVerifyPhone from '@fuel/auth/hooks/useVerifyPhone'
import { PhoneVerificationContext } from '@fuel/auth/context/PhoneVerificationProvider'
import { StyledSuccessDescription, StyledSuccessTitle, StyledPinInput, StyledContainer, StyledErrorText } from './PhoneVerifyPin.styled'

const PhoneVerifyPin = ({ handleResendCode, isSuccess, setIsSuccess }: any) => {
  const { setModalOpen, phoneNumber } = useContext<any>(PhoneVerificationContext as any)
  const { verifyOtp } = useVerifyPhone()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [timer, setTimer] = useState(5)

  useEffect(() => {
    if (isSuccess) {
      if (timer > 0) {
        setTimeout(() => {
          setTimer(timer - 1)
        }, 1000)
      }

      if (timer === 0) {
        setModalOpen(false)
      }
    }
  })

  const handleVerifyOtp = async (value: any) => {
    setLoading(true)

    try {
      await verifyOtp.mutateAsync({
        otp: value,
        phone_number: phoneNumber,
      })

      setIsSuccess(true)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <Spinner my="50px" thickness="15px" speed="1s" emptyColor="gray.200" color="alt.main" boxSize={24} />
  else if (isSuccess) {
    return (
      <VStack my="40px">
        <Image src="/images/success-black-background.svg" alt="success" bg="transparent" />

        <StyledSuccessTitle>Başarılı!</StyledSuccessTitle>
        <StyledSuccessDescription>Telefon numaran doğrulandı</StyledSuccessDescription>

        <Text>Closing in {timer}...</Text>
      </VStack>
    )
  } else
    return (
      <StyledContainer>
        <HStack placeContent="center" mt="30px">
          <PinInput type="number" onComplete={value => handleVerifyOtp(value)} size="lg" placeholder="">
            <StyledPinInput />
            <StyledPinInput />
            <StyledPinInput />
            <StyledPinInput />
            <StyledPinInput />
            <StyledPinInput />
          </PinInput>
        </HStack>

        {error && <StyledErrorText mt="10px">Kod yanlış</StyledErrorText>}
        <VStack gap="30px" mb="40px">
          <Button variant="ghost" _hover={{ bg: 'transparent' }} _active={{ bg: 'transparent' }} mt="30px" onClick={handleResendCode}>
            Kodu tekrar gönder
          </Button>
        </VStack>
      </StyledContainer>
    )
}

export default PhoneVerifyPin
