import { removeLocalStorage } from '@fuel/core/services/localStorage'
import * as React from 'react'
import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit'
import { getRainbowNonce, verifyRainbowSignature } from '../utils'
import { prepareMessage } from '../utils'
import { ethers } from 'ethers'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { useToast } from '@chakra-ui/react'
import { storage } from '@fuel/core'
import { CryptoNativeUserContext } from '../context/CryptoNativeUserProvider'

export function useAuthenticationAdapter() {
  const encodedJwtRef = React.useRef(null)
  const toast = useToast()

  const { setAuthStatus, setAddress } = React.useContext<any>(CryptoNativeUserContext)

  const authAdapter = createAuthenticationAdapter({
    getNonce: async () => {
      const { decodedJwt, encodedJwt } = (await getRainbowNonce()) as any
      encodedJwtRef.current = encodedJwt

      return decodedJwt
    },

    createMessage: ({ address, nonce }: { address: string; nonce: any }) => {
      const nonceObject = {
        address,
        issued_at: nonce.issued_at,
        domain: nonce.domain,
        nonce: nonce.nonce,
      }
      return prepareMessage(nonceObject)
    },

    getMessageBody: ({ message }: any) => {
      return message
    },

    verify: async ({ signature }) => {
      let public_address: any = null
      const walletConnectAddress = getLocalStorage('walletconnect') as any

      if (walletConnectAddress) {
        public_address = walletConnectAddress.accounts[0]
      } else {
        if (window.ethereum) {
          const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          })

          public_address = ethers.utils.getAddress(accounts[0]) || null
        }
      }

      const { authenticated, authTokens } = await verifyRainbowSignature(signature, encodedJwtRef)
      if (authenticated) {
        setAuthStatus('authenticated')

        setAddress(public_address)
      } else {
        setAuthStatus('unauthenticated')
      }

      return authenticated
    },

    signOut: async () => {
      setAuthStatus('unauthenticated')
      removeLocalStorage(storage.AUTH_TOKENS)
    },
  })

  return {
    authAdapter,
  }
}
