import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { Button } from './components/Button'
import { Heading } from './components/Heading'

import { Text } from './components/Text'
import { Badge } from './components/Badge'
import { colors } from './base/colors'
import { sizes } from './sizes'
import { breakpoints } from './base/breakpoints'
import { FormLabel } from './components/FormLabel'
import { Input } from './components/Input'
import { FormHelperText } from './components/FormHelperText'
import { Textarea } from './components/Textarea'
import { Select } from './components/Select'
import { Skeleton } from './components/Skeleton'
import Switch from './components/Switch'

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
}

export const theme = extendTheme({
  config,
  colors,
  breakpoints,
  sizes,
  shadows: {
    section: '0px 4px 30px rgba(219, 227, 236, 0.45)',
  },
  components: {
    Button,
    Heading,
    Text,
    Badge,
    FormLabel,
    Input,
    FormHelperText,
    Textarea,
    Select,
    Skeleton,
    Switch,
  },
})
