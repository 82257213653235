import { useContext, useState } from 'react'
import { storage } from '@fuel/core'
import { PurchaseStatus } from '@fuel/payment/constants/paymentMethods'
import { api } from '@fuel/core/constants/api'
import { useAxiosWithAuth } from '@fuel/api'
import { PaymentContext } from '@fuel/payment'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { DropPageDataContext } from '@fuel/auth/context/DropPageDataProvider'

interface UseEditionPurchaseStartArgs {
  setPurchaseStatus: (value: PurchaseStatus) => void
  purchaseStatus: PurchaseStatus
  purchaseQuantity?: number
  setMintPassGroupId?: (value: string) => void
  setRemainingCount?: (value: number) => void
}

export const useEditionPurchaseStart = ({
  setPurchaseStatus,
  purchaseStatus,
  purchaseQuantity,
  setMintPassGroupId,
  setRemainingCount,
}: UseEditionPurchaseStartArgs) => {
  const { setPurchaseLoading } = useContext<any>(PaymentContext)
  const [requestInProgress] = useState(false)
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
  const { dropPageData } = useContext<any>(DropPageDataContext)
  const axiosApi = useAxiosWithAuth()

  const selectPurchaseStartEndpoint = () => {
    switch (dropPageData?.payment_flow) {
      case 'limited_edition_purchase':
        return api.LIMITED_EDITION_PURCHASE_START

      case 'unlimited_edition_purchase':
        return api.LIMITED_EDITION_PURCHASE_START

      default:
        return api.LIMITED_EDITION_PURCHASE_START
    }
  }

  const fetchData = async () => {
    try {
      if (!selectedEdition) return
      if (requestInProgress) return

      axiosApi
        .get<{ status: PurchaseStatus; remaining_count: number }>(`${api.QUEUE_TICKET_STATUS}?edition_id=${selectedEdition?.id}`)
        .then(response => {
          if (response) {
            if (response.data.status === PurchaseStatus.NOT_ENOUGH_ITEMS) {
              setPurchaseLoading(false)
              setRemainingCount && setRemainingCount(response.data?.remaining_count || 0)
              setPurchaseStatus(PurchaseStatus.NOT_ENOUGH_ITEMS)
            }

            if (response.data.status === PurchaseStatus.ENQUEUED) {
              setTimeout(() => {
                fetchData()
              }, 2500)
            }
            if (response.data.status === PurchaseStatus.SOLD_OUT) {
              setPurchaseLoading(false)
              setPurchaseStatus(PurchaseStatus.SOLD_OUT)
            }
            if (response.data.status === PurchaseStatus.NFT_AVAILABLE) {
              setPurchaseLoading(false)
              setPurchaseStatus(PurchaseStatus.NFT_AVAILABLE)
            }
          }
        })
    } catch (e) {
      console.info('Something went wrong, please try again later!')
      setPurchaseLoading(false)
    }
  }

  const getEditionPurchaseStatus = async () => {
    setPurchaseStatus(PurchaseStatus.ENQUEUED)
    try {
      const response = await axiosApi.post(selectPurchaseStartEndpoint(), {
        edition_id: selectedEdition?.id,
        quantity: purchaseQuantity || 1,
      })
      if (response) {
        if (response.data.status === PurchaseStatus.ENQUEUED) {
          fetchData()
          setMintPassGroupId && setMintPassGroupId(response.data.mint_pass_group_id)
        }
        if (response.data.status === PurchaseStatus.SOLD_OUT) {
          setPurchaseLoading(false)
          setPurchaseStatus(PurchaseStatus.SOLD_OUT)
        }
      } else {
        setPurchaseStatus(PurchaseStatus.ENQUEUED)
      }
    } catch (e) {
      console.info('Something went wrong, please try again later!')
      setPurchaseLoading(false)
    }
  }

  return { getEditionPurchaseStatus }
}
