import { Button } from '@chakra-ui/react'
import styled from '@emotion/styled'

const red = '#990000'
const black = '#21232F'

/**
 * @deprecated
 * Please use PaymentButton from @fuel/payment
 */
export const StyledButton = styled(Button)`
  background: ${(props: any) => (props.status === 'sale' ? red : props.status === 'pre_sale' ? '#696969' : red)};
  border-radius: 50px;
  font-size: 18px;
  width: 200px;
  &:hover {
    background: ${(props: any) => (props.status === 'sale' ? red : props.status === 'pre_sale' ? black : red)};
    color: white;
  }

  &:hover[disabled] {
    background: #696969;
    color: white;
  }

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`
