import { useState, SetStateAction, Dispatch, FC } from 'react'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import RegisterTabPanel from '../RegisterTabPanel/RegisterTabPanel'
import LoginTabPanel from '../LoginTabPanel/LoginTabPanel'
import { useTranslation } from 'next-i18next'

interface AuthenticationTabsProps {
  onClose: () => void
  setAlwaysOpen: Dispatch<SetStateAction<boolean | undefined>>
}

const AuthenticationTabs: FC<AuthenticationTabsProps> = ({ onClose, setAlwaysOpen }) => {
  const { t } = useTranslation('auth')
  const [tabIndex, setTabIndex] = useState(1)

  const handleTabChange = (index: SetStateAction<number>) => {
    setTabIndex(index)
  }

  return (
    <Tabs defaultIndex={1} index={tabIndex} onChange={handleTabChange}>
      <TabList
        display={{ base: 'table', md: 'flex' }}
        w="full"
        margin="0 auto"
        bg="#FAFAFC"
        borderRadius="30px"
        mb="100px"
        borderBottom="none"
        mt="10px"
        p="5px"
      >
        <StyledTab label={t('create_wallet_tab')} />
        <StyledTab label={t('connect_wallet_tab')} />
      </TabList>
      <TabPanels maxW="400px" minH="300px" margin="0 auto" mb="56px">
        <TabPanel p="0px">
          <RegisterTabPanel onClose={onClose} setAlwaysOpen={setAlwaysOpen} />
        </TabPanel>
        <TabPanel p="0px">
          <LoginTabPanel onClose={onClose} setAlwaysOpen={setAlwaysOpen} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default AuthenticationTabs

const StyledTab = ({ label }: { label: string }) => (
  <Tab
    fontWeight={600}
    fontFamily="Inter"
    fontStyle="normal"
    fontSize="12px"
    lineHeight="10px"
    color="#B2B7CF"
    w="full"
    borderRadius="30px"
    margin="0px"
    _selected={{
      bg: '#ECE9FE',
      color: '#907DFE',
    }}
    _active={{
      bg: '#ECE9FE',
    }}
  >
    {label}
  </Tab>
)
