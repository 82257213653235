import { Button } from '@chakra-ui/react'
import styled from '@emotion/styled'

const default_color = 'rgb(255, 88, 124)'
const black = '#21232F'
const disabled_color = '#696969'

export const PaymentButton = styled(Button)<{ pre_sale_color?: string; sale_color?: string; status: 'sale' | 'pre_sale' }>`
  background: ${({ status, sale_color }) =>
    status === 'sale' ? sale_color ?? default_color : status === 'pre_sale' ? disabled_color : default_color};
  border-radius: 50px;
  font-size: 18px;
  width: 200px;
  &:hover {
    background: ${(props: any) => (props.status === 'sale' ? default_color : props.status === 'pre_sale' ? black : default_color)};
    color: white;
  }

  &:hover[disabled] {
    background: #696969;
    color: white;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`
