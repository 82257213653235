import React, { FC } from 'react'
import styled from 'styled-components'
import { StyledButton } from './Button.styles'
import { Metamask } from '../../icons/Metamask'

const StyledMetamaskButton = styled(StyledButton)`
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.text.disabled};
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(14)};
  line-height: 24px;

  svg {
    margin-left: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)};
  }
`

type MetamaskButtonProps = {
  onClick: () => void
  label?: any
}

export const MetamaskButton: FC<MetamaskButtonProps> = ({ onClick, label }) => {
  return (
    <StyledMetamaskButton onClick={onClick} type="button">
      {label ? (
        label
      ) : (
        <>
          <span>Sign in with</span> <Metamask />
        </>
      )}
    </StyledMetamaskButton>
  )
}
