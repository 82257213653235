import * as React from 'react'
import Image from 'next/image'
import { Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'

type LogoProps = {
  route: string
}

export const Logo: React.FC<LogoProps> = ({ route }) => {
  const router = useRouter()

  const handleNavigate = () => router.push(route)

  return (
    <Box position="relative" width="80px" height="80px" onClick={handleNavigate} cursor="pointer">
      <Image src="/images/logo.svg" alt="Fuel logo" layout="fill" />
    </Box>
  )
}
