import React, { FC } from 'react'
import { StyledMusicPlayerToggle } from './MusicPlayerToggle.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type MusicPlayerToggleProps = {
  setIsExpanded: (isExpanded: boolean) => void
  isExpanded: boolean
  name: string
}

export const MusicPlayerToggle: FC<MusicPlayerToggleProps> = ({ setIsExpanded, isExpanded, name }) => {
  return (
    <StyledMusicPlayerToggle onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded} aria-label={name}>
      <FontAwesomeIcon icon={['fas', 'plus']} size="lg" />
    </StyledMusicPlayerToggle>
  )
}
