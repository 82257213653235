import { defineStyleConfig } from '@chakra-ui/react'

const Switch = defineStyleConfig({
  baseStyle: {
    track: {
      bg: 'gray.100',
      _checked: {
        bg: 'purple.300',
      },
    },
    thumb: {
      bg: 'white',
      _checked: {
        bg: 'purple.500',
      },
    },
  },
})

export default Switch
