import React, { FC } from 'react'
import { StyledMusicPlayerControl } from './MusicPlayerControl.styles'

type MusicPlayerControlProps = {
  onClick: () => void
  name: string
  children: any
}

export const MusicPlayerControl: FC<MusicPlayerControlProps> = ({ onClick, children, name }) => {
  return (
    <StyledMusicPlayerControl onClick={onClick} aria-label={name}>
      {children}
    </StyledMusicPlayerControl>
  )
}
