import styled from 'styled-components'

export const StyledIcon = styled.div`
  width: 36px;
  height: 36px;
  background: ${({ theme }) => theme.palette.common.success}40;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    color: ${({ theme }) => theme.palette.text.success};
  }
`
