import { useEffect, useState } from 'react'
import { useNetworkStatusV2, NETWORK_STATUS } from './useNetworkStatusV2'

interface UseMintSoldOutV2Args {
  uniqueCollectionContractV2: any
}

/**
 * Determines if a collection is sold out based on max supply and total supply
 */
export const useMintSoldOutV2 = ({ uniqueCollectionContractV2 }: UseMintSoldOutV2Args) => {
  const { networkStatus } = useNetworkStatusV2()
  const [isSoldOut, setIsSoldOut] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (uniqueCollectionContractV2 && networkStatus === NETWORK_STATUS.CORRECT) {
        try {
          const maxSupply = +(await uniqueCollectionContractV2.maxSupply())
          const totalSupply = +(await uniqueCollectionContractV2.totalSupply()).toString()
          if (totalSupply >= maxSupply) {
            setIsSoldOut(true)
          }
        } catch (e) {
          console.info('Called too early maxSupply/totalSupply!')
        }
      }
    })()
  }, [uniqueCollectionContractV2, networkStatus])

  return { isSoldOut }
}
