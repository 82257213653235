import { useContext, useEffect, useState } from 'react'
import { CollectionContext, NETWORK_STATUS, useNetworkStatus, useUniqueCollectionContract } from '@fuel/nft'
import * as Sentry from '@sentry/browser'
import { ethers } from 'ethers'
import { abi } from '../constants/abi'

export enum MINT_STATUS {
  DISABLED = 'disabled',
  ALLOW_LIST = 'allow_list',
  PUBLIC = 'public',
}

export const useCurrentBlockHeight = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum as any)
  const { collection }: any = useContext(CollectionContext)
  const { uniqueCollectionContract } = useUniqueCollectionContract({ contractAddress: collection?.contract_address, abi: abi.COLLECTION_ABI })
  const [mintStatus, setMintStatus] = useState(MINT_STATUS.DISABLED)
  const { networkStatus } = useNetworkStatus()
  const [currentBlockHeight, setCurrentBlockHeight] = useState(0)

  const getBlockNumber = async () => {
    try {
      if (provider && networkStatus === NETWORK_STATUS.CORRECT) {
        const data = await provider.getBlockNumber()
        return data
      }
    } catch (e) {
      console.error('Something went wrong in useCurrentBlockHeight')
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (uniqueCollectionContract) {
          const publicMintStartBlockHeight =
            provider && networkStatus === NETWORK_STATUS.CORRECT && (await uniqueCollectionContract.publicMintStartBlockHeight()).toString()

          const blockHeight: any = await getBlockNumber()

          Sentry.addBreadcrumb({
            category: 'mint',
            message: 'Current blockheight ' + blockHeight,
            level: 'info',
          })
          setCurrentBlockHeight(blockHeight)
          const blockNumber: any = await getBlockNumber()
          const blocksUntilPublicMintStart = publicMintStartBlockHeight - blockNumber

          // The close we are getting to the publicMintStartBlockHeight,
          // the more  often we are polling - using quadratic curve
          if (publicMintStartBlockHeight > 0) {
            let interval = ((1 / 2000) * (publicMintStartBlockHeight - blockNumber) ** 2 + 1) * 1000

            if (interval > 300000) {
              interval = 300000
            }
            if (provider && networkStatus === NETWORK_STATUS.CORRECT && blocksUntilPublicMintStart > 0) {
              const blockNumber = setInterval(async () => {
                const blockHeight: any = await getBlockNumber()
                setCurrentBlockHeight(blockHeight)
              }, interval)

              return () => {
                clearInterval(blockNumber)
              }
            }
          }
        }
      } catch (e) {
        console.error('Something went wrong in useCurrentBlockHeight')
      }
    })()
  }, [provider, networkStatus, uniqueCollectionContract, getBlockNumber])

  useEffect(() => {
    ;(async () => {
      if (provider && uniqueCollectionContract && networkStatus === NETWORK_STATUS.CORRECT && currentBlockHeight !== 0) {
        const publicMintStartBlockHeight = (await uniqueCollectionContract.publicMintStartBlockHeight()).toString()

        const allowListMintEndBlockHeight = +(await uniqueCollectionContract.allowListMintEndBlockHeight()).toString()

        const isAllowListMint = currentBlockHeight <= +allowListMintEndBlockHeight && allowListMintEndBlockHeight > 0

        const isPublicListMint = publicMintStartBlockHeight > 0 && publicMintStartBlockHeight <= currentBlockHeight

        if (isAllowListMint) {
          setMintStatus(MINT_STATUS.ALLOW_LIST)
        } else if (isPublicListMint) {
          setMintStatus(MINT_STATUS.PUBLIC)
        } else {
          setMintStatus(MINT_STATUS.DISABLED)
        }
      }
    })()
  }, [provider, uniqueCollectionContract, networkStatus, currentBlockHeight])

  return { mintStatus }
}
