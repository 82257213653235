import { Text, Box, VStack } from '@chakra-ui/react'
import { AuthMagicConnectButton } from '../AuthMagicConnectButton/AuthMagicConnectButton'
import { useTranslation } from 'next-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

const buttonStyles = {
  background: '#ADFFB5',
  color: '#4F5079',
  letterSpacing: '-0.5px',
  borderRadius: '12px',
}

type Props = {
  onClose: () => void
  setAlwaysOpen: Dispatch<SetStateAction<boolean | undefined>>
}

const RegisterTabPanel: FC<Props> = ({ onClose, setAlwaysOpen }) => {
  const { t } = useTranslation('auth')

  return (
    <VStack textAlign="center" gap="20px">
      <Text fontFamily="Inter" fontWeight={800} fontSize="40px" lineHeight="44px" color="#1A1347" mt="40px">
        {t('create_wallet')}
      </Text>

      <Text fontSize="16px" fontStyle="normal" fontFamily="Inter" color="#4F5079" textAlign="center" lineHeight="24px" fontWeight={500} maxW="350px">
        {t('sign_up_automatic_wallet')}
      </Text>
      <Box
        textAlign="center"
        onClick={() => {
          setAlwaysOpen(false)
          onClose()
        }}
      >
        <AuthMagicConnectButton connectText={t('create_email_metamask')} buttonStyles={buttonStyles} />
      </Box>
    </VStack>
  )
}

export default RegisterTabPanel
