import { useEffect } from 'react'
import { useRouter } from 'next/router'

interface LanguageDetector {
  cache?(lng: string, caches?: string[]): void
  detect(detectionOrders?: string | string[]): string | undefined
}

export const useLanguageDetector = (languageDetector: LanguageDetector) => {
  const router = useRouter()
  const detectedLanguage = languageDetector.detect()

  useEffect(() => {
    const preferredLanguage = typeof window !== 'undefined' && localStorage.getItem('preferredLanguage')

    if (detectedLanguage && !preferredLanguage) {
      if (detectedLanguage !== router.locale && router.locales?.includes(detectedLanguage)) {
        const { pathname, asPath, query } = router
        router.push({ pathname, query }, asPath, { locale: detectedLanguage })
      }
    }
  }, [detectedLanguage])

  return null
}
