import { createContext, Dispatch, SetStateAction, useState } from 'react'

export interface PhoneVerificationContextProviderProps {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  phoneNumber: string
  setPhoneNumber: Dispatch<SetStateAction<string>>
  verificationCode: string
  setVerificationCode: Dispatch<SetStateAction<string>>
}

export const PhoneVerificationContext = createContext<PhoneVerificationContextProviderProps>({} as PhoneVerificationContextProviderProps)

export const PhoneVerificationProvider = ({ children }: any) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationCode, setVerificationCode] = useState('')

  return (
    <PhoneVerificationContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        phoneNumber,
        setPhoneNumber,
        verificationCode,
        setVerificationCode,
      }}
    >
      {children}
    </PhoneVerificationContext.Provider>
  ) as any
}
