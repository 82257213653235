import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select from 'react-select'
import styled from 'styled-components'

const StyledSelect = styled(Select)`
  & > div {
    background: ${({ theme }) => theme.input.background};
    font-size: ${({ theme }) => theme.input.fontSize.base};
  }
`

type SelectInputProps = {
  name: string
  options: Array<{ value: string; label: string }>
}

export const SelectInput: FC<SelectInputProps> = ({ name, options }) => {
  const { control } = useFormContext()

  return <Controller name={name} control={control} rules={{ required: true }} render={({ field }) => <StyledSelect {...field} options={options} />} />
}
