import styled from 'styled-components'

export const StyledDropdownButton = styled.div`
  position: relative;
  margin-left: 10px;
  &:hover {
    div[data-name='menu'] {
      transform: scale(1);
      transition: all 0.3s ease-in-out;
    }
  }

  div[data-name='menu'] {
    transition: transform 0.2s ease-in-out;
    top: -20px;
    left: -20px;
    z-index: 9;
    background: ${({ theme }) => theme.palette.common.white};
    width: calc(100% + 40px);
    border-radius: 20px;
    position: absolute;
    transform: scale(0);
    transform-origin: 50% 25%;
    height: 120px;
    padding: 70px 20px 20px 20px;

    & > button {
      width: 100%;
      cursor: pointer;
    }
  }

  & > button {
    position: relative;
    z-index: 10;
    cursor: pointer;
  }
`
