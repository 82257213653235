import { ethers } from 'ethers'

export const encodeTokenId = (tokenId: number) => {
  return ethers.utils.hexZeroPad(ethers.utils.hexlify(tokenId), 32)
}

export type ConstraintPayload = { tokenId: number; amountToMint: number }[]

export const encodeTokenIds = (payload: ConstraintPayload) => {
  return ethers.utils.defaultAbiCoder.encode(['tuple(uint256 tokenId, uint256 amountToMint)[] ConstraintPayload'], [payload])
}
