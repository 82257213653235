import React, { FC } from 'react'
import styled from 'styled-components'

const StyledTextInputWrapper = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const StyledTextInputLabel = styled.span`
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xxs};

  & > span {
    color: ${({ theme }) => theme.palette.text.disabled}60;
  }
`

type TextInputWithLabelProps = {
  label: string
  additionalLabel?: string
  as?: 'label' | 'div'
  children: any
}

export const InputWithLabel: FC<TextInputWithLabelProps> = ({ label, additionalLabel, children, as }) => {
  return (
    <StyledTextInputWrapper as={as ?? 'label'}>
      <>
        <StyledTextInputLabel>
          {label} <span>{additionalLabel}</span>
        </StyledTextInputLabel>
        {children}
      </>
    </StyledTextInputWrapper>
  )
}
