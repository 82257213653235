import React, { FC } from 'react'
import styled from 'styled-components'

export const StyledBadge = styled.span<BadgeProps>`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  padding: ${({ theme }) => theme.spacing(2.5)} ${({ theme }) => theme.spacing(4)};

  ${({ type, theme }) => {
    switch (type) {
      case 'success':
        return `
          background-color: ${theme.palette.common.success};
          color: ${theme.palette.text.success};
        `
      case 'warning':
        return `
          background-color: ${theme.palette.common.warning};
          color: ${theme.palette.text.warning};
          font-weight: ${theme.fontWeight.bold};
        `
      case 'info':
        return `
          background-color: ${theme.palette.common.info};
          color: ${theme.palette.common.white};
        `
      default:
        return `
          background-color: transparent;
          color: ${theme.palette.common.white}
        `
    }
  }}
`

type BadgeProps = {
  type?: 'success' | 'warning' | 'info'
  children: any
}

export const Badge: FC<BadgeProps> = ({ type, children }) => {
  return <StyledBadge type={type}>{children}</StyledBadge>
}
