import styled from 'styled-components'
import { StyledText } from '../text/Text.styles'
import { motion } from 'framer-motion'

export const StyledErrorMessage = styled(motion(StyledText))`
  position: absolute;
  top: -22px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.common.error};
`
