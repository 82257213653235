import jwt from 'jwt-decode'
import { getLocalStorage, setLocalStorage } from '@fuel/core/services/localStorage'
import { ethers } from 'ethers'
import { api, storage } from '@fuel/core'
import { useAxios } from '@fuel/api'

export const getRainbowNonce = async (address?: string) => {
  const axiosApi = useAxios()

  let public_address = address
  const walletConnectAddress = getLocalStorage('walletconnect') as any

  if (!address) {
    if (walletConnectAddress) {
      public_address = walletConnectAddress.accounts[0]
    } else {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        public_address = ethers.utils.getAddress(accounts[0]) || undefined
      }
    }
  }

  const domain = window.location.host

  try {
    if (domain && public_address) {
      const { data } = await axiosApi.post(api.CONNECT_WALLET, {
        domain,
        public_address,
      })
      const decoded = jwt(data.jwt) as any

      return {
        decodedJwt: decoded,
        encodedJwt: data.jwt,
      }
    }
  } catch (e) {
    console.log('Error trying to connect to get nonce')
  }
}

export const verifyRainbowSignature = async (signature: string, encodedJwt?: any, email?: string) => {
  try {
    const axiosApi = useAxios()
    const token = encodedJwt.current ?? encodedJwt

    const options = {
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    const url = process.env.NEXT_PUBLIC_BACKEND_URL + api.VERIFY_SIGNATURE

    const response = await axiosApi.post(
      url,
      {
        signature,
        email,
      },
      options
    )

    const authenticated = response
    let authTokens = null

    if (authenticated) {
      authTokens = {
        accessToken: response.data.authentication_token,
        refreshToken: response.data.refresh_token,
      }
      setLocalStorage(storage.AUTH_TOKENS, authTokens)
    }

    const res: any = {
      authenticated,
      authTokens,
    }

    return res
  } catch (error) {
    console.log(error)
    return false
  }
}
