import React, { FC } from 'react'
import styled from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faCheck)

export const StyledRadio = styled.span<{ checked: boolean }>`
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.palette.common.black};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ checked, theme }) => `
    background-color: ${checked ? theme.palette.common.success : theme.palette.common.white};
  `}
`

type RadioProps = {
  checked: boolean
  onClick?: () => void
}

export const Radio: FC<RadioProps> = ({ checked, onClick }) => {
  return (
    <StyledRadio onClick={onClick} checked={checked}>
      {checked && <FontAwesomeIcon icon={faCheck} size="sm" />}
    </StyledRadio>
  )
}
