import React from 'react'
import { Select, OptionBase, GroupBase, ChakraStylesConfig } from 'chakra-react-select'

interface SelectOptions extends OptionBase {
  label: string
  value: string
}

export default function CountryCustomSelect({ inputRef, value, name, options, onChange, defaultValue }: any) {
  const chakraStyles: ChakraStylesConfig = {
    control: provided => ({
      ...provided,

      borderRadius: '0px 12px 12px 0px',
      height: '50px',
      display: 'flex',
      border: 'none',
      background: '#21232F',
      fontFamily: 'Inter,sans-serif;',
      fontSize: '16px',

      _focus: {
        border: '1px solid',
        boxShadow: 'none',
      },

      '@media (min-width: 1324px)': {
        height: '60px',
      },

      //tablet
    }),
    container: provided => ({
      ...provided,
      cursor: 'pointer',
      width: '100%',
    }),
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: '12px',
      padding: '6px',
      background: '#21232F',
      borderColor: '#2c2e3e',
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#2c2e3e' : '#21232F',
      borderRadius: '12px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: state.isSelected ? 600 : 500,
      marginTop: '4px',

      _active: {
        background: '#2c2e3e',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,

      color: '#E8ECFB',
      cursor: 'pointer',
      transition: 'all 0.2s',
      _active: {
        transform: 'scale(0.9)',
      },
      transform: () => {
        if (state.selectProps.menuIsOpen) {
          return 'rotate(180deg)'
        }
      },
    }),
  }

  return (
    <Select<SelectOptions, true, GroupBase<SelectOptions>>
      useBasicStyles={true}
      placeholder="Select option"
      size="lg"
      defaultValue={defaultValue}
      chakraStyles={chakraStyles as any}
      ref={inputRef}
      value={value}
      name={name}
      options={options}
      onChange={onChange}
    />
  )
}
