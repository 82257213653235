import { ethers } from 'ethers'
import { NETWORK_STATUS } from '@fuel/nft'

interface GetBlockNumberArgs {
  provider?: ethers.providers.Web3Provider
  networkStatus?: NETWORK_STATUS
}

export const getBlockNumber = async ({ provider, networkStatus }: GetBlockNumberArgs) => {
  try {
    if (provider && networkStatus === NETWORK_STATUS.CORRECT) {
      const data = await provider.getBlockNumber()
      return data
    }
  } catch (e) {
    console.error(`Something went wrong in getBlockNumber`)
  }
}
