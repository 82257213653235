import { api } from '@fuel/core'
import axios from 'axios'

interface GetMerkleProofArgs {
  collectionId: string
  address: string | undefined
  index: number
}

export const getMerkleProof = async ({ collectionId, address, index }: GetMerkleProofArgs) => {
  const { data } = await axios.get(process.env.NEXT_PUBLIC_BACKEND_URL + api.ALLOW_LIST_PROOF, {
    params: {
      collection_id: collectionId,
      wallet_address: address,
      index,
    },
  })

  return data.proof
}

interface CheckMerkleProofValidityArgs {
  proof: string[]
  address: string | undefined
  index: number
  uniqueCollectionContractV2: any
}

export const checkMerkleProofValidity = async ({
  proof,
  address,
  index,
  uniqueCollectionContractV2,
}: CheckMerkleProofValidityArgs): Promise<boolean> => {
  let proofValid = false

  if (uniqueCollectionContractV2 && proof.length && address) {
    proofValid = await uniqueCollectionContractV2.isProofValid(index, proof, address)
  }

  return proofValid
}
