import { useDisclosure } from '@chakra-ui/react'
import React, { createContext, useState } from 'react'
import { PurchaseStatus } from '../constants/paymentMethods'

export const PaymentContext: any = createContext<any>({
  purchaseStatus: null,
  purchaseLoading: false,
  setPurchaseLoading: () => {},
  isAvailableToPurchase: false,
  setIsAvailableToPurchase: () => {},
  setPurchaseStatus: (purchaseStatus: PurchaseStatus) => {},
  openSucceedModal: () => {},
  isOpenSucceedModal: false,
  onCloseSucceedModal: () => {},
})

export const PaymentProvider = ({ children }: any) => {
  const { isOpen: isOpenSucceedModal, onOpen: openSucceedModal, onClose: onCloseSucceedModal } = useDisclosure()

  const [purchaseLoading, setPurchaseLoading] = useState(false)

  const [isAvailableToPurchase, setIsAvailableToPurchase] = useState(false)

  return (
    <PaymentContext.Provider
      value={{
        purchaseLoading,
        setPurchaseLoading,
        isAvailableToPurchase,
        setIsAvailableToPurchase,
        openSucceedModal,
        isOpenSucceedModal,
        onCloseSucceedModal,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}
