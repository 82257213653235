import '@rainbow-me/rainbowkit/styles.css'
import { Button } from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import React from 'react'
import { RainbowCustomButtonMenu } from './RainbowCustomButtonMenu'

export const AuthRainbowKitButton = ({
  connectText = 'Connect',
  connectedText = 'Connected',
  menuItemStyles = {
    color: '#21232F',
    fontWeight: '400',
  },
  menuListStyles = {
    color: '#21232F',
  },
  buttonStyles = {
    background: '#E8ECFB',
    borderRadius: '50px',
    color: '#21232F',
    letterSpacing: '.5px',
    padding: '0 20px',
    fontWeight: '400',
  },
  profileText = 'Profile',
  logoutText = 'Logout',
  redirectPathOnLogout = '/',
  avatarButton = false,
  showProfile = true,
  logoutItemStyles,
}: {
  buttonStyles?: any
  menuListStyles?: any
  menuItemStyles?: any
  connectText?: string
  connectedText?: string
  profileText?: string
  logoutText?: string
  redirectPathOnLogout?: string
  avatarButton?: boolean
  showProfile?: boolean
  logoutItemStyles?: any
}) => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated')

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button onClick={openConnectModal} type="button" style={buttonStyles}>
                    {connectText}
                  </Button>
                )
              }

              if (chain.unsupported) {
                return (
                  <Button onClick={openChainModal} type="button" style={buttonStyles}>
                    Wrong network
                  </Button>
                )
              }

              return (
                <RainbowCustomButtonMenu
                  openAccountModal={openAccountModal}
                  avatarButton={avatarButton}
                  connectedText={connectedText}
                  profileText={profileText}
                  logoutText={logoutText}
                  buttonStyles={buttonStyles}
                  menuListStyles={menuListStyles}
                  menuItemStyles={menuItemStyles}
                  logoutItemStyles={logoutItemStyles}
                />
              )
            })()}
          </div>
        )
      }}
    </ConnectButton.Custom>
  )
}
