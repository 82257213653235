import { useContext, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useSigner } from 'wagmi'
import { MINT_TRACK_STATUS, useTrackMint } from './useTrackMint'
import { useToast } from '@chakra-ui/react'
import { metamaskStatus } from '../../constants/metamaskStatus'
import { useUniqueCollection } from './useUniqueCollection'
import { MINT_STATUS } from './useCurrentBlockHeight'
import { CollectionContext } from '@fuel/nft/contexts/CollectionContext'
import { PaymentContext } from '../../context/PaymentProvider'
import { storage } from '@fuel/core'
import { setLocalStorage } from '@fuel/core/services/localStorage'

interface UseMintArgs {
  proof: string[] | null
  mintStatus: MINT_STATUS
}

export const useMint = ({ proof, mintStatus }: UseMintArgs) => {
  const [processing, setProcessing] = useState(false)
  const { data: signer } = useSigner()
  const { uniqueCollection } = useUniqueCollection()
  const { trackMint } = useTrackMint()
  const { collection }: any = useContext(CollectionContext)
  const toast = useToast()
  const { openSucceedModal } = useContext<any>(PaymentContext)

  const mintToken = async () => {
    Sentry.configureScope(scope => scope.setTransactionName('MintToken from useMint'))
    trackMint(MINT_TRACK_STATUS.MINT_PRESSED)
    try {
      console.log('Start minting...', collection)

      trackMint(MINT_TRACK_STATUS.MINT_STARTED)
      const price = await uniqueCollection?.mintPrice()

      let call = null
      if (mintStatus === MINT_STATUS.PUBLIC) {
        console.log('is public minting')
        call = await uniqueCollection?.publicMint({ value: price })
      }

      if (mintStatus === MINT_STATUS.ALLOW_LIST) {
        console.log('is public minting')
        call = await uniqueCollection?.allowListMint(proof, {
          value: price,
        })
      }

      //     const MintPhase =  {
      //       startBlockHeight, // the block height the mintphase becomes active (inclusive)
      //       endBlockHeight, // the block height the mintphase becomes inactive (inclusive
      // maxMintPerAddress, // how many tokens a user can mint in this phase
      //       mintPrice, // how much a token costs in this phase
      //       allowListMerkleRoot, // if the root != 0x00, you need to use allowListMint(), otherwise publicMint()
      //        mintConstraint // not yet relevant
      // }

      const mintPhasesCount = await uniqueCollection?.mintPhasesLenght()
      for (let i = 0; i < mintPhasesCount; i++) {
        const mintPhase = await uniqueCollection?.mintPhases(i)
        console.log('MintPhase', mintPhase)
      }

      setProcessing(true)
      if (call) {
        const receipt = await signer?.provider?.waitForTransaction(call.hash)
        if (receipt) {
          setLocalStorage(storage.TX_HASH, receipt.transactionHash)
          trackMint(MINT_TRACK_STATUS.MINT_COMPLETED)
          openSucceedModal()
          toast({
            title: 'Success',
            description: 'Transaction completed',
            status: 'success',
          })
        }
      }
    } catch (error: any) {
      trackMint(MINT_TRACK_STATUS.MINT_FAILED)
      if (error?.data?.code === metamaskStatus.SOLD_OUT) {
        toast({
          title: 'Sold out!',
          description: 'This collection is sold out.',
          status: 'error',
        })
        return
      }
      if (error?.code === metamaskStatus.INSUFFICIENT_FUNDS || (typeof error === 'string' ?? error?.includes('insufficient funds'))) {
        toast({
          title: 'Insufficient funds!',
          description: 'You do not have enough funds to mint this token.',
          status: 'error',
        })
        return
      }
      if (error?.code === metamaskStatus.DENIED_TRANSACTION || error === 'User rejected the transaction') {
        toast({
          title: 'Transaction denied!',
          description: 'You have denied the transaction.',
          status: 'error',
        })
        return
      }

      console.log('Error minting', error)

      toast({
        title: 'Something went wrong!',
        description: 'Please try again.',
        status: 'error',
      })
    } finally {
      setProcessing(false)
    }
  }

  return {
    mintToken,
    mintStatus,
    processing,
  }
}
