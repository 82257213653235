import { useContext, useState } from 'react'
import { getRainbowNonce, prepareMessage, verifyRainbowSignature } from '../utils'
import { PaymentContext } from '@fuel/payment'
import { MagicConnectUserContext } from '@fuel/auth/context/MagicConnectUserProvider'
import { api } from '@fuel/core'
import { useAxiosWithAuth } from '@fuel/api'
import { useUserDetails } from '@fuel/core/hooks/useUserDetails'
import { InstanceWithExtensions } from '@magic-sdk/provider/dist/types/modules/base-extension'
import { MagicSDKExtensionsOption, SDKBase } from '@magic-sdk/provider/dist/types/core/sdk'

interface UseMagicConnectArgs {
  setDisabled?: any
  callback?: any
}

export const useMagicConnect = ({ setDisabled, callback }: UseMagicConnectArgs) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string>()

  const { updateUserDetails } = useUserDetails()
  const { provider, signer, setUserDetails } = useContext<any>(MagicConnectUserContext)
  const magic = useContext<any>(MagicConnectUserContext).magic as InstanceWithExtensions<SDKBase, MagicSDKExtensionsOption<string>>
  const { setPurchaseLoading } = useContext<any>(PaymentContext)
  const axiosApi = useAxiosWithAuth()

  const getUserInfo = async () => {
    try {
      const { data } = await axiosApi.get(api.GET_ACCOUNT_DETAILS)
      if (data) {
        setUserDetails(data)
        callback && callback()
      }
    } catch (e) {
      console.error('Get User Info ERROR:', e)
    }
  }

  const signMessage = async (address: string, email?: string) => {
    const { decodedJwt, encodedJwt }: any = await getRainbowNonce(address)
    const nonceObject = {
      address,
      issued_at: decodedJwt.issued_at,
      domain: decodedJwt.domain,
      nonce: decodedJwt.nonce,
    }

    const signature = await signer.signMessage(prepareMessage(nonceObject))
    const { authenticated, authTokens } = await verifyRainbowSignature(signature, encodedJwt, email)
    if (authenticated) {
      setDisabled ? setDisabled(false) : null
    }
  }

  const getEmail = async () => {
    try {
      const { email } = await magic.wallet.requestUserInfoWithUI({ scope: { email: 'required' } })

      return email
    } catch (e) {
      window.location.reload()
    }
  }

  const connectMagic = async () => {
    try {
      setPurchaseLoading(true)
      setLoading(true)
      const accounts = await provider?.listAccounts()

      const walletInfo = await magic.wallet.connectWithUI()

      // Has address from Magic connect = is magic
      if (walletInfo.length) {
        const email = await getEmail()

        if (accounts?.length && email && email?.length > 3) {
          await signMessage(accounts[0], email)
        } else {
          await signMessage(accounts[0])
        }

        getUserInfo()
      } else {
        console.log('in metamask')
        await signMessage(accounts[0])
        getUserInfo()

        if (!email?.length) {
          const email = await getEmail()
          setEmail(email)

          const newData = {
            email,
          }
          updateUserDetails.mutate(newData)
        }
      }
    } catch (e) {
      window.location.reload()
      console.log('Error magic connect', e)
    } finally {
      setLoading(false)
      setPurchaseLoading(false)
      setDisabled && setDisabled(false)
    }
  }

  return { connectMagic, loading }
}
