import { toast } from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { api, SaleStatus } from '@fuel/core'
import { CollectionDetailModel } from '@fuel/nft'
import { useAxios } from '@fuel/api'

export const sale = {
  isWhitelistSale: process.env.NEXT_PUBLIC_SALE_STATUS === 'whitelist',
}

export const useFanCollectionDetailQuery = () => {
  const { t } = useTranslation()
  const axiosApi = useAxios()

  const getFanCollectionDetail = async () => {
    try {
      if (sale.isWhitelistSale) {
        return {
          contract_address: '',
          description: '',
          id: '',
          image_url: '',
          name: '',
          royalty_fee: 0,
          symbol: '',
          total: '0.06',
          sale_status: SaleStatus.WHITELIST,
        } as CollectionDetailModel
      }

      return (
        await axiosApi.get(`${api.COLLECTION(process.env.NEXT_PUBLIC_CREATOR_ID as string, process.env.NEXT_PUBLIC_COLLECTION_ID as string)}`, {
          withCredentials: false,
        })
      ).data as CollectionDetailModel
    } catch (error) {
      toast.error(t('something_went_wrong'), {
        position: 'bottom-right',
        duration: 4000,
      })
    }
  }

  return { getFanCollectionDetail }
}
