import React, { FC } from 'react'
import { StyledErrorMessage } from './ErrorMessage.styles'

type ErrorMessageProps = {
  text: string
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ text }) => {
  return (
    <StyledErrorMessage initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} fontFamily="secondary">
      {text}
    </StyledErrorMessage>
  )
}
