import { useContext, useEffect } from 'react'
import * as Sentry from '@sentry/browser'

import { MagicConnectUserContext } from '@fuel/auth'

export const useSentryUser = () => {
  const { userDetails } = useContext<any>(MagicConnectUserContext)
  const address = userDetails?.wallet_public_address
  useEffect(() => {
    if (address) {
      console.info('User identified with address ', address)
      Sentry.setUser({ id: address })
    } else {
      Sentry.setUser(null)
    }
  }, [address])
}
