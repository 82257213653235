import React, { FunctionComponent, ReactNode, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { StyledCheckbox } from './Checkbox.styles'

type CheckboxProps = {
  className?: string
  registerReturn: UseFormRegisterReturn
  label: string | ReactNode
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ registerReturn, label }) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <StyledCheckbox isChecked={isChecked}>
      <input
        type="checkbox"
        {...registerReturn}
        onChange={() => {
          setIsChecked(!isChecked)
        }}
      />
      <svg className={`${isChecked ? 'checkbox--active' : ''}`} aria-hidden="true" viewBox="0 0 15 11" fill="none">
        <path d="M1 4.5L5 9L14 1" strokeWidth="2" />
      </svg>
      {/*// @ts-ignore*/}
      <p dangerouslySetInnerHTML={{ __html: label }} />
    </StyledCheckbox>
  )
}
