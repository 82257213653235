import React, { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import { Box, Flex, IconButton, Skeleton, Text, Image } from '@chakra-ui/react'
import { BiDownload } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'

const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result?.toString() || '')
    reader.onerror = error => reject(error)
  })
}

const StyledDropzoneZone = styled.div`
  border: 1px dashed #e2e8f0;
  border-radius: 0.375rem;
  min-height: 256px;
  display: flex;
  justify-content: center;
  width: 100%;
`

type FileUploadInputProps = {
  name: string
  loading?: boolean
  initialArtwork?: boolean | number
  defaultPreviewImage?: string | null
  hasMultiple?: boolean
  convertToBase64?: boolean
  placerholderHeight?: string
}

export const FileUploadInput: FC<FileUploadInputProps> = ({
  defaultPreviewImage,
  name,
  loading,
  initialArtwork,
  hasMultiple,
  convertToBase64,
  placerholderHeight,
}) => {
  const { register, unregister, setValue, watch } = useFormContext()
  const [previewImage, setPreviewImage] = useState(defaultPreviewImage ? defaultPreviewImage : null)

  useEffect(() => {
    if (defaultPreviewImage && defaultPreviewImage?.length > 0) {
      setPreviewImage(defaultPreviewImage)
    }
  }, [defaultPreviewImage])

  // @ts-ignore
  const onDrop = useCallback<DropzoneOptions['onDrop']>(
    async droppedFiles => {
      if (convertToBase64) {
        const image = await file2Base64(droppedFiles[0] as any)
        setValue(name, image, { shouldValidate: true })
      } else {
        setValue(name, droppedFiles, { shouldValidate: true })
      }
    },
    [setValue, name]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/*'],
  })
  const files: File[] = watch(name)
  useEffect(() => {
    if (hasMultiple) {
      if (!watch(name)) {
        register(name, {
          required: { value: !!initialArtwork, message: 'Image is required' },
        })
      }
    } else {
      register(name, {
        required: { value: !!initialArtwork, message: 'Image is required' },
      })
    }
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  useEffect(() => {
    if (!!files?.length) {
      if (typeof files === 'string') {
        setPreviewImage(files)
      } else {
        files.map(file => {
          setPreviewImage(URL.createObjectURL(file))
        })
      }
    }
  }, [files])

  const deleteFile = () => {
    setValue(name, [], { shouldValidate: true, shouldDirty: true })
    setPreviewImage(null)
  }

  return (
    <Box position="relative">
      {previewImage && (
        <IconButton
          onClick={deleteFile}
          position="absolute"
          zIndex="9999"
          right="16px"
          top="16px"
          width="auto"
          bg="secondary.light"
          color="secondary.accent"
          fontSize="18px"
          size="sm"
          aria-label="Remove image"
          icon={<RiDeleteBin6Line />}
        />
      )}

      <Skeleton isLoaded={!loading} display="flex" justifyContent="center">
        {previewImage ? (
          <Image src={previewImage} alt="preview-image" width="auto" maxH="320px" objectFit="contain" style={{ borderRadius: '20px' }} />
        ) : (
          <StyledDropzoneZone {...getRootProps()}>
            <input {...getInputProps()} />
            {!files?.length &&
              (isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                !previewImage && (
                  <Flex direction="column" justifyContent="center" alignItems="center" w="100%" height={placerholderHeight}>
                    <IconButton
                      width="auto"
                      bg="secondary.light"
                      color="secondary.accent"
                      aria-label="Call Segun"
                      fontSize="30px"
                      borderRadius="15px"
                      size="lg"
                      mb="22px"
                      icon={<BiDownload />}
                    />
                    <Text variant="secondary" align="center" px="20px">
                      Click here to upload or drag and drop a file from you computer.
                    </Text>
                    <Text variant="secondary">Max size: 100 MB</Text>
                  </Flex>
                )
              ))}
          </StyledDropzoneZone>
        )}
      </Skeleton>
    </Box>
  )
}
