import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const StyledEditButton = styled.button`
  width: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.spacing(8)};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
`

export const EditButton = () => {
  return (
    <StyledEditButton>
      <FontAwesomeIcon icon="edit" size="sm" />
    </StyledEditButton>
  )
}
