import React, { FC, forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'
import { StyledButton } from './Button.styles'

export const StyledIconButton = styled(StyledButton)`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`

type IconButtonProps = {
  icon: IconName
  color?: 'primary' | 'secondary' | 'alt' | 'error'
  variant?: 'contained' | 'outlined' | 'text'
  onClick?: () => void
  href?: string
  children: any
}

export const IconButton: FC<IconButtonProps> = forwardRef(({ children, icon, color, onClick, href, variant }, ref) => (
  <StyledIconButton color={color} ref={ref as any} variant={variant} onClick={onClick} type="button">
    <FontAwesomeIcon icon={icon} size="lg" />
    {children}
  </StyledIconButton>
))
