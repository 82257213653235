import styled from 'styled-components'

export const StyledTextInput = styled.input`
  padding: ${({ theme }) => theme.spacing(2.5)} ${({ theme }) => theme.spacing(2.5)};
  border-radius: 5px;
  background: ${({ theme }) => theme.input.background};
  font-size: ${({ theme }) => theme.input.fontSize.base};
  font-family: ${({ theme }) => theme.input.fontFamily.primary};
  color: ${({ theme }) => theme.input.color};
  outline: none;
  border: 2px solid transparent;

  &:focus {
    border-color: ${({ theme }) => theme.palette.text.disabled};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`
