import { useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { DropPageDataContext } from '@fuel/auth/context/DropPageDataProvider'
import { api } from '@fuel/core'

export function useDropPage() {
  const { setDropPageData } = useContext<any>(DropPageDataContext)
  const toast = useToast()

  const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  })

  const getDropPageData = async (slug: string) => {
    try {
      const { data } = await axiosInstance.get(api.GET_DROP_PAGE(slug))
      setDropPageData(data)
    } catch (e) {
      toast({
        title: 'Cant find the drop page',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return { getDropPageData }
}
