import styled from 'styled-components'

export const StyledTitle = styled.div<{
  level?: number
  pb?: number
  align?: string
  variant?: string
  fontFamily?: 'primary' | 'secondary'
  neon?: boolean
}>`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme, variant }) => theme.palette[variant ?? 'primary'].main};
  margin: 0;
  padding-bottom: ${({ theme, pb }) => theme.spacing(pb)};
  text-transform: uppercase;

  ${({ align }) => align && `text-align: ${align}`};

  & > span,
  p {
    color: ${({ theme, variant }) => theme.palette[variant ?? 'primary'].main};
  }

  ${({ neon, theme, variant }) => {
    if (neon) {
      return `
        color: transparent;
        background: ${theme.palette[variant ?? 'primary'].dark};
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        `
    }
  }}

  ${({ neon, theme, variant, level, fontFamily }) => {
    if (neon) {
      switch (level) {
        case 2:
          return `
          font-weight: ${theme.fontWeight.regular};
          font-family: ${fontFamily ? theme.fontFamily[fontFamily] : theme.fontFamily.primary};
          font-size: ${theme.fontSize['3xl']};
          line-height: ${theme.fontSize['3xl']};
          
          text-shadow: ${theme.palette[variant ?? 'primary'].dark} 0px 0px 20px;
        
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize['xl']};
            line-height: ${theme.fontSize['xl']};
          }
        `
        case 3:
          return `
          font-family: ${theme.fontFamily.title};
          color: ${theme.palette.text.dark};
          font-weight: ${theme.fontWeight.regular};
          font-size: ${theme.fontSize['xl']};
          text-shadow: ${theme.palette[variant ?? 'primary'].dark} 0px 0px 100px;
        
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize['lg']};
            line-height: ${theme.fontSize['lg']};
          }
        `
        case 4:
          return `
          font-family: ${theme.fontFamily[fontFamily ?? 'primary']};
          color: ${theme.palette.text.light};
          font-weight: ${theme.fontWeight.regular};
          font-size: ${theme.fontSize['lg']};
          text-shadow: ${theme.palette[variant ?? 'primary'].dark} 0px 0px 100px;
          
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize['sm']};
            line-height: ${theme.fontSize['lg']};
          }
        `
        case 5:
          return `
          color: ${theme.palette.text.dark};
          font-family: ${theme.fontFamily.secondary};
          font-size: ${theme.fontSize['base']};
          text-transform: none;
          line-height: 40px;
          ${neon ? `text-shadow: ${theme.palette[variant ?? 'primary'].dark} 0px 2px 100px;` : ''}
          
        `
        case 6:
          return `
          color: ${theme.palette.common.white};
          font-family: ${theme.fontFamily.secondary};
          font-size: ${theme.fontSize['xs']};
        `
      }

      return `
        color: transparent;
        background: ${theme.palette[variant ?? 'primary'].dark};
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
      `
    } else {
      switch (level) {
        case 2:
          return `
          font-family: ${fontFamily ? theme.fontFamily[fontFamily] : theme.fontFamily.primary};
          font-size: ${theme.fontSize['3xl']};
          line-height: ${theme.fontSize['3xl']};
          color: ${theme.palette.text[variant ?? 'primary']};
        
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize['xl']};
            line-height: ${theme.fontSize['xl']};
          }
        `
        case 3:
          return `
          font-family: ${theme.fontFamily.title};
          color: ${theme.palette.text.dark};
          font-weight: ${theme.fontWeight.regular};
          font-size: ${theme.fontSize['xl']};
        
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize['lg']};
            line-height: ${theme.fontSize['lg']};
          }
        `
        case 4:
          return `
          font-family: ${theme.fontFamily.primary};
          color: ${theme.palette.text.light};
          font-weight: ${theme.fontWeight.regular};
          font-size: ${theme.fontSize['lg']};
          
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize['sm']};
            line-height: ${theme.fontSize['lg']};
          }
        `
        case 5:
          return `
          color: ${theme.palette.text.dark};
          font-family: ${theme.fontFamily.secondary};
          font-size: ${theme.fontSize['base']};
          text-transform: none;
          line-height: 40px;
        `
        case 6:
          return `
          color: ${theme.palette.common.white};
          font-family: ${theme.fontFamily.secondary};
          font-size: ${theme.fontSize['xs']};
        `
      }
    }
  }}
`
