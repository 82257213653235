import { useContext, useState } from 'react'
import { ModalOverlay, ModalContent, ModalBody, Modal, HStack, Link, Spinner } from '@chakra-ui/react'

import { useTranslation } from 'next-i18next'
import { MagicConnectUserContext } from '../../context/MagicConnectUserProvider'
import AuthenticationTabs from '../AuthenticationTabs/AuthenticationTabs'
import { AuthenticationButton } from '../AuthenticationButton/AuthenticationButton'

export const AuthenticationModal = ({ alwaysOpened, showButton = true }: { alwaysOpened?: boolean; showButton?: boolean }) => {
  const { t } = useTranslation('common')
  const { isOpen, onClose } = useContext<any>(MagicConnectUserContext)
  const [isAlwaysOpen, setAlwaysOpen] = useState(alwaysOpened)

  return (
    <>
      {!isAlwaysOpen && showButton ? <AuthenticationButton /> : <Spinner />}
      <Modal isOpen={isAlwaysOpen ?? isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent
          fontFamily="Inter"
          maxW={{ base: '100%', sm: '100%', md: '668px' }}
          mb="0px"
          bg="white"
          border="1px solid #DBE3EC"
          borderRadius="17px"
          color="secondary.dark"
          px={{ base: '0px', sm: '0px', md: '48px 130px 80px' }}
          mt={{ base: '0px', sm: '0px', md: '80px' }}
          h={{ base: '100%', sm: '100%', md: 'auto' }}
          overflow={{ base: 'hidden', sm: 'scroll', md: 'auto' }}
        >
          <ModalBody>
            <AuthenticationTabs onClose={onClose} setAlwaysOpen={setAlwaysOpen} />

            <HStack
              margin="0 auto"
              fontSize="14px"
              lineHeight="24px"
              justifyContent="space-around"
              mb="20px"
              mt="100px"
              fontWeight={600}
              color="#B2B7CF"
              letterSpacing="-0.5px"
            >
              <Link href="https://docs.google.com/document/d/16h4QscPbULtME5jb-rsIJ9hC5-wE9HbR75Gbx71gDUg/edit">{t('terms_and_conditions')}</Link>
              <Link href="https://docs.google.com/document/d/1dQAQUwwzDsUgJ43w01LOWDS2bqakaEAtgvuvykiK0yE/edit">{t('privacy_policy')}</Link>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
