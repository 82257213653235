export const queries = {
  COLLECTION_DETAIL: 'COLLECTION_DETAIL',
  SHOP_CONFIG: 'SHOP_CONFIG',
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  GET_COLLECTIONS: 'GET_COLLECTIONS',
  COLLECTION_LIST: 'COLLECTION_LIST',
  GET_ARTIST_PROFILE: 'GET_ARTIST_PROFILE',
  DROP_PAGE_QUERY: 'DROP_PAGE_QUERY',
  GET_DROP_PAGE: 'GET_DROP_PAGE',
}
