import { useContext, useState } from 'react'
import { InstanceWithExtensions } from '@magic-sdk/provider/dist/types/modules/base-extension'
import { MagicSDKExtensionsOption, SDKBase } from '@magic-sdk/provider/dist/types/core/sdk'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useLogout } from '../../hooks/useLogout'
import { MagicConnectUserContext } from '../../context/MagicConnectUserProvider'

export const AuthenticationButton = () => {
  const magic = useContext<any>(MagicConnectUserContext).magic as InstanceWithExtensions<SDKBase, MagicSDKExtensionsOption<string>>
  const { onOpen, userDetails } = useContext<any>(MagicConnectUserContext)
  const [walletLoading, setWalletLoading] = useState(false)
  const { logout } = useLogout()

  const userWalletPublicAddress = userDetails?.wallet_public_address

  if (!userWalletPublicAddress) {
    return <ConnectButton onOpen={onOpen} />
  }

  const shortAddress = userWalletPublicAddress.substring(0, 4) + '...' + userWalletPublicAddress.substring(userWalletPublicAddress.length - 4)

  const openProfile = async () => {
    try {
      setWalletLoading(true)
      await magic.wallet.showUI()
    } catch (e) {
      setWalletLoading(false)
      console.info('Closed magic connect wallet!')
    }
  }

  return (
    <Menu gutter={10}>
      <MenuButton
        variant="outline"
        p="17px"
        isLoading={walletLoading}
        textTransform="uppercase"
        borderRadius="none"
        borderColor="platinum"
        fontSize={{ base: '12px', md: '14px' }}
        _hover={{
          bg: 'transparent',
        }}
        _active={{
          bg: 'transparent',
        }}
        as={Button}
      >
        {shortAddress}
      </MenuButton>
      <MenuList borderColor="transparent" fontWeight={500} minWidth="190px" p="0px">
        <MenuItem color="darkBlue" onClick={openProfile}>
          Wallet
        </MenuItem>
        <MenuItem color="darkBlue" onClick={logout}>
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const ConnectButton = ({ onOpen }: { onOpen: () => void }) => {
  return (
    <Button
      variant="outline"
      p="17px"
      textTransform="uppercase"
      borderRadius="none"
      onClick={() => onOpen()}
      borderColor="platinum"
      fontSize={{ base: '12px', md: '14px' }}
      _hover={{
        bg: 'transparent',
      }}
      _active={{
        bg: 'transparent',
      }}
    >
      Connect
    </Button>
  )
}
