import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledLoader = styled.path`
  transform-origin: inherit;
  animation: ${rotate360} 1.4s ease infinite;
`

export const DrawTokenIcon = () => {
  return (
    <svg width="154" height="154" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
      <StyledLoader
        d="M120.354 20.2074C133.815 30.4833 143.189 45.2066 146.803 61.7516C150.417 78.2965 148.037 95.5874 140.087 110.541C132.137 125.494 119.134 137.137 103.397 143.393C87.66 149.65 70.2123 150.114 54.1653 144.701C38.1184 139.289 24.5157 128.352 15.7832 113.842C7.05059 99.3321 3.75594 82.192 6.48678 65.4785C9.21762 48.765 17.7964 33.5649 30.6932 22.5889C43.5899 11.6129 59.9661 5.57477 76.9013 5.55129L76.905 8.27491C60.6155 8.29749 44.8635 14.1055 32.4584 24.663C20.0533 35.2206 11.8015 49.8413 9.17476 65.9177C6.54802 81.9941 9.71707 98.4808 18.1168 112.438C26.5164 126.395 39.6006 136.914 55.0358 142.12C70.471 147.326 87.2537 146.881 102.391 140.863C117.528 134.844 130.035 123.645 137.682 109.262C145.329 94.8788 147.618 78.2471 144.142 62.3328C140.666 46.4185 131.649 32.2565 118.701 22.3723L120.354 20.2074Z"
        fill="black"
      />
      <circle cx="76.5" cy="77.5" r="57.5" fill="#D0FF1A" />
      <path
        d="M59.8684 57V55.5C59.4298 55.5 59.0131 55.692 58.7281 56.0255L59.8684 57ZM90.1316 57L91.2719 56.0255C90.9869 55.692 90.5702 55.5 90.1316 55.5V57ZM100 68.5472L101.267 69.3501C101.619 68.794 101.568 68.0731 101.14 67.5726L100 68.5472ZM75 108L73.733 108.803C74.008 109.237 74.4861 109.5 75 109.5C75.5139 109.5 75.992 109.237 76.267 108.803L75 108ZM50 68.5472L48.8597 67.5726C48.432 68.0731 48.3806 68.794 48.733 69.3501L50 68.5472ZM88.9913 57.9745L98.8597 69.5217L101.14 67.5726L91.2719 56.0255L88.9913 57.9745ZM98.733 67.7443L73.733 107.197L76.267 108.803L101.267 69.3501L98.733 67.7443ZM76.267 107.197L51.267 67.7443L48.733 69.3501L73.733 108.803L76.267 107.197ZM51.1403 69.5217L61.0087 57.9745L58.7281 56.0255L48.8597 67.5726L51.1403 69.5217ZM50 70.0472H65.7895V67.0472H50V70.0472ZM58.5337 57.6844L64.4547 69.2316L67.1242 67.8627L61.2032 56.3156L58.5337 57.6844ZM65.7895 70.0472H84.2105V67.0472H65.7895V70.0472ZM84.2105 70.0472H100V67.0472H84.2105V70.0472ZM88.7968 56.3156L82.8758 67.8627L85.5453 69.2316L91.4663 57.6844L88.7968 56.3156ZM59.8684 58.5H75V55.5H59.8684V58.5ZM75 58.5H90.1316V55.5H75V58.5ZM66.9621 69.4825L76.1726 57.9354L73.8274 56.0646L64.6168 67.6118L66.9621 69.4825ZM85.3832 67.6118L76.1726 56.0646L73.8274 57.9354L83.0379 69.4825L85.3832 67.6118ZM64.3288 68.8882L73.5393 108.341L76.4607 107.659L67.2502 68.2062L64.3288 68.8882ZM82.7498 68.2062L73.5393 107.659L76.4607 108.341L85.6712 68.8882L82.7498 68.2062Z"
        fill="black"
      />
    </svg>
  )
}
