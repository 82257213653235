import React, { FC } from 'react'
import { Button, ButtonProps } from '../button/Button'
import { Spinner } from '@chakra-ui/react'

type ButtonWithSpinnerProps = ButtonProps & {
  disabled: boolean
  onClick?: () => void
  color?: 'secondary' | 'primary' | 'alt'
  type?: 'button' | 'submit'
  children: any
}

export const ButtonWithSpinner: FC<ButtonWithSpinnerProps> = ({ disabled, children, onClick, color = 'primary', type = 'button' }) => {
  return (
    <Button onClick={disabled ? () => null : onClick} disabled={disabled} color={color} type={type}>
      {disabled ? <Spinner /> : children}
    </Button>
  )
}
