import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { NETWORK_STATUS } from './useNetworkStatusV2'
import { MINT_STATUS } from './useUniqueCollectionContractV2Payload'
import { useNetworkStatus } from '@fuel/nft'
import { useAccount, useBalance } from 'wagmi'
import { UniqueCollectionV2MintPhaseType } from '../types'

interface UseMintInsufficientFundsV2Args {
  uniqueCollectionContractV2: any
  gasPrice?: string
  mintPayload: {
    status: MINT_STATUS
    proof: any
    mintPhase: UniqueCollectionV2MintPhaseType | null
  }
}

export const useMintInsufficientFundsV2 = ({ uniqueCollectionContractV2, gasPrice, mintPayload }: UseMintInsufficientFundsV2Args) => {
  const [hasEnoughFunds, setHasEnoughFunds] = useState(true)
  const { networkStatus } = useNetworkStatus()
  const { address } = useAccount()
  const { data: walletBalance } = useBalance({
    addressOrName: address,
  })
  const mintStatus = mintPayload.status
  const proof = mintPayload.proof
  const mintPrice = mintPayload?.mintPhase?.mintPrice
  const mintPhaseIndex = mintPayload?.mintPhase?.mintPhaseIndex

  useEffect(() => {
    ;(async () => {
      if (uniqueCollectionContractV2 && networkStatus === NETWORK_STATUS.CORRECT) {
        try {
          let estimatedGas = null
          // TODO: Check if proof exists for valid argument

          // Estimated gas for allowListMint
          if (mintStatus === MINT_STATUS.NOW_ON_ALLOW_LIST) {
            estimatedGas = await uniqueCollectionContractV2.estimateGas.allowListMint(mintPhaseIndex, proof, [], {
              value: mintPrice,
              gasLimit: gasPrice,
            })
          }

          // Estimated gas for publicMint
          if (mintStatus === MINT_STATUS.NOW_PUBLIC) {
            estimatedGas = await uniqueCollectionContractV2.estimateGas.publicMint(mintPhaseIndex, [], {
              value: mintPrice,
              gasLimit: gasPrice,
            })
          }

          // Compare price on the estimated gas and wallet balance
          if (estimatedGas) {
            // TODO: PUT THIS LINE  BACK IN AT LINE 60 WHEN WE HAVE A GAS PRICE
            // .mul(gasPrice)
            const gasPriceInWei = ethers.utils.parseUnits(estimatedGas.toString(), 'gwei')

            if (mintPrice.add(gasPriceInWei).lte(walletBalance?.value)) {
              setHasEnoughFunds(true)
            }
          }
          // TODO: Catch not enough gas!
        } catch (e: any) {
          if (e.code === 'INSUFFICIENT_FUNDS') {
            console.log('INSUFFICIENT_FUNDS')
            setHasEnoughFunds(false)
          }
        }
      }
    })()
  }, [walletBalance, uniqueCollectionContractV2, gasPrice, networkStatus, mintStatus, proof, address])

  return { hasEnoughFunds }
}
