import { StepperAnimation } from '@fuel/core'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import UserDetailsForm from '../../../Details/userDetailsForm'
import { StyledTitle, StyledSubtitle } from '../Stepper.styled'
import { Box } from '@chakra-ui/react'
import { useAxiosWithAuth } from '@fuel/api'
import { useQuery } from 'react-query'

export const Step2 = ({
  isTurkish,
  updateUserDetails,
  methods,
  setIsLeavingPurchase,
}: {
  isTurkish: boolean
  updateUserDetails: any
  methods: any
  setIsLeavingPurchase: any
}) => {
  const axiosApi = useAxiosWithAuth()

  const { data } = useQuery(
    [],
    async () => {
      try {
        const { data } = await axiosApi.get('/users/account')
        return data
      } catch (e) {
        console.log('Error', e)
      }
    },
    {
      cacheTime: 0,
    }
  )

  const { reset: resetForm } = methods

  useEffect(() => {
    if (data) {
      resetForm({
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        phone_number: data?.phone_number || '',

        address: {
          street: data?.address?.street || '',
          house_number: data?.address?.house_number || '',
          post_code: data?.address?.post_code || '',
          city: data?.address?.city || '',
          country: data?.address?.country || '',
        },
        consent: {
          text: isTurkish
            ? 'Not: Bileti alabilmek için en az 14 yaşında olman gerekiyor, aksi takdirde ebeveynlerinden biriyle gelmen gerekiyor.'
            : 'Bestätige bitte, dass du mindestens 14 Jahre alt bist. ',
        },
      })
    }
  }, [data])

  if (updateUserDetails.status === 'loading') {
    return null
  } else
    return (
      <motion.div
        variants={StepperAnimation}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
        style={{ textAlign: 'center' }}
      >
        <Box my={[0, 0, 10]}>
          <StyledTitle as="h3" size="lg" mb={4}>
            {isTurkish ? 'Fatura Bilgileri' : 'Zahlungsinformationen'}
          </StyledTitle>
          <StyledSubtitle>
            {isTurkish ? 'Devam edebilmemiz için birkaç detaya ihtiyacımız var.' : 'Wir benötigen ein paar Angaben, bevor wir fortfahren können.'}
          </StyledSubtitle>
        </Box>
        <UserDetailsForm methods={methods} isTurkish={isTurkish} />
      </motion.div>
    )
}
