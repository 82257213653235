import { VStack, FormControl, Checkbox, InputGroup } from '@chakra-ui/react'
import { Countries } from '@fuel/core'
import { CountryCustomSelect } from '@fuel/ui'
import PhoneInput from 'react-phone-input-2'
import React from 'react'
import { Controller } from 'react-hook-form'
import 'react-phone-input-2/lib/style.css'

import { StyledInput, StyledFormErrorMessage, StyledCheckbox, StyledInputLeftAddon } from './UserDetailsForm.styled'

export default function UserDetailsForm({ methods, isTurkish }: { methods: any; isTurkish?: boolean }) {
  const {
    register,
    formState: { errors },
    control,
  } = methods

  return (
    <>
      <VStack alignItems="flex-start" gap="10px">
        <FormControl tabIndex={0}>
          <StyledInput
            placeholder="Vorname"
            {...register('first_name', {
              required: {
                value: true,
                message: isTurkish ? 'Gerekli' : 'Erforderlich',
              },
              minLength: {
                value: 3,
                message: isTurkish ? 'İsim adı en az 3 karakter olmalıdır' : 'Das Feld muss mindestens 3 Zeichen lang sein',
              },
              maxLength: {
                value: 80,
                message: isTurkish ? 'İsim 80 karakterden kısa olmalıdır' : 'Das Feld muss kürzer als 80 Zeichen sein',
              },
            })}
          />
          <StyledFormErrorMessage>{errors.first_name && errors.first_name.message}</StyledFormErrorMessage>
        </FormControl>
        <FormControl>
          <StyledInput
            placeholder="Nachname"
            {...register('last_name', {
              required: {
                value: true,
                message: isTurkish ? 'Gerekli' : 'Erforderlich',
              },
              minLength: {
                value: 3,
                message: isTurkish ? 'İsim adı en az 3 karakter olmalıdır' : 'Das Feld muss mindestens 3 Zeichen lang sein',
              },
              maxLength: {
                value: 80,
                message: isTurkish ? 'İsim 80 karakterden kısa olmalıdır' : 'Das Feld muss kürzer als 80 Zeichen sein',
              },
            })}
          />
          <StyledFormErrorMessage>{errors.last_name && errors.last_name.message}</StyledFormErrorMessage>
        </FormControl>
        <FormControl>
          <StyledInput
            placeholder="Straße"
            {...register('address.street', {
              required: {
                value: true,
                message: isTurkish ? 'Gerekli' : 'Erforderlich',
              },
              minLength: {
                value: 3,
                message: isTurkish ? 'İsim adı en az 3 karakter olmalıdır' : 'Das Feld muss mindestens 3 Zeichen lang sein',
              },
              maxLength: {
                value: 80,
                message: isTurkish ? 'İsim 80 karakterden kısa olmalıdır' : 'Das Feld muss kürzer als 80 Zeichen sein',
              },
            })}
          />
          <StyledFormErrorMessage>{errors.address?.street && errors.address.street.message}</StyledFormErrorMessage>
        </FormControl>

        <FormControl>
          <StyledInput
            placeholder="Hausnummer"
            {...register('address.house_number', {
              required: {
                value: true,
                message: isTurkish ? 'Gerekli' : 'Erforderlich',
              },
            })}
          />
          <StyledFormErrorMessage>{errors.address?.house_number?.message}</StyledFormErrorMessage>
        </FormControl>

        <FormControl>
          <StyledInput
            margin="0 auto"
            placeholder="Postleitzahl"
            {...register('address.post_code', {
              required: {
                value: true,
                message: isTurkish ? 'Gerekli' : 'Erforderlich',
              },
              minLength: {
                value: 3,
                message: isTurkish ? 'İsim adı en az 3 karakter olmalıdır' : 'Das Feld muss mindestens 3 Zeichen lang sein',
              },
              maxLength: {
                value: 80,
                message: isTurkish ? 'İsim 80 karakterden kısa olmalıdır' : 'Das Feld muss kürzer als 80 Zeichen sein',
              },
            })}
          />
          <StyledFormErrorMessage>{errors.address?.post_code?.message}</StyledFormErrorMessage>
        </FormControl>

        <FormControl>
          <StyledInput
            placeholder="Stadt"
            {...register('address.city', {
              required: {
                value: true,
                message: isTurkish ? 'Gerekli' : 'Erforderlich',
              },
              minLength: {
                value: 3,
                message: isTurkish ? 'İsim adı en az 3 karakter olmalıdır' : 'Das Feld muss mindestens 3 Zeichen lang sein',
              },
              maxLength: {
                value: 80,
                message: isTurkish ? 'İsim 80 karakterden kısa olmalıdır' : 'Das Feld muss kürzer als 80 Zeichen sein',
              },
            })}
          />
          <StyledFormErrorMessage>{errors.address?.city && errors.address?.city.message}</StyledFormErrorMessage>
        </FormControl>

        <FormControl>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <InputGroup>
                <StyledInputLeftAddon
                  children={
                    Countries.find(c => c.label === value)?.flag
                      ? Countries.find(c => c.label === value)?.flag
                      : Countries.find(c => c.label === (isTurkish ? 'Turkey' : 'Germany'))?.flag
                  }
                />
                <CountryCustomSelect
                  inputRef={ref}
                  value={Countries.find(c => c.label === value)}
                  defaultValue={Countries.find(c => c.label === (isTurkish ? 'Turkey' : 'Germany'))}
                  name={name}
                  options={Countries}
                  onChange={(selectedOption: any) => {
                    onChange(selectedOption.label)
                  }}
                />
              </InputGroup>
            )}
            name={'address.country'}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <PhoneInput
                inputStyle={{
                  maxWidth: '75%',
                  marginLeft: 90,
                }}
                country={isTurkish ? 'tr' : 'de'}
                placeholder="Enter phone number"
                value={value}
                onChange={value => {
                  onChange(`+${value}`)
                }}
              />
            )}
            {...register('phone_number', {
              required: {
                value: true,
                message: 'Gerekli',
              },
              minLength: {
                value: 5,
                message: 'İsim adı en az 5 karakter olmalıdır',
              },
            })}
          />
          <StyledFormErrorMessage>{errors?.phone_number && errors?.phone_number?.message}</StyledFormErrorMessage>
        </FormControl>

        <FormControl>
          <Checkbox
            {...register('consent.approved', {
              required: {
                value: true,
                message: 'Gerekli',
              },
            })}
          >
            <StyledCheckbox>
              {isTurkish
                ? 'Not: Bileti alabilmek için en az 14 yaşında olman gerekiyor, aksi takdirde ebeveynlerinden biriyle gelmen gerekiyor.'
                : 'Bestätige bitte, dass du mindestens 14 Jahre alt bist. '}
            </StyledCheckbox>
          </Checkbox>

          <StyledFormErrorMessage>{errors.consent?.approved && errors.consent?.approved.message}</StyledFormErrorMessage>
        </FormControl>
      </VStack>
    </>
  )
}
