import styled from 'styled-components'

export const StyledButton = styled.button<{ neon?: boolean; variant?: string }>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.button};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ color, theme, variant }) => {
    switch (color) {
      case 'primary':
        if (variant === 'outline') {
          return `
            border: 2px solid ${theme.palette.primary.main};
            background: ${theme.palette.common.white};
          `
        }
        return `
          border: ${theme.button.primary.border};
          background: ${theme.palette.primary.main};
          color: ${theme.palette.button.primary};
        `
      case 'secondary':
        return `
          border: ${theme.button.secondary.border};
          background: ${theme.palette.secondary.main};
          color: ${theme.palette.text.light};
        `
      case 'error':
        if (variant === 'text') {
          return `
            color: ${theme.palette.text.error};
          `
        }
        return `
            color: ${theme.palette.text.error};
            background: ${theme.palette.common.error};
          `
      case 'alt':
        if (variant === 'outline') {
          return `
            border: 3px solid ${theme.palette.common.black};
            background: ${theme.palette.common.white};
          `
        }
        return `
          background: ${theme.palette.common.black};
          color: ${theme.palette.primary.main};
        `
    }
  }}
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.button.padding};
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }

  &:active {
    transform: translateY(4px);
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
    background: ${theme.palette.common.disabled};
    &:hover {
      transform: translateY(0);
    }
  `}

  ${({ neon, color }) => {
    if (neon) {
      if (color === 'primary') {
        return `
          box-shadow: 0px 0px 96px rgba(245, 71, 208, 0.8), inset 0px 0px 32px rgba(243, 22, 195, 0.6);
        `
      }
      if (color === 'secondary') {
        return `box-shadow: 0px 0px 96px rgba(71, 245, 245, 0.8), inset 0px 0px 32px rgba(22, 243, 243, 0.6);
        `
      }
    }
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(10)};
  }
`
