import { handleSetItems } from './handleSetItems'

interface AllowListMintArgs {
  uniqueCollectionContractV2: any
  mintPhaseIndex: number | undefined
  proof: string[] | undefined
  mintPrice: any
  mintPayload: any
  setItems: any
  address: string | undefined
  openSuccess: () => void
  setTxHash: (txHash: string) => void
}

export const allowListMint = async ({
  uniqueCollectionContractV2,
  mintPhaseIndex,
  proof,
  mintPrice,
  mintPayload,
  setItems,
  address,
  openSuccess,
  setTxHash,
}: AllowListMintArgs) => {
  try {
    const mint = await uniqueCollectionContractV2?.allowListMint(mintPhaseIndex, proof, [], {
      value: mintPrice,
      gasLimit: 1000000, // STOP THE EXECUTION OF THE TRANSACTION IF THE GAS LIMIT IS EXCEEDED
    })
    if (mint) {
      openSuccess()
      mint.wait().then(async (data: any) => {
        setTxHash(data.transactionHash)
      })
    }
  } catch (e) {
    setTxHash('')
    console.log('User rejected transaction')
    // if the user rejects metamask transaction we need to reset the items
    await handleSetItems({ mintPayload, setItems, uniqueCollectionContractV2, address })
  }
}
