import React, { FC, useEffect, useRef, useState } from 'react'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBackwardStep, faForwardStep, faPause, faPlay, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { StyledMusicPlayer } from './MusicPlayer.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MusicPlayerSongModel } from './MusicPlayerSongModel'
import { MusicPlayerSongname } from './musicPlayerSongname/MusicPlayerSongname'
import { MusicPlayerArtistName } from './musicPlayerArtistName/MusicPlayerArtistName'
import { MusicPlayerControl } from './musicPlayerControl/MusicPlayerControl'
import { MusicPlayerToggle } from './musicPlayerToggle/MusicPlayerToggle'

library.add(faBackwardStep, faPlay, faForwardStep, faPause, faTimes, faPlus)

type MusicPlayerProps = {
  musicPlayerSongs: MusicPlayerSongModel[]
  showPreviousSongButton?: boolean
  showNextSongButton?: boolean
}

export const MusicPlayer: FC<MusicPlayerProps> = ({ musicPlayerSongs, showPreviousSongButton = true, showNextSongButton = true }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const audioPlayer = useRef<HTMLAudioElement | null>(null)
  const [currentSong, setCurrentSong] = useState(0)

  const togglePlayer = () => {
    const prevValue = isPlaying
    setIsPlaying(!prevValue)
    if (audioPlayer.current) {
      if (prevValue) {
        audioPlayer.current.pause()
      } else {
        audioPlayer.current.play()
      }
    }
  }

  const nextSong = () => {
    if (currentSong === musicPlayerSongs.length - 1) {
      setCurrentSong(0)
    } else {
      setCurrentSong(currentSong + 1)
    }
    if (audioPlayer.current) {
      audioPlayer.current.pause()
      audioPlayer.current.load()
      if (isPlaying) {
        audioPlayer.current.play()
      }
    }
  }

  const prevSong = () => {
    if (currentSong === 0) {
      setCurrentSong(musicPlayerSongs.length - 1)
    } else {
      setCurrentSong(currentSong - 1)
    }
    if (audioPlayer.current) {
      audioPlayer.current.pause()
      audioPlayer.current.load()
      if (isPlaying) {
        audioPlayer.current.play()
      }
    }
  }

  useEffect(() => {
    audioPlayer.current?.addEventListener('ended', async () => {
      if (currentSong === musicPlayerSongs.length - 1) {
        setCurrentSong(0)
      } else {
        setCurrentSong(currentSong + 1)
      }
      try {
        if (audioPlayer.current) {
          await audioPlayer.current.pause()
          await audioPlayer.current.load()
          await audioPlayer.current.play()
        }
      } catch (e) {
        console.info('Song finished, playing next song.')
      }
    })
  }, [audioPlayer, currentSong])

  return (
    <StyledMusicPlayer isExpanded={isExpanded}>
      <audio ref={audioPlayer}>
        <source src={musicPlayerSongs[currentSong].song} type="audio/mpeg" />
      </audio>
      <div>
        <MusicPlayerSongname songname={musicPlayerSongs[currentSong].songName} />
        <MusicPlayerArtistName artistName={musicPlayerSongs[currentSong].artistName} />
        {showPreviousSongButton && (
          <MusicPlayerControl onClick={prevSong} name="Previous song">
            <FontAwesomeIcon icon={['fas', 'backward-step']} size="lg" />
          </MusicPlayerControl>
        )}
        <MusicPlayerControl onClick={togglePlayer} name={isPlaying ? 'Pause song' : 'Play song'}>
          <FontAwesomeIcon icon={['fas', isPlaying ? 'pause' : 'play']} size="lg" />
        </MusicPlayerControl>
        {showNextSongButton && (
          <MusicPlayerControl onClick={nextSong} name="Next song">
            <FontAwesomeIcon icon={['fas', 'forward-step']} size="lg" />
          </MusicPlayerControl>
        )}
      </div>
      <MusicPlayerControl onClick={togglePlayer} name={isPlaying ? 'Pause song' : 'Play song'}>
        <FontAwesomeIcon icon={['fas', isPlaying ? 'pause' : 'play']} size="lg" />
      </MusicPlayerControl>
      <MusicPlayerToggle isExpanded={isExpanded} setIsExpanded={setIsExpanded} name={isExpanded ? 'Collapse music player' : 'Expand music player'} />
    </StyledMusicPlayer>
  )
}
