import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

export const useAllowedAddress = (allowList: any) => {
  const [isAllowed, setIsAllowed] = useState(false)
  const { address } = useAccount()

  useEffect(() => {
    ;(async () => {
      if (address && allowList) {
        setIsAllowed(Object.keys(allowList).includes(address))
      }
    })()
  }, [allowList, address])

  return { isAllowed }
}
