import React, { FC, ReactNode } from 'react'
import { ErrorMessage } from '../typography/errorMessage/ErrorMessage'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { AnimatePresence } from 'framer-motion'

type InputWithValidationProps = {
  error: FieldError
  field: string
  children: ReactNode
}

export const InputWithValidation: FC<InputWithValidationProps> = ({ error, children, field }) => {
  const { t } = useTranslation('common')

  return (
    <>
      <AnimatePresence>
        {error && (
          <ErrorMessage
            text={t(`${error.type}`, {
              field,
            })}
          />
        )}
      </AnimatePresence>
      {children}
    </>
  )
}
