import React, { useContext, useEffect } from 'react'
import { Button, Spinner, useToast } from '@chakra-ui/react'
import { PaymentContext } from '@fuel/payment'
import { MagicConnectUserContext } from '@fuel/auth'
import { api, storage } from '@fuel/core'
import { StyledButton } from './EditionCollectionPurchaseButtonStyles'
import { PurchaseStatus } from '../../constants/paymentMethods'
import SucceedPaymentModal from '../SucceedPaymentModal'
import { useAxiosWithAuth } from '@fuel/api'
import { getLocalStorage } from '@fuel/core/services/localStorage'

export const EditionCollectionPurchaseButton = ({
  status,
  styles,
  buyNowText = 'Buy now',
  isTurkish = false,
}: {
  status: string
  styles?: any
  buyNowText?: string
  isTurkish?: boolean
}) => {
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
  const { purchaseLoading } = useContext<any>(PaymentContext)
  const { setDetailModalOpen, address } = useContext<any>(MagicConnectUserContext)

  const axiosApi = useAxiosWithAuth()

  const [nftStatus, setNftStatus] = React.useState('')

  const SoldOutButton = () => (
    <Button disabled variant="primary">
      Sold out
    </Button>
  )

  const handleMintNow = () => {
    setDetailModalOpen(true)
  }

  useEffect(() => {
    const getNftStatus = async () => {
      try {
        const response = axiosApi.get<{ status: PurchaseStatus }>(api.GET_QUEUE_PURCHASE_STATUS(selectedEdition.id))
        const { data } = await response
        setNftStatus(data.status)
      } catch (e) {
        console.log(e)
      }
    }
    if (address) getNftStatus()
  }, [])

  return (
    <>
      <SucceedPaymentModal />

      {nftStatus === PurchaseStatus.SOLD_OUT ? (
        <SoldOutButton />
      ) : status === 'sale' ? (
        <StyledButton status={status} onClick={handleMintNow} disabled={purchaseLoading} styles={styles}>
          {purchaseLoading ? <Spinner /> : buyNowText}
        </StyledButton>
      ) : null}
    </>
  )
}
