import React, { FC, ReactNode } from 'react'
import { StyledDropdownButton } from './DropdownButton.styles'

type DropdownButtonProps = {
  label: string | ReactNode
  items: any
}

export const DropdownButton: FC<DropdownButtonProps> = ({ label, items }) => {
  return (
    <StyledDropdownButton>
      <>
        {label}
        <div data-name="menu">{items}</div>
      </>
    </StyledDropdownButton>
  )
}
