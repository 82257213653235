import React, { FC } from 'react'
import { NftBlock } from './NftBlock'
import { AnimatePresence } from 'framer-motion'
import { NftModel } from '@fuel/nft/models/NftModel'
import { UtilityModel } from '@fuel/nft'

type NftListProps = {
  nfts: NftModel[]
  isFetching?: boolean
  onNotificationClose: (utility: UtilityModel) => void
}

export const NftList: FC<NftListProps> = ({ nfts, onNotificationClose }) => {
  return (
    <AnimatePresence>
      {nfts?.map((nft, index) => (
        <NftBlock nft={nft} key={index} onNotificationClose={onNotificationClose} />
      ))}
    </AnimatePresence>
  )
}
