import React, { FunctionComponent } from 'react'
import { StyledTitle } from './Title.styles'

type TitleProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6
  pb?: number
  variant?: 'primary' | 'secondary' | 'alt'
  align?: 'left' | 'center' | 'right'
  parseHtml?: boolean
  fontFamily?: 'primary' | 'secondary'
  neon?: boolean
}

export const Title: FunctionComponent<TitleProps> = ({ children, pb, level, align, variant, parseHtml = false, neon, fontFamily }) => {
  const props = {
    as: `h${level}`,
    level: level,
    pb: pb,
    align: align,
    variant: variant,
    fontFamily,
    neon,
  }

  if (parseHtml) {
    return (
      // @ts-ignore
      <StyledTitle {...props} dangerouslySetInnerHTML={{ __html: children }} />
    )
  }

  // @ts-ignore
  return <StyledTitle {...props}>{children}</StyledTitle>
}
