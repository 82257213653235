export const prepareMessage = ({ address, issued_at, domain, nonce }: { address: string; issued_at: number; domain: string; nonce: string }) => {
  return `${domain} wants you to sign in with your Ethereum account:
${address}

Signing is the only way to prove that you are the owner of your wallet. It does not grant FUEL permission to perform any transactions. Signing is safe and costs no gas.

URL: ${domain}
Version: 1
Nonce: ${nonce}
Issued At: ${issued_at}
`
}
