import React, { FC } from 'react'
import { StyledText } from './Text.styles'

type TextProps = {
  pt?: number
  pb?: number
  pl?: number
  pr?: number
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'base' | 'xl'
  disabled?: boolean
  color?: 'secondary' | 'primary' | 'disabled' | 'success' | 'light' | 'dark'
  fontFamily?: 'primary' | 'secondary'
  fontWeight?: 'regular' | 'bold' | 'semibold'
  lineClamp?: number | 'none'
  children: any
}

export const Text: FC<TextProps> = ({
  children,
  disabled = false,
  color = 'primary',
  pb,
  pr,
  pt,
  pl,
  size,
  fontFamily = 'secondary',
  fontWeight = 'regular',
  lineClamp,
}) => {
  return (
    <StyledText
      size={size}
      pt={pt}
      pb={pb}
      pr={pr}
      pl={pl}
      disabled={disabled}
      color={color}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      lineClamp={lineClamp}
    >
      {children}
    </StyledText>
  )
}
