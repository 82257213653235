import { encodeTokenId } from './encodeTokenId'

const cantMintResponse = {
  tokenId: null,
  canMint: false,
}

interface FindTokenIdToMintWithConstraintArgs {
  address: string
  tokenIds: number[]
  constraintContract: any
}

export const findTokenIdToMintWithConstraint = async ({ address, tokenIds, constraintContract }: FindTokenIdToMintWithConstraintArgs) => {
  let tokenId
  // how many tokens can we mint per tokenId
  const amountCanMint = (await constraintContract.amountCanMint()).toNumber()
  for (let i = 0; i < tokenIds.length; i++) {
    try {
      tokenId = tokenIds[i]

      // check how many tokens have already been minted for this tokenId
      const tokenIdMinted = (await constraintContract.tokenIdMinted(tokenId)).toNumber()
      //if this tokenid has already been used we don't need to check anymore and can continue with the next tokenId
      if (tokenIdMinted >= amountCanMint) return cantMintResponse

      // tokenId needs to be converted into a specific format to be passed to the contract
      const tokenIdBytesConverted = encodeTokenId(tokenId)

      // ask the constraint contract if minting would work (simulation)
      const canMint = await constraintContract.canMint(address, tokenIdBytesConverted)

      if (canMint) {
        return {
          tokenId,
          canMint: true,
        }
      }
    } catch (e) {
      console.error('Something went wrong in findTokenIdToMintWithConstraint')
      console.error(e)
    }
  }

  return cantMintResponse
}
