export const containerBlockAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 2,
    },
  },
}
