import { NULL_MERKLE_ROOT } from '../hooks/useUniqueCollectionContractV2Payload'
import { UniqueCollectionV2MintPhaseType } from '../types'

export const separateMintPhasesByMintType = (mintPhases: Array<UniqueCollectionV2MintPhaseType>) => {
  const allowListMintPhases = mintPhases.filter(mintPhase => {
    return mintPhase.allowListMerkleRoot !== NULL_MERKLE_ROOT
  })
  const publicMintPhases = mintPhases.filter(mintPhase => {
    return mintPhase.allowListMerkleRoot === NULL_MERKLE_ROOT
  })
  return {
    allowListMintPhases,
    publicMintPhases,
  }
}
