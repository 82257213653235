import styled from '@emotion/styled'
import { Button, ModalContent, Text } from '@chakra-ui/react'

export const StyledHeaderText = styled(Text)`
  font-family: inter;
  font-weight: 800;
  line-height: 44px;
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 30px;
`

export const StyledPayButton = styled(Button)`
  background: #e8ecfb;
  border-radius: 50px;
  letter-spacing: -0.5px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  width: 150px;
  color: #21232f;
  padding: 30px;
  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    &:active {
      transform: scale(1);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }
`

export const StyledModalContent = styled(ModalContent)`
  background: #0e0e16;
  color: #e8ecfb;
  box-shadow: 0px 4px 30px rgba(14, 14, 22, 0.45);
  border-radius: 17px;
  text-align: center;
  max-width: 650px;
  border-radius: 17px;
  padding: 0px 100px 0px 100px;
  height: fit-content;
  margin: 0px;
  @media (max-width: 768px) {
    margin: 5px;
    padding: 10px;
  }
`
export const StyledTotalText = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;

  color: #e8ecfb;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
export const StyledFooterText = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin: 0 auto;
  text-align: center;
  color: #b2b7cf;
  margin-top: 35px;

  span {
    font-weight: 800;
  }

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`
