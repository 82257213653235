export const Input = {
  variants: {
    filled: {
      field: {
        bg: 'grey.100',
        height: 50,
        border: '2px solid',
        fontWeight: 500,
        _focus: {
          borderColor: 'secondary.accent',
        },
        _hover: {
          bg: 'grey.200',
        },
      },
    },
  },
}
