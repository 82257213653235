import { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useAccount } from 'wagmi'
import { CollectionContext } from '@fuel/nft'
import { useUniqueCollectionContract } from '@fuel/nft'
import { MINT_STATUS } from '@fuel/nft'
import { abi } from '../../constants/abi'

export const useMintPerAddress = (mintStatus: MINT_STATUS) => {
  const { address } = useAccount()
  const { collection }: any = useContext(CollectionContext)
  const { uniqueCollectionContract } = useUniqueCollectionContract({ contractAddress: collection?.contract_address, abi: abi.COLLECTION_ABI })
  const [isMaxMinted, setMaxMinted] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (uniqueCollectionContract && (mintStatus === MINT_STATUS.PUBLIC || mintStatus === MINT_STATUS.ALLOW_LIST)) {
        const minted = +(await uniqueCollectionContract.minted(address)).toString()
        if (mintStatus === MINT_STATUS.ALLOW_LIST) {
          const maxAllowListMintPerAddress = await uniqueCollectionContract.maxAllowListMintPerAddress()
          if (+maxAllowListMintPerAddress.toString() !== 0) {
            setMaxMinted(minted >= +maxAllowListMintPerAddress.toString())
            Sentry.addBreadcrumb({
              category: 'mint',
              message: 'Allow list max minted reached!',
              level: 'info',
            })
          }
        } else {
          setMaxMinted(false)
        }
      }
    })()
  }, [uniqueCollectionContract, mintStatus, address])

  return { isMaxMinted }
}
