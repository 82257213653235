export const Textarea = {
  variants: {
    filled: {
      bg: 'grey.100',
      border: '2px solid',
      fontWeight: 500,
      _focus: {
        borderColor: 'secondary.accent',
      },
      _hover: {
        bg: 'grey.200',
      },
    },
  },
}
