import styled from 'styled-components'

export const StyledText = styled.p<{
  fontFamily?: string
  pr?: number
  pb?: number
  pl?: number
  pt?: number
  disabled?: boolean
  lineClamp?: number | 'none'
  fontWeight?: string
  size?: string
}>`
  white-space: pre-wrap;
  font-family: '${({ theme, fontFamily }) => theme.fontFamily[fontFamily ?? 'primary']}';
  padding: ${({ pt }) => pt ?? 0}px ${({ pr }) => pr ?? 0}px ${({ pb }) => pb ?? 0}px ${({ pl }) => pl ?? 0}px;
  ${({ disabled, theme }) => disabled && `color: ${theme.palette.common.disabled};`}
  color: ${({ theme, color }) => theme.palette.text[color ?? 'primary']};

  ${({ lineClamp }) =>
    lineClamp &&
    `
      display: -webkit-box;
      -webkit-line-clamp: ${lineClamp};
      -webkit-box-orient: vertical;  
      overflow: hidden;
  `}

  ${({ fontWeight, theme }) => `font-weight: ${theme.fontWeight[fontWeight ?? 'regular']}`};
  ${({ theme, size }) => {
    switch (size) {
      case 'xxs':
        return `
          font-size: ${theme.fontSize.xxs};
        `
      case 'xs':
        return `
          font-size: ${theme.fontSize.xs};
          line-height: ${theme.fontSize.xs};
        `
      case 'sm':
        return `
          font-size: ${theme.fontSize.sm};
        `
      case 'md':
        return `
          font-size: ${theme.fontSize.md};
          
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize.sm};
          }
        `
      case 'base':
        return `
          font-size: ${theme.fontSize.base};
          
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize.md};
          }
        `
      case 'xl':
        return `
          font-size: ${theme.fontSize['xl']};
          line-height: ${theme.fontSize.xl};
          
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize.lg};
          line-height: ${theme.fontSize.lg};
          }
        `
      default:
        return `
          font-size: ${theme.fontSize.lg};
          
          @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.fontSize.md};
          }
        `
    }
  }}
`
