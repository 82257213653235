import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import { useCollectionExit } from '../../hooks/useCollectionExit'
import { useStore } from '../../zustand/store'

export const CollectionCreateExitModal = () => {
  const cancelRef = React.useRef()
  const { exitCollectionModal, setExitCollectionModal } = useStore()
  const { exitCollectionCreation } = useCollectionExit()

  return (
    <>
      <AlertDialog isOpen={exitCollectionModal} leastDestructiveRef={cancelRef} onClose={() => setExitCollectionModal(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Leave create collection?
            </AlertDialogHeader>

            <AlertDialogBody>Exiting the process you will lose all progress. Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setExitCollectionModal(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={exitCollectionCreation} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
