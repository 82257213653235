import { PaymentProvider } from '@fuel/payment'
import { ErrorModal, ModalsProvider } from '@fuel/core'
import MagicConnectUserProvider from './MagicConnectUserProvider'
import DropPageDataProvider from './DropPageDataProvider'
import { PhoneVerificationProvider } from './PhoneVerificationProvider'

export function MagicConnectWrapperProvider({ children }: any) {
  return (
    <PaymentProvider>
      <ModalsProvider>
        <ErrorModal />
        <MagicConnectUserProvider>
          <DropPageDataProvider>
            <PhoneVerificationProvider>{children}</PhoneVerificationProvider>
          </DropPageDataProvider>
        </MagicConnectUserProvider>
      </ModalsProvider>
    </PaymentProvider>
  )
}
