import { Flex, Box } from '@chakra-ui/react'
import { Steps, Step } from 'chakra-ui-steps'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { PurchaseStatus } from '../../../constants/paymentMethods'
import { StyledPayButton, StyledFooterText } from '../Payment/Payment.styled'

export const StepperForm = ({
  methods,
  steps,
  activeStep,
  setStep,
  nextStep,
  handleFirstStep,
  updateUserDetails,
  purchaseStatus,
  isLeavingPurchase,
  setIsLeavingPurchase,
  leaveQueue,
  isTurkish,
}: {
  isTurkish?: boolean
  methods: any
  steps: any
  activeStep: number
  setStep: any
  nextStep: any
  handleFirstStep: any
  updateUserDetails: any
  purchaseStatus: any
  isLeavingPurchase: any
  setIsLeavingPurchase: any
  leaveQueue: () => void
}) => {
  const { handleSubmit } = methods

  const onSubmit = () => {
    try {
      const data: any = methods.getValues()

      const newData = {
        ...data,
        address: {
          ...data.address,
        },
      }
      updateUserDetails.mutate(newData)

      nextStep()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isLeavingPurchase && activeStep === 1) {
      setIsLeavingPurchase(false)
    }
  }, [activeStep])

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="column" width="100%" mt={[10, 10, 0]}>
          <Steps
            activeStep={activeStep}
            onClickStep={step => {
              if (step < activeStep) {
                setStep(step)
              }
            }}
            state={purchaseStatus === PurchaseStatus.ENQUEUED ? 'loading' : isLeavingPurchase ? 'loading' : undefined}
          >
            {steps.map(({ label, content }: any) => (
              <Step label={label} key={label}>
                {content}
              </Step>
            ))}
          </Steps>
          <Box>
            {activeStep !== steps.length - 1 ? (
              <Flex width="100%" justifyContent="center" mt={[10, 10, 10]}>
                {purchaseStatus === PurchaseStatus.ENQUEUED ? null : isLeavingPurchase ? null : activeStep === 0 ? (
                  purchaseStatus === PurchaseStatus.NOT_ENOUGH_ITEMS ? (
                    <StyledPayButton onClick={leaveQueue}>tekrar ara</StyledPayButton>
                  ) : (
                    <StyledPayButton onClick={handleFirstStep}>{isTurkish ? 'devam et' : 'Weiter'}</StyledPayButton>
                  )
                ) : activeStep === 1 ? (
                  <StyledPayButton type="submit">{isTurkish ? 'devam et' : 'Weiter'}</StyledPayButton>
                ) : activeStep === 2 ? (
                  <StyledPayButton onClick={nextStep}>bitirmek</StyledPayButton>
                ) : null}
              </Flex>
            ) : null}
          </Box>
        </Flex>
      </form>
      <StyledFooterText>
        Powered by <span>FUEL</span>
      </StyledFooterText>
    </FormProvider>
  )
}
