import { createContext, useState } from 'react'

export const DropPageDataContext = createContext<any>({
  dropPageData: null,
  setDropPageData: () => {},
}) as any

const DropPageDataProvider = ({ children }: any) => {
  const [dropPageData, setDropPageData] = useState<any>(null)

  return (
    <DropPageDataContext.Provider
      value={{
        dropPageData,
        setDropPageData,
      }}
    >
      {children}
    </DropPageDataContext.Provider>
  )
}

export default DropPageDataProvider
