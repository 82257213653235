export const Text = {
  baseStyle: {
    color: 'grey.400',
    fontSize: '14px',
  },
  variants: {
    secondary: {
      color: 'grey.300',
      fontSize: '12px',
      fontWeight: 500,
    },
    error: {
      color: 'alert',
      fontSize: '12px',
      fontWeight: 500,
    },
  },
}
