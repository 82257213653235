import { useContext, useEffect, useState } from 'react'
import { useUniqueCollectionContract } from '../useUniqueCollectionContract'
import { MINT_STATUS } from '../useCurrentBlockHeight'
import * as Sentry from '@sentry/browser'
import { MagicConnectUserContext } from '@fuel/auth'
import { api } from '../../constants/api'
import { useAxiosWithAuth } from '@fuel/api'
import { CollectionContext } from '../../contexts/CollectionContext'
import { abi } from '../../constants/abi'

export const useGetMerkleRoot = (mintStatus: MINT_STATUS) => {
  const axiosApi = useAxiosWithAuth()
  const { collection }: any = useContext(CollectionContext)
  const { uniqueCollectionContract } = useUniqueCollectionContract({ contractAddress: collection?.contract_address, abi: abi.COLLECTION_ABI })
  const { userDetails } = useContext<any>(MagicConnectUserContext)
  const [proof, setProof] = useState(null)
  const [proofValid, setProofValid] = useState(null)
  const address = userDetails?.wallet_public_address

  // Get the merkle root
  useEffect(() => {
    ;(async () => {
      if (collection && address) {
        const { data } = await axiosApi.get(api.ALLOW_LIST_PROOF, {
          params: {
            collection_id: collection.id,
            wallet_address: address,
          },
        })

        if (data.proof.length) {
          Sentry.addBreadcrumb({
            category: 'mint',
            message: 'Authenticated user ' + data.proof,
            level: 'info',
          })
          setProof(data.proof)
        }
      }
    })()
  }, [collection, address])

  // Validate merkle root
  useEffect(() => {
    ;(async () => {
      if (uniqueCollectionContract && proof && address && mintStatus === MINT_STATUS.ALLOW_LIST) {
        const isValid = await uniqueCollectionContract.isProofValid(proof, address)

        setProofValid(isValid)
      }
    })()
  }, [proof, uniqueCollectionContract, address, mintStatus])

  return { proof, proofValid }
}
