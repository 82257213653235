interface GetMintedInfoFromAddressArgs {
  uniqueCollectionContractV2: any
  address: any
  mintPhaseIndex: any
}

export const getMintedInfoFromAddress = async ({ uniqueCollectionContractV2, address, mintPhaseIndex }: GetMintedInfoFromAddressArgs) => {
  const mintedInTotal = await uniqueCollectionContractV2?.minted(address)
  const mintedInPhase = await uniqueCollectionContractV2?.phaseMinted(address, mintPhaseIndex)
  return {
    mintedInTotal,
    mintedInPhase,
  }
}
