import { UniqueCollectionV2MintPhaseType } from '../types'

export const getRawMintPhases = async (uniqueCollectionContract: any) => {
  const rawMintPhases: UniqueCollectionV2MintPhaseType[] = []
  const mintPhasesLength = await uniqueCollectionContract.mintPhasesLength()
  for (let i = 0; i < mintPhasesLength.toNumber(); i++) {
    const mintPhase = await uniqueCollectionContract.mintPhases(i)
    const mintPhaseObj = {
      ...mintPhase,
      mintPhaseIndex: i,
    }
    rawMintPhases.push(mintPhaseObj)
  }
  return rawMintPhases
}
