import { useContext, useEffect, useState } from 'react'
import { useUniqueCollection } from './useUniqueCollection'
import { MINT_STATUS } from './useCurrentBlockHeight'
import { useAxiosWithAuth } from '@fuel/api'
import { CollectionContext } from '@fuel/nft/contexts/CollectionContext'
import { useAccount } from 'wagmi'
import * as Sentry from '@sentry/react'

const api = {
  ALLOW_LIST_PROOF: '/users/allowlist_proof',
}

export const useGetMerkleRoot = (mintStatus: MINT_STATUS) => {
  const [proof, setProof] = useState(null)
  const [proofValid, setProofValid] = useState(null)
  const { collection }: any = useContext(CollectionContext)
  const { uniqueCollection } = useUniqueCollection()
  const axiosApi = useAxiosWithAuth()
  const { address } = useAccount()

  console.log('Mint Status', mintStatus)

  // Get the merkle root
  useEffect(() => {
    ;(async () => {
      console.log('colection', collection)
      console.log('address', address)
      if (collection && address) {
        const { data } = await axiosApi.get(api.ALLOW_LIST_PROOF, {
          params: {
            collection_id: collection.id,
            wallet_address: address,
            index: 0,
          },
        })

        console.log('Data', data)

        if (data.proof.length) {
          Sentry.addBreadcrumb({
            category: 'mint',
            message: 'Authenticated user ' + data.proof,
            level: 'info',
          })
          setProof(data.proof)
        }
      }
    })()
  }, [collection, address])

  // Validate merkle root
  useEffect(() => {
    ;(async () => {
      if (uniqueCollection && proof && address && mintStatus === MINT_STATUS.ALLOW_LIST) {
        const isValid = await uniqueCollection.isProofValid(proof, address)

        setProofValid(isValid)
      }
    })()
  }, [proof, uniqueCollection, address, mintStatus])

  return { proof, proofValid }
}
