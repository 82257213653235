import { Box, HStack, Input, Text, useNumberInput, VStack } from '@chakra-ui/react'
import { StepperAnimation, storage } from '@fuel/core'
import { getLocalStorage } from '@fuel/core/services/localStorage'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { PurchaseStatus } from '../../../../constants/paymentMethods'
import LeavingPurchase from '../../../LeavingPurchase'
import { WaitingForToken } from '../../../WaitingForToken'
import styled from '@emotion/styled'
import { CircleMinus, CirclePlus } from 'tabler-icons-react'

import { StyledTitle, StyledSubtitle, StyledTicketName, StyledBeforeNftPrice, StyledNftPrice } from '../Stepper.styled'

export const Step1 = ({
  isTurkish,
  setIsLeavingPurchase,
  isLeavingPurchase,
  setPurchaseStatus,
  purchaseStatus,
  leaveQueue,
  purchaseLimit,
  purchaseQuantity,
  setPurchaseQuantity,
  remainingTickets,
}: {
  isTurkish: boolean
  setIsLeavingPurchase: any
  isLeavingPurchase: boolean
  setPurchaseStatus: any
  purchaseStatus: PurchaseStatus
  leaveQueue: () => void
  purchaseLimit: number
  purchaseQuantity: number
  setPurchaseQuantity: (num: number) => void
  remainingTickets: number
}) => {
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: purchaseQuantity,
    min: 1,
    max: purchaseLimit,
    precision: 0,
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  useEffect(() => {
    if (input?.value) {
      setPurchaseQuantity(input?.value || 1)
    }
  }, [input?.value])

  const StyledCirclePlus = styled(CirclePlus as any)`
    outline: none;
    margin: 0;
    height: 40px;
    width: 130px;
    margin-start: 0px;
    fill: ${purchaseQuantity < purchaseLimit ? '#fff' : 'transparent'};
    color: ${purchaseQuantity < purchaseLimit ? '#21232F' : '#B2B7CF'};
    margin-inline-start: 0px;
    &:active {
      fill: ${purchaseQuantity < purchaseLimit ? '#fff' : 'transparent'};
      color: ${purchaseQuantity < purchaseLimit ? '#fff' : '#B2B7CF'};
      cursor: ${purchaseQuantity < purchaseLimit ? 'pointer' : 'not-allowed'};
    }
    &:hover {
      cursor: ${purchaseQuantity < purchaseLimit ? 'pointer' : 'not-allowed'};
    }
    &:focus {
      outline: none;
    }
  `

  const StyledCircleMinus = styled(CircleMinus as any)`
    outline: none;
    margin: 0;
    height: 40px;
    width: 130px;
    margin-start: 0px;
    margin-inline-start: 0px;
    fill: ${purchaseQuantity > 1 ? '#fff' : 'transparent'};
    color: ${purchaseQuantity > 1 ? '#21232F' : '#B2B7CF'};

    &:active {
      fill: transparent;
      color: ${purchaseQuantity > 1 ? '#fff' : '#B2B7CF'};
    }

    &:hover {
      cursor: ${purchaseQuantity > 1 ? 'pointer' : 'not-allowed'};
    }
    &:focus {
      outline: none;
    }
  `

  if (isLeavingPurchase) {
    return (
      <LeavingPurchase
        setIsLeavingPurchase={setIsLeavingPurchase}
        setPurchaseStatus={setPurchaseStatus}
        purchaseStatus={purchaseStatus}
        isTurkish={isTurkish}
        leaveQueue={leaveQueue}
      />
    )
  } else if (purchaseStatus === PurchaseStatus.NOT_ENOUGH_ITEMS) {
    return (
      <motion.div
        variants={StepperAnimation}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Box my={[4, 4, 10]}>
            <Text fontSize="28px" fontWeight="800" color="#E8ECFB">
              {isTurkish ? 'Aradığınız biletleri bulamadık.' : 'We couldn’t find the tickets you were looking for.'}
            </Text>
          </Box>
          <Text fontSize="16px" fontWeight="500" color="#CFD4EB">
            {isTurkish
              ? `Bu etkinlik için bu bilet kademesinde yalnızca ${remainingTickets} bilet kalmıştır. `
              : `There are only ${remainingTickets} tickets remaining for this event at this ticket tier.`}
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#CFD4EB" mt={5} mb={5}>
            {isTurkish ? 'Lütfen miktarı veya bilet türünü değiştirin.' : 'Please change the quantity or ticket type.'}
          </Text>
        </Box>
      </motion.div>
    )
  } else {
    if (purchaseStatus === PurchaseStatus.ENQUEUED)
      return (
        <motion.div
          variants={StepperAnimation}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          <WaitingForToken setIsLeavingPurchase={setIsLeavingPurchase} setPurchaseStatus={setPurchaseStatus} isTurkish={isTurkish} />
        </motion.div>
      )
  }

  return (
    <motion.div
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        opacity: { duration: 0.2 },
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Box my={[4, 4, 10]}>
          <StyledTitle as="h3" size="lg" mb={4}>
            {isTurkish ? 'Bilet Onayla' : 'Kauf bestätigen'}
          </StyledTitle>
          {isTurkish && <StyledSubtitle>Lütfen aşağıdaki biletin doğru olduğundan emin olun.</StyledSubtitle>}
        </Box>

        {purchaseLimit > 1 ? (
          <Box
            sx={{
              marginTop: 10,
              background: 'rgba(33, 35, 47, 0.5)',
              borderRadius: '12px',
              textAlign: 'center',
              padding: '30px',
              '@media screen and (max-width: 768px)': {
                padding: '5px',
              },
            }}
          >
            <HStack justifyContent="space-between" p="8px">
              <VStack alignItems="flex-start">
                <StyledTicketName mb={4}>{selectedEdition.name}</StyledTicketName>
                <HStack>
                  {selectedEdition.beforePrice && (
                    <StyledBeforeNftPrice>
                      {!isTurkish && '€'} {selectedEdition.beforePrice} {isTurkish && 'TL'}
                    </StyledBeforeNftPrice>
                  )}
                  <StyledNftPrice>
                    {!isTurkish && '€'} {selectedEdition.price} {isTurkish && 'TL'}
                  </StyledNftPrice>
                </HStack>
              </VStack>
              <HStack maxW="120px">
                <StyledCircleMinus {...dec} strokeWidth={1} />
                <Input
                  variant="unstyled"
                  {...input}
                  disabled
                  _disabled={{
                    color: 'white',
                  }}
                  style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                />

                <StyledCirclePlus {...inc} strokeWidth={1} />
              </HStack>
            </HStack>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                marginTop: 10,
                background: 'rgba(33, 35, 47, 0.5)',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '30px',
                '@media screen and (max-width: 768px)': {
                  padding: '5px',
                },
              }}
            >
              <StyledTicketName mb={4}>{selectedEdition.name}</StyledTicketName>

              <HStack justifyContent="center" mt={5}>
                {selectedEdition.beforePrice && (
                  <StyledBeforeNftPrice>
                    {!isTurkish && '€'} {selectedEdition.beforePrice} {isTurkish && 'TL'}
                  </StyledBeforeNftPrice>
                )}
                <StyledNftPrice>
                  {!isTurkish && '€'} {selectedEdition.price} {isTurkish && 'TL'}
                </StyledNftPrice>
              </HStack>
            </Box>

            <Text mt={[4, 4, 10]}>
              {isTurkish ? 'Bu etkinlik için biletler kişi başına bir adet ile sınırlıdır.' : 'Tickets for this event are limited to one per person.'}
            </Text>
          </>
        )}
      </Box>
    </motion.div>
  )
}
