import React, { FC } from 'react'
import { NftModel, Text } from '@fuel/ui'
import Image from 'next/image'
import { Alert, AlertIcon, Box, Flex, Link } from '@chakra-ui/react'
import { UtilityModel } from '@fuel/nft'
import { useTranslation } from 'next-i18next'
import { isBefore } from 'date-fns'

type NftBlockProps = {
  nft: NftModel
  onNotificationClose: (utility: UtilityModel) => void
}

export const NftBlock: FC<NftBlockProps> = ({ nft, onNotificationClose }) => {
  const { t } = useTranslation('common')
  const { name, image_url, description, utilities } = nft

  return (
    <Box boxShadow="rgb(0 0 0 / 25%) 0px 4px 14px" borderRadius="16px" fontFamily="Inter">
      <Box position="relative" h="200px" w="full" mt="15px">
        <Image src={image_url} alt={name} layout="fill" objectFit="contain" />
      </Box>

      <Box p="8px">
        <Box px="8px" mt="10px" mb="20px">
          <Text fontWeight="semibold">{name}</Text>
          <Text fontWeight="regular" size="xs" color="disabled">
            {description}
          </Text>
        </Box>
        {utilities?.map(utility => {
          const currentDate = new Date(utility.date)

          if (isBefore(currentDate, new Date())) {
            return null
          }

          return (
            <Alert status="warning" display="flex" justifyContent="space-between" borderRadius="17px" key={utility.name} bg="#FFDCCD80">
              <Flex mr="20px" fontSize="12px">
                <AlertIcon color="#FF996D" pt="5px" />
                <Box mr="30px">
                  {t('qr_code_description')} <br />
                  <Link color="#1A1347" fontWeight="600" onClick={() => onNotificationClose(utility)} as="button">
                    {t('qr_code_link')}
                  </Link>
                </Box>
              </Flex>
            </Alert>
          )
        })}
      </Box>
    </Box>
  )
}
