import { useEffect, useState } from 'react'
import { ethers } from 'ethers'

export const useProvider = () => {
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null)

  useEffect(() => {
    if (window.ethereum) {
      setProvider(new ethers.providers.Web3Provider(window?.ethereum as any))
    }
  }, [])

  return { provider }
}
