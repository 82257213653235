import styled from 'styled-components'

export const Grid = styled.div<{
  gap?: number
  gridTemplateColumns?: string
  width?: string
  flex?: boolean
  mt?: number
}>`
  display: grid;

  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    `
    grid-template-columns: ${gridTemplateColumns ?? `repeat(1, fr)`};
  `}
  width: ${({ width }) => width ?? '100%'};
  gap: ${({ theme, gap }) => theme.spacing(gap ?? 5)};
  ${({ theme, mt }) => mt && `margin-top: ${theme.spacing(mt)}`};
  ${({ flex }) => flex && `display: flex;`};
`
