import { Input, Button, Text, InputLeftAddon } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const StyledInput = styled(Input)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 8px;
  background: #21232f;
  border-radius: 12px;
  border: none;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 1324px) {
    padding: 25px;
  }
`

export const StyledInputLeftAddon = styled(InputLeftAddon)`
  border: none;
  padding: 30px;
  background: #21232f;
  border-radius: 12px 0px 0px 12px;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 1324px) {
    padding: 25px;
  }
`

export const StyledPhoneInput = styled(Input)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 30px 10px;
  gap: 8px;
  background: #21232f;
  border-radius: 0px 12px 12px 0px;
  border: none;

  @media (max-width: 768px) {
    padding: 20px 20px 20px 5px;
  }

  @media (max-width: 1324px) {
    padding: 25px 25px 25px 5px;
  }
`

export const StyledCheckbox = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #a0abb0;
  text-align: left;
  margin-left: 10px;
`

export const StyledTitle = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: #e8ecfb;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 47px;
  gap: 10px;
  background: #e8ecfb;
  border-radius: 50px;
  color: #21232f;
  width: 150px;
  margin: 0 auto;
  margin-bottom: 40px;
`

export const StyledFormErrorMessage = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff0000;
  text-align: left;
  margin-top: 2px;
`
