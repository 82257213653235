import { UniqueCollectionV2MintPhaseType } from '../types'

interface SeparateMintPhasesByBlockHeightArgs {
  blockHeight: number
  rawMintPhases: Array<UniqueCollectionV2MintPhaseType>
}

export const separateMintPhasesByBlockHeight = ({ blockHeight, rawMintPhases }: SeparateMintPhasesByBlockHeightArgs) => {
  const currentMintPhases = rawMintPhases.filter(mintPhase => {
    return blockHeight >= mintPhase.startBlockHeight.toNumber() && blockHeight < mintPhase.endBlockHeight.toNumber()
  })
  // MintPhases that are over, done, in the past
  const pastMintPhases = rawMintPhases.filter(mintPhase => {
    return blockHeight >= mintPhase.endBlockHeight.toNumber()
  })
  // MintPhases that are in the future
  const futureMintPhases = rawMintPhases.filter(mintPhase => {
    return blockHeight < mintPhase.startBlockHeight.toNumber()
  })

  return {
    pastMintPhases,
    currentMintPhases,
    futureMintPhases,
  }
}
