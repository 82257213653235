import { Heading, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const StyledBeforeNftPrice = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  margin-right: 10px;
  text-decoration-line: line-through;
  color: #e8ecfb;
  opacity: 0.5;
  @media (max-width: 768px) {
    font-size: 17px;
  }
`

export const StyledNftPrice = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;

  color: #e8ecfb;
  @media (max-width: 768px) {
    font-size: 17px;
  }
`

export const StyledTicketName = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #e8ecfb;
`

export const StyledDescription = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #cfd4eb;
`

export const StyledSubtitle = styled(Text)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #cfd4eb;
  // mobile
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

export const StyledTitle = styled(Heading)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: #e8ecfb;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 20px;
  }
`
