import { library } from '@fortawesome/fontawesome-svg-core'
import ReactModal from 'react-modal'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import React, { useEffect } from 'react'
import { ChakraProvider, GlobalStyle } from '@chakra-ui/react'
import { theme as chakraTheme } from '../theme/theme'

import { Provider, useCreateStore } from '../zustand/store'
import { CollectionCreateExitModal } from '../components/modals/CollectionCreateExitModal'
import {
  faArrowLeft,
  faBox,
  faChartLine,
  faCheck,
  faCrown,
  faEdit,
  faFolder,
  faHeartCrack,
  faPlus,
  faTrash,
  faUpload,
  faUser,
  faWallet,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { CustomHead, useLanguageDetector } from '@fuel/core'
import './../styles/globalStyles.css'
import './../styles/fonts.css'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { Intercom } from '../components/common/Intercom'
import { IntercomContainer } from '../components/common/IntercomContainer'
import { MagicConnectWrapperProvider } from '@fuel/auth'
import { useCreatorsMiddleware } from '../useCreatorsMiddleware'
import { appWithTranslation } from 'next-i18next'
import nextI18nConfig from '../next-i18next.config'
import languageDetector from '@fuel/wallet/utilities/languageDetector'

library.add(faFolder, faWallet, faUser, faPlus, faArrowLeft, faCheck, faX, faBox, faCrown, faChartLine, faUpload, faTrash, faEdit, faHeartCrack)
ReactModal.setAppElement('#__next')

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

const App = props => {
  useLanguageDetector(languageDetector)
  useCreatorsMiddleware()
  const { Component, pageProps } = props
  const createStore = useCreateStore(pageProps.initialReduxState)
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      ;(window as any).Intercom('update')
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (typeof window !== 'undefined' && (window as any).Intercom) {
      ;(window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'knxs1tnt',
      })
    }
  }, [])

  const variants = {
    initial: {
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  }

  return (
    <>
      <CustomHead title="Fuel | Creators" />
      <Provider createStore={createStore}>
        <ChakraProvider theme={chakraTheme}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ReactQueryDevtools initialIsOpen={false} />
              <MagicConnectWrapperProvider>
                <IntercomContainer>
                  <GlobalStyle />
                  <motion.div key={router.route} initial="initial" animate="animate" variants={variants}>
                    <Component {...pageProps} />
                  </motion.div>
                  <CollectionCreateExitModal />
                </IntercomContainer>
              </MagicConnectWrapperProvider>
            </Hydrate>
          </QueryClientProvider>
        </ChakraProvider>
      </Provider>
      <Intercom />
    </>
  )
}

export default appWithTranslation(App, nextI18nConfig)
