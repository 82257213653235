import React, { FC } from 'react'
import styled from 'styled-components'

export const StyledCard = styled.div<CardProps>`
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => theme.shadows.base};
  padding: ${({ theme }) => theme.spacing(6)};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid ${({ theme }) => theme.palette.common.disabled};
  ${({ mb, theme }) => (mb ? `margin-bottom: ${theme.spacing(mb)};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
`

type CardProps = {
  maxWidth?: string
  mb?: number
  children: any
}

export const Card: FC<CardProps> = ({ children, maxWidth, mb }) => {
  return (
    <StyledCard maxWidth={maxWidth} mb={mb}>
      {children}
    </StyledCard>
  )
}
