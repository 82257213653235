import React, { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

library.add(faTimes)

type ModalProps = {
  modalIsOpen: boolean
  closeModal: () => void
  children: ReactNode
}

const customStyles = {
  overlay: {
    zIndex: 998,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    minWidth: '30vw',
  },
}

export const StyledCloseButton = styled.button`
  background: none;
  border: none;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  &:active {
    transform: scale(1.1);
  }
`

export const StyledModal = styled<any>(ReactModal)`
  position: absolute;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: ${({ theme }) => theme.spacing(10)};
  background: ${({ theme }) => theme.palette.common.white};
`
if (ReactModal.defaultStyles && ReactModal.defaultStyles.overlay) {
  ReactModal.defaultStyles.overlay.backgroundColor = 'transparent'
}

export const Modal: FC<ModalProps> = ({ closeModal, modalIsOpen, children }) => {
  return (
    <StyledModal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} closeTimeoutMS={300}>
      <StyledCloseButton>
        <FontAwesomeIcon icon={faTimes} onClick={closeModal} size="lg" />
      </StyledCloseButton>
      {children}
    </StyledModal>
  )
}
