export const routes = {
  HOME: '/',
  SOS: '/SesimOlsunSesin',
  COLLECTIONS: '/dashboard/collections',
  COLLECTIONS_DETAIL: '/dashboard/collections',
  DASHBOARD_COLLECTIONS_ADD: '/dashboard/collections/add',
  ANALYTICS: '/dashboard/wallet',
  PROFILE: '/dashboard/profile',
  NFTS_ADD: '/dashboard/nfts/add',
  ON_FUEL: 'https://onfuel.io',
  WALLET_LOGIN: '/login',
}
