export const Button = {
  variants: {
    primary: {
      bg: 'primary.accent',
      color: 'grey.400',
      fontSize: '16px',
      borderRadius: '12px',
    },
    secondary: {
      color: 'secondary.accent',
      bg: 'secondary.light',
      fontSize: '16px',
      borderRadius: '12px',
    },
    link: {
      color: 'secondary.accent',
      fontWeight: 900,
    },
  },
}
