import React from 'react'
import styled from '@emotion/styled'

export const StyledDivider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  span {
    color: #b2b7cf;
  }
  &:before {
    content: '';
    width: 50%;
    height: 1px;
    background-color: #f0f1f5;
    margin-right: 30px;
  }
  &:after {
    content: '';
    width: 50%;
    height: 1px;
    margin-left: 30px;
    background-color: #f0f1f5;
  }
`

export const Divider = () => {
  return (
    <StyledDivider>
      <span>OR</span>
    </StyledDivider>
  )
}
