import { useEffect, useState } from 'react'
import { Contract, ethers } from 'ethers'
import { useSigner } from 'wagmi'

interface UseUniqueCollectionV2Args {
  contractAddress: string
  abi: string[]
}

export const useUniqueCollectionContract = ({ contractAddress, abi }: UseUniqueCollectionV2Args) => {
  const [uniqueCollectionContract, setUniqueCollectionContract] = useState<Contract | null>(null)
  const { data: signer } = useSigner()

  useEffect(() => {
    if (signer && contractAddress) {
      const contract = new ethers.Contract(contractAddress, abi, signer)

      setUniqueCollectionContract(contract)
    }
  }, [signer, contractAddress, setUniqueCollectionContract])

  return { uniqueCollectionContract }
}
