import { removeLocalStorage } from '@fuel/core/services/localStorage'
import { useContext } from 'react'
import { MagicConnectUserContext } from '../context/MagicConnectUserProvider'
import { storage } from '@fuel/core'
import { InstanceWithExtensions } from '@magic-sdk/provider/dist/types/modules/base-extension'
import { MagicSDKExtensionsOption, SDKBase } from '@magic-sdk/provider/dist/types/core/sdk'

export const useLogout = () => {
  const { setUserDetails }: any = useContext(MagicConnectUserContext)
  const magic = useContext<any>(MagicConnectUserContext).magic as InstanceWithExtensions<SDKBase, MagicSDKExtensionsOption<string>>

  const logout = () => {
    try {
      magic?.wallet.disconnect()
    } catch (error) {
      console.log(error)
    }

    if (typeof window !== 'undefined' && (window as any).Intercom) {
      ;(window as any).Intercom('shutdown')
    }

    removeLocalStorage(storage.AUTH_TOKENS)
    removeLocalStorage(storage.MINT_PASS_ID)
    if (setUserDetails) setUserDetails(null)
  }

  return { logout }
}
