import { toast } from 'react-hot-toast'
import { useAxiosWithAuth } from '@fuel/api'
import { api, storage } from '@fuel/core'
import { getLocalStorage } from '@fuel/core/services/localStorage'

export const usePurchaseLeave = () => {
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
  const axiosApi = useAxiosWithAuth()
  const leavePurchase = async () => {
    try {
      await axiosApi.post(api.LEAVE_TICKET_QUEUE, {
        edition_id: selectedEdition?.id,
      })
    } catch (e) {
      toast.error('Something went wrong!')
    }
  }

  return { leavePurchase }
}
