import { ChangeEvent, FC, useMemo } from 'react'
import { Select, SelectProps } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

function capitalize(lang: string) {
  return lang.slice(0, 1).toUpperCase() + lang.slice(1)
}

type Props = {
  switcherStyles?: SelectProps
}

export const LanguageSwitcher: FC<Props> = ({ switcherStyles }) => {
  const router = useRouter()
  const { i18n } = useTranslation('common')

  const { language: currentLanguage } = i18n

  const changeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    const newLocale = e.target.value
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: newLocale })
    typeof window !== 'undefined' && localStorage.setItem('preferredLanguage', newLocale)
  }

  const languageNames = useMemo(() => {
    if (currentLanguage) {
      return new Intl.DisplayNames(['en'], {
        type: 'language',
      })
    }
  }, [currentLanguage])

  if (router.locales?.length === 1) {
    return null
  }

  return (
    <Select defaultValue={currentLanguage} onChange={e => changeLanguage(e)} {...switcherStyles}>
      {router.locales?.map(language => (
        <option value={language} key={language}>
          {capitalize(languageNames?.of(language) ?? language)}
        </option>
      ))}
    </Select>
  )
}
