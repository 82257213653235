export const abi = {
  COLLECTION_ABI: [
    'constructor()',
    'event BaseURIChanged(string baseURI)',
    'event SaleStatusChanged(bool active)',
    'event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)',
    'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
    'function HUNDRED_PERCENT() view returns (uint256)',
    'function MANAGER_ROLE() view returns (bytes32)',
    'function _extendedBaseURI() view returns (string)',
    'function allowListMerkleRoot() view returns (bytes32)',
    'function allowListMint(bytes32[] _merkleProof) payable',
    'function allowListMintEndBlockHeight() view returns (uint128)',
    'function approve(address to, uint256 tokenId)',
    'function balanceOf(address owner) view returns (uint256)',
    'function decreaseRoyaltyPercentage(uint256 _royaltyPercentage)',
    'function getApproved(uint256 tokenId) view returns (address)',
    'function getRoleAdmin(bytes32 role) view returns (bytes32)',
    'function grantRole(bytes32 role, address account)',
    'function hasRole(bytes32 role, address account) view returns (bool)',
    'function initialize(tuple(address roleAdmin, address manager, uint64 maxSupply, uint56 maxMintPerAddress, string name, string symbol, string baseURI, uint256 royaltyPercentage, address royaltyRecipient, uint112 mintPrice, uint16 maxAllowListMintPerAddress, bytes32 allowListMerkleRoot) _init)',
    'function isApprovedForAll(address owner, address operator) view returns (bool)',
    'function isProofValid(bytes32[] _merkleProof, address _sender) view returns (bool isValid)',
    'function managerMint(address to)',
    'function maxAllowListMintPerAddress() view returns (uint16)',
    'function maxMintPerAddress() view returns (uint56)',
    'function maxSupply() view returns (uint64)',
    'function mintPrice() view returns (uint112)',
    'function minted(address) view returns (uint256)',
    'function name() view returns (string)',
    'function owner() view returns (address)',
    'function ownerOf(uint256 tokenId) view returns (address)',
    'function pause()',
    'function paused() view returns (bool)',
    'function publicMint() payable',
    'function publicMintStartBlockHeight() view returns (uint128)',
    'function recoverERC20(address _tokenAddress, address _receiver, uint256 _tokenAmount)',
    'function renounceOwnership()',
    'function renounceRole(bytes32 role, address account)',
    'function revokeRole(bytes32 role, address account)',
    'function royaltyInfo(uint256, uint256 salePrice) view returns (address receiver, uint256 royaltyAmount)',
    'function royaltyPercentage() view returns (uint256)',
    'function royaltyRecipient() view returns (address)',
    'function safeTransferFrom(address from, address to, uint256 tokenId)',
    'function safeTransferFrom(address from, address to, uint256 tokenId, bytes _data)',
    'function saleActive() view returns (bool)',
    'function setAllowListMintEndBlockHeight(uint128 _allowListMintEndBlockHeight)',
    'function setApprovalForAll(address operator, bool approved)',
    'function setBaseURI(string _newBaseURI)',
    'function setPublicMintStartBlockHeight(uint128 _publicMintStartBlockHeight)',
    'function setRoyaltyRecipient(address _recipient)',
    'function setSaleStateAndBlockHeights(bool _saleActive, uint128 _allowListMintEndBlockHeight, uint128 _publicMintStartBlockHeight)',
    'function supportsInterface(bytes4 interfaceId) view returns (bool)',
    'function symbol() view returns (string)',
    'function toggleSaleState(bool _saleActive)',
    'function tokenURI(uint256 tokenId) view returns (string)',
    'function totalSupply() view returns (uint256)',
    'function tokensMinted() view returns (uint256)',
    'function transferFrom(address from, address to, uint256 tokenId)',
    'function transferOwnership(address newOwner)',
    'function unpause()',
    'function withdrawNativeTokens(address _to)',
  ],
}
