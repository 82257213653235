import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'next-i18next'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

library.add(faLock)

const StyledSecuredByFuel = styled.p`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2.5)};
  left: 50%;
  transform: translateX(-50%);

  & > span > span {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  svg {
    margin-right: 5px;
  }
`

export const SecuredByFuel = () => {
  const { t } = useTranslation('payment')

  return (
    <StyledSecuredByFuel>
      <FontAwesomeIcon icon={['fas', 'lock']} size="lg" />
      <span dangerouslySetInnerHTML={{ __html: t('secured_by_fuel') }} />
    </StyledSecuredByFuel>
  )
}
