import { useEffect, useState } from 'react'
import { metamaskStatus } from '../../constants/metamaskStatus'
import { ethers } from 'ethers'
import { NETWORK_STATUS, useNetworkStatus } from './useNetworkStatus'
import { useUniqueCollection } from './useUniqueCollection'
import { useAccount, useBalance } from 'wagmi'

export const useMintMinPrice = (gasPrice: string) => {
  const [hasEnoughFunds, setHasEnoughFunds] = useState(true)
  const { uniqueCollection } = useUniqueCollection()
  const { networkStatus } = useNetworkStatus()
  const { address } = useAccount()
  const { data: walletBalance } = useBalance({
    addressOrName: address,
  })

  useEffect(() => {
    ;(async () => {
      if (uniqueCollection && networkStatus === NETWORK_STATUS.CORRECT) {
        const price = await uniqueCollection.mintPrice()
        try {
          const estimatedGas = await uniqueCollection.estimateGas.publicMint({
            value: price,
          })

          const gasPriceInWei = ethers.utils.parseUnits(estimatedGas.toString(), 'gwei').mul(gasPrice)
          if (price.add(gasPriceInWei).lte(walletBalance?.value)) {
            setHasEnoughFunds(true)
          }
        } catch (e: any) {
          if (e?.data?.code === metamaskStatus.INSUFFICIENT_GAS_FUNDS) {
            setHasEnoughFunds(false)
          }
        }
      }
    })()
  }, [walletBalance, uniqueCollection, gasPrice, networkStatus])

  return { hasEnoughFunds }
}
