export const childBlockAnimation = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 2,
      duration: 0.5,
    },
  },
}
