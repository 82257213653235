import { storage } from '@fuel/core'
import { getLocalStorage } from '@fuel/core/services/localStorage'

import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useCreatorsMiddleware = () => {
  const authTokens = getLocalStorage(storage.AUTH_TOKENS)
  const router = useRouter()

  useEffect(() => {
    if (router.pathname !== '/' && !authTokens) {
      router.push('/')
    }
  }, [])
}
